import React, { FC } from 'react';

export interface ProductCardProps {
    className?: string;
}

const LoadingAddress: FC<ProductCardProps> = ({ className = '' }) => {
    return (
        <>
            {Array.from({ length: 3 }).map((_, index) => (
                <div key={index} className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
                    <div className="flex-col sm:flex-row items-start p-1 py-4">
                        <div>
                            <div className="my-auto mx-2 border-b">
                                <div className="mt-2">
                                    <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-7  animate-pulse"></div>
                                </div>
                            </div>
                            <div className="mx-2 mt-2">
                                <div className="mt-2">
                                    <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-7  animate-pulse"></div>
                                </div>
                                <div className="mt-2">
                                    <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-7  animate-pulse"></div>
                                </div>
                                <div className="mt-2">
                                    <div className="bg-gray-200 rounded-full dark:bg-gray-700 h-7  animate-pulse"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            ))}
        </>
    );
};

export default LoadingAddress;
