// ** React Imports
import { FC, ReactNode, Suspense } from 'react';
import { Navigate } from 'react-router-dom';

// ** Utils
import { Page } from './types';
import { RootState } from 'reduxStore/store';
import { useSelector } from 'react-redux';

export interface PublicRouteProps {
    children?: ReactNode;
    route: Page;
}
const PublicRoute: FC<PublicRouteProps> = ({ children, route }) => {
    if (route) {
        const user = useSelector((state: RootState) => state.auth.accessToken);

        if (user) {
            return <Navigate to="/" />;
        }
    }

    return <Suspense fallback={null}>{children}</Suspense>;
};

export default PublicRoute;
