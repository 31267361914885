import React, { FC } from 'react';
// import NcImage from 'shared/NcImage/NcImage';
// import rightImgDemo from 'images/rightLargeImg.png';
// import rightImg from 'images/instaacard/home/right-image.jpeg';
// import rightLargeImgDark from 'images/rightLargeImgDark.png';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
// import Logo from 'shared/Logo/Logo';
// import ButtonSecondary from 'shared/Button/ButtonSecondary';

export interface SectionPromo1Props {
    className?: string;
}

const SectionPromo1: FC<SectionPromo1Props> = ({ className = '' }) => {
    return (
        <div className={`nc-SectionPromo1 relative flex flex-col lg:flex-row items-center ${className}`} data-nc-id="SectionPromo1">
            <div className="relative flex-shrink-0 mb-16 lg:mb-0 lg:mr-10 lg:w-2/5">
                {/* <Logo className="w-28" /> */}
                <h2 className="font-semibold text-3xl sm:text-4xl xl:text-3xl 2xl:text-3xl mt-6 sm:mt-10 !leading-[1.13] tracking-tight">
                    Networking made effortless with INSTAACARD
                </h2>
                <span className="block mt-6 text-slate-500 dark:text-slate-400 ">
                    Instaacard revolutionizes networking with its NFC-based smart business card. Effortlessly exchange contact details by
                    simply tapping phones, eliminating the need for traditional paper cards. Its sleek design and convenience make
                    networking efficient and eco-friendly.
                </span>
                <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
                    <ButtonPrimary href="/subcategory/pvc-card" className="">
                        Explore Cards
                    </ButtonPrimary>
                    {/* <ButtonSecondary href="/page-search" className="border border-slate-100 dark:border-slate-700">
                        Discover more
                    </ButtonSecondary> */}
                </div>
            </div>
            <div className="relative flex-1 max-w-xl lg:max-w-none">
                {/* <NcImage containerClassName="block dark:hidden" src={rightImg} />
                <NcImage containerClassName="hidden dark:block" src={rightImg} /> */}
                <div className={`nc-NcImage`} data-nc-id="NcImage">
                    <iframe
                        src="https://www.youtube.com/embed/4G98GalcpBQ?si=6faTe5yxgXN8q5WO"
                        title="INSTAACARD - Smart NFC Business cards"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                        allowFullScreen
                        className="rounded-2xl"
                        style={{
                            width: '100%',
                            height: '350px'
                        }}
                    ></iframe>
                </div>
            </div>
        </div>
    );
};

export default SectionPromo1;
