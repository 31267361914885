import { SocialType } from 'shared/SocialsShare/SocialsShare';
import React, { FC } from 'react';
import instagram from 'images/socials/instagram.svg';
import linkedin from 'images/socials/linkedin.svg';
import youtube from 'images/socials/youtube.svg';
import facebook from 'images/socials/facebook.svg';

export interface SocialsList1Props {
    className?: string;
}

const socials: SocialType[] = [
    { name: 'Instagram', icon: instagram, href: 'https://www.instagram.com/official_instaacard?igsh=cnozNHJyaHFpa3Bm' },
    { name: 'Facebook', icon: facebook, href: 'https://www.facebook.com/profile.php?id=61567156762350&mibextid=ZbWKwL' },
    { name: 'LinkedIn', icon: linkedin, href: 'https://www.linkedin.com/company/instaacard/about/?viewAsMember=true' },
    { name: 'Youtube', icon: youtube, href: 'https://www.youtube.com/@TrumpcardInitiativesWorldwideP' }
];

const SocialsList1: FC<SocialsList1Props> = ({ className = 'space-y-3' }) => {
    const renderItem = (item: SocialType, index: number) => {
        return (
            <a
                href={item.href}
                target="_blank" rel="noopener noreferrer"
                className="flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group"
                key={index}
            >
                <div className="flex-shrink-0 w-5 ">
                    <img src={item.icon} alt="" />
                </div>
                <span className="hidden lg:block text-sm">{item.name}</span>
            </a>
        );
    };

    return (
        <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
            {socials.map(renderItem)}
        </div>
    );
};

export default SocialsList1;
