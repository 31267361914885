import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import ProductCard from 'components/PvcCard';
import axios from '../service/axios';
// import LoadingSubcategory from 'components/LoadingSubcategory';

export interface PageCollectionProps {
    className?: string;
}
const PageCollection: FC<PageCollectionProps> = ({ className = '' }) => {
    const [subcategoryData, setSubcategoryData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const getMetal = async (id: string) => {
        try {
            let response = await axios.get('/product-sub-category/category/' + id, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setSubcategoryData(response.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            return {};
        }
    };
    useEffect(() => {
        if (window.location.pathname === '/subcategory/metal-card') getMetal('59fc06b1-bee3-4881-bc45-e526023e4940');
        else if (window.location.pathname === '/subcategory/pvc-card') getMetal('be51b0d7-5ea5-45fa-90ff-e6e5205a03bd');
        else getMetal('c9d5b282-2823-406b-9e26-b0b4f3ee927d');
        return () => {
            setIsLoading(true);
        };
    }, [window.location.pathname]);

    return (
        <div className={`nc-PageCollection ${className}`} data-nc-id="PageCollection">
            <Helmet>
                <title>Collection || Instaacard</title>
            </Helmet>

            <div className="container py-16 lg:pb-28 lg:pt-20 space-y-16 sm:space-y-20 lg:space-y-28">
                <div className="space-y-10 lg:space-y-14">
                    {/* HEADING */}
                    <div className="max-w-screen-sm">
                        <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold">Product Subcategory collection</h2>
                        <span className="block mt-4 text-neutral-500 dark:text-neutral-400 text-sm sm:text-base">
                            We not only help you design exceptional products, but also make it easy for you to share your designs with more
                            like-minded people.
                        </span>
                    </div>

                    <hr className="border-slate-200 dark:border-slate-700" />
                    <main>
                        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-x-8 gap-y-10 mt-8 lg:mt-10">
                            {!isLoading && subcategoryData.map((item, index) => <ProductCard data={item} key={index} />)}
                            {/* {isLoading && <LoadingSubcategory />} */}
                        </div>
                    </main>
                </div>
            </div>
        </div>
    );
};

export default PageCollection;
