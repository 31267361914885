// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Product } from '../products/class';
// ** API Imports
import axios from '../../../service/axios';

export const getNavProduct = createAsyncThunk('SliderProductData/getNavProduct', async (data: {}) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/product?productSubCategoryId=' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});

export const NavProductSlice = createSlice({
    name: 'SliderProductData',
    initialState: {
        navProducts: [] as Product[]
    },

    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getNavProduct.fulfilled, (state, action) => {
            state.navProducts = action.payload.productMaster;
        });
    }
});

export default NavProductSlice.reducer;
