import React, { FC } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import LikeButton from './LikeButton';
import Prices from './Prices';
// import { toast } from 'react-toastify';
// import { Transition } from '@headlessui/react';
import ModalQuickView from './ModalQuickView';
import { Product } from 'reduxStore/store/products/class';
import service from 'service/constant';

export interface ProductCardProps {
    className?: string;
    data?: Product;
    isLiked?: boolean;
}

const ProductCard: FC<ProductCardProps> = ({ className = '', data, isLiked }) => {
    const url = service.IMAGE_URL;
    const image = `${url}${data?.ProductImageMappings?.[0]?.ProductImage?.path || ''}`;
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);

    // eslint-disable-next-line no-unused-vars
    const notifyAddToCart = ({ size }: { size?: string }) => {
        // toast.custom(
        //     (t) => (
        //         <Transition
        //             appear
        //             show={t.visible}
        //             className="p-4 max-w-md w-full bg-white dark:bg-slate-800 shadow-lg rounded-2xl pointer-events-auto ring-1 ring-black/5 dark:ring-white/10 text-slate-900 dark:text-slate-200"
        //             enter="transition-all duration-150"
        //             enterFrom="opacity-0 translate-x-20"
        //             enterTo="opacity-100 translate-x-0"
        //             leave="transition-all duration-150"
        //             leaveFrom="opacity-100 translate-x-0"
        //             leaveTo="opacity-0 translate-x-20"
        //         >
        //             <p className="block text-base font-semibold leading-none">Added to cart!</p>
        //             <div className="border-t border-slate-200 dark:border-slate-700 my-4" />
        //             {renderProductCartOnNotify({ size })}
        //         </Transition>
        //     ),
        //     { position: 'top-right', id: 'nc-product-notify', duration: 3000 }
        // );
    };

    // eslint-disable-next-line no-unused-vars
    const renderProductCartOnNotify = ({ size }: { size?: string }) => {
        return (
            <div className="flex ">
                <div className="h-24 w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        // src={image}
                        // alt={name}
                        className="h-full w-full object-cover object-center"
                    />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                {/* <h3 className="text-base font-medium ">{data?.name}</h3> */}
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                    <span>{/* {variants ? variants[variantActive].name : `Natural`} */}</span>
                                    <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                                    <span>{size || 'XL'}</span>
                                </p>
                            </div>
                            {/* <Prices price={price} className="mt-0.5" /> */}
                        </div>
                    </div>
                    <div className="flex flex-1 items-end justify-between text-sm">
                        <p className="text-gray-500 dark:text-slate-400">Qty 1</p>

                        <div className="flex">
                            <Link to={'/cart'} className="font-medium text-primary-6000 dark:text-primary-500 ">
                                View cart
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const navigate = useNavigate();

    const clickProductDetail = () => {
        navigate(`/product-details/${data?.id}`);
    };

    return (
        <>
            <div className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`} data-nc-id="ProductCard">
                <div
                    className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group"
                    onClick={clickProductDetail}
                >
                    {/* <Link to={"/product-detail"} className="block"> */}
                    <NcImage
                        // containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                        // containerClassName="flex w-full h-auto"
                        containerClassName="aspect-w-8 aspect-h-8 bg-neutral-100 rounded-2xl overflow-hidden"
                        className="object-cover w-full h-full rounded-2xl"
                        src={image}
                        style={{ cursor: 'pointer' }}
                        // className="object-fill w-full h-50 drop-shadow-xl"
                    />
                    {/* </Link> */}

                    {/* <ProductStatus status={status} /> */}

                    <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" />

                    {/* {sizes ? renderSizeList() : renderGroupButtons()} */}
                </div>

                <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                    {/* {renderVariants()} */}

                    <div>
                        <h2 className={`nc-ProductCard__title text-base font-semibold transition-colors`} onClick={clickProductDetail}>
                            {data?.name}
                        </h2>
                     
                    </div>

                    <div className="flex justify-between items-end " onClick={clickProductDetail}>
                        <Prices price={data?.price} isSale={data?.isSale} salePrice={data?.salePrice} />
                    </div>
                </div>
            </div>

            {/* QUICKVIEW */}
            <ModalQuickView show={showModalQuickView} onCloseModalQuickView={() => setShowModalQuickView(false)} />
        </>
    );
};

export default ProductCard;
