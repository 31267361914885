// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// ** API Imports
import axios from 'service/axios';
import { handleLogin } from '../auth/index';

export const getUser = createAsyncThunk('getUserAllData/getUser', async () => {
    try {
        let response = await axios.get('/user', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});
export const createCustomer = createAsyncThunk('userSlice/createCustomer', async (data: {}) => {
    try {
        let response = await axios.post('/customer', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        console.error('Error', err);
        return Promise.reject(err);
    }
});
export const customerLogin = createAsyncThunk('userSlice/customerLogin', async (data: {}, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/login', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        dispatch(handleLogin(response.data));
        return Promise.resolve(response.data);
    } catch (err: any) {
        return rejectWithValue(err);
    }
});
export const userSlice = createSlice({
    name: 'getUserAllData',
    initialState: {
        user: [],
        userOption: []
    },

    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, action) => {
            state.user = action.payload;
        });
    }
});

export default userSlice.reducer;
