import { productImgs } from 'contains/fakeData';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import Prices from './Prices';
// import DefaultImage from '../images/placeholder-small.png';
import service from 'service/constant';
export interface CollectionCard2Props {
    className?: string;
    imgs?: string[];
    name?: string;
    price?: number;
    image?: string;
    description?: string;
    id: string;
}

const MetalCollections: FC<CollectionCard2Props> = ({
    className,
    imgs = [productImgs[9], productImgs[10], productImgs[11], productImgs[8]],
    name,
    image,
    id,
    description = 'Product Description',
    price
}) => {
    const url = service.IMAGE_URL;
    const sliderImage = `${url}${image}`;

    const navigate = useNavigate();

    const clickProductDetail = () => {
        // const titleProps = product.name.split(' ').join('');
        navigate(`/product-details/${id}`);
        // console.log("id", data?.id);
    };
    return (
        <div className={`CollectionCard2 group relative ${className}`} style={{ cursor: 'pointer' }} onClick={clickProductDetail}>
            <div className="relative flex flex-col">
                <NcImage
                    containerClassName="aspect-w-8 aspect-h-8 bg-neutral-100 rounded-2xl overflow-hidden"
                    className="object-fill w-full h-full rounded-2xl"
                    src={sliderImage}
                />
                <div className="grid grid-cols-3 gap-2.5 mt-2.5">
                    {imgs.slice(1, 4).map((im, index) => {
                        return (
                            <NcImage
                                key={index}
                                containerClassName="w-full h-24 sm:h-28"
                                className="object-contain w-full h-full rounded-2xl"
                                src={url + im}
                            />
                        );
                    })}
                    {/* <NcImage
                        containerClassName="w-full h-24 sm:h-28"
                        className="object-contain w-full h-full rounded-2xl"
                        src={DefaultImage}
                    />
                    <NcImage
                        containerClassName="w-full h-24 sm:h-28"
                        className="object-contain w-full h-full rounded-2xl"
                        src={DefaultImage}
                    />
                    <NcImage
                        containerClassName="w-full h-24 sm:h-28"
                        className="object-cover w-full h-full rounded-2xl"
                        src={DefaultImage}
                    /> */}
                </div>
            </div>

            <div className="relative mt-5 flex justify-between">
                {/* TITLE */}
                <div className="flex-1">
                    <h2 className="font-semibold text-lg sm:text-xl ">{name}</h2>
                </div>
                <Prices className="mt-0.5 sm:mt-1 ml-4" price={price} />
            </div>
        </div>
    );
};

export default MetalCollections;
