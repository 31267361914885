import React, { FC, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormFeedback } from 'reactstrap';
import Input from 'shared/Input/Input';
import { toast } from 'react-toastify';
import showLoader from 'components/LoaderComponent';
import axios from 'axios';
import service from 'service/constant';

export interface PageLoginProps {
    className?: string;
}
type FormValues = {
    email: string;
};

const PageForgetpass: FC<PageLoginProps> = ({ className = '' }) => {
    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);

    const ForgetPassword = async (data: any) => {
        return await axios.post(service.API_URL + '/api/v1/client/forget-password', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        // todo: Forget password api integration
        const formData = {
            email: data?.email
        };

        showLoader(true);

        ForgetPassword(formData)
            .then((res) => {
                toast.success(res?.data?.message || 'forget Password Mail send successfully!');
                reset();
                navigate('/');
                showLoader(false);
            })
            .catch((err) => {
                showLoader(false);
                setButtonStatus(false);
                toast.error(err?.response?.data?.message || 'Something went wrong!');
            });
    };
    const validationScheme = yup.object().shape({
        email: yup.string().email('Must be a valid email.').required('Email is required')
    });
    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Forget Password || Instaacard</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Enter Your Registered Email
                </h2>
                <div className="max-w-md mx-auto space-y-6">
                    {/* FORM */}
                    <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
                            <Input
                                type="email"
                                placeholder="example@example.com"
                                className="mt-1"
                                style={{ borderColor: errors.email ? '#ea5455' : '' }}
                                {...register('email')}
                            />
                            {errors && errors.email && <FormFeedback style={{ color: '#ea5455' }}>{errors.email.message}</FormFeedback>}
                        </label>

                        <ButtonPrimary type="submit" disabled={buttonStatus}>
                            Continue
                        </ButtonPrimary>
                    </Form>
                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        New user? {` `}
                        <Link className="text-green-600" to="/signup">
                            Create an account
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PageForgetpass;
