/* eslint-disable no-unused-vars */
import Prices from 'components/Prices';
import CommonLayout from './CommonLayout';
import { getCustomerOrder, getDownloadInvoice } from '../../reduxStore/store/order/index';
import { useEffect, useState } from 'react';
import { AppDispatch, RootState } from 'reduxStore/store';
import { useDispatch, useSelector } from 'react-redux';
import { Order, OrderedItem } from 'reduxStore/store/order/class';
import DefaultImage from '../../images/placeholder-small.png';
import { useNavigate } from 'react-router-dom';
import service from 'service/constant';
import { OrderStatus } from 'service/enums';
import moment from 'moment';

const AccountOrder = () => {
    const [isLoading, setIsLoading] = useState<Boolean>(true);

    const OrderData = useSelector((state: RootState) => state?.customerOrderSlice.customerOrder);
    // console.log(OrderD);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();

    const getOrderData = () => {
        dispatch(getCustomerOrder())
            .unwrap()
            .then(() => {
                setIsLoading(false);
            })
            .catch(() => setIsLoading(false));
    };

    useEffect(() => {
        getOrderData();
    }, []);
    // const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    // const clickOrderDetail = (order: Order) => {
    //     navigate(`/order/${order.id}`, { state: { order: order } });
    // };

    const renderProductItem = (item: OrderedItem, index: number) => {
        const { ProductMaster, quantity, sellingPrice } = item; // Assuming there is always one item in OrderedItems array

        const url = service.IMAGE_URL;
        const { name, ProductImageMappings, dimension, sku } = ProductMaster;
        const imagePath = ProductImageMappings[0]?.ProductImage?.path;
        const image = `${url}${imagePath}`;
        const displayImage = imagePath ? image : DefaultImage;

        return (
            <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img src={displayImage} alt={name} className="h-full w-full object-cover object-center" />
                </div>

                <div className="ml-4 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div>
                                <h3 className="text-base font-medium line-clamp-1">{name}</h3>
                                <p className="mt-1 text-sm text-slate-500 dark:text-slate-400">
                                    <span>SKU: {sku} </span>
                                    <span className="mx-2 border-l border-slate-200 dark:border-slate-700 h-4"></span>
                                    <span>Price: ₹{sellingPrice.toFixed(2)}</span>
                                </p>
                                <p className="text-gray-500 dark:text-slate-400 flex items-center">
                                    <span className="hidden sm:inline-block">Qty</span>
                                    <span className="inline-block sm:hidden">x</span>
                                    <span className="ml-2">{quantity}</span>
                                </p>
                            </div>
                            {/* <Prices className="mt-0.5 ml-2" price={sellingPrice * quantity} /> */}
                        </div>
                    </div>
                    {/* <div className="flex flex-1 items-end justify-between text-sm">
                        <div className="flex">
                            <button type="button" className="font-medium text-indigo-600 dark:text-primary-500 ">
                                Leave review
                            </button>
                        </div>
                    </div> */}
                </div>
            </div>
        );
    };

    const renderOrder = (order: Order, index: number) => {
        return (
            <div key={index} className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                <div className="flex  sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div className="flex flex-1 flex-col">
                        <div className="flex justify-between">
                            <div>
                                <p className="text-lg font-semibold">{order.orderNumber}</p>
                                <p className="text-slate-500 dark:text-slate-400 text-sm mt-1.5 sm:mt-2">
                                    <span>{moment(order.createdAt).format('Do MMMM YYYY')}</span>
                                    <span className="mx-2">·</span>
                                    <span className="text-primary-500">
                                        {Object.keys(OrderStatus)[Object.values(OrderStatus).indexOf(order.orderStatus)] || 'N.A.'}
                                    </span>
                                </p>
                            </div>
                            <div>
                                <div className="flex justify-end">
                                    <Prices className="mt-0.5 ml-2" price={order.totalAmount} />
                                </div>
                                <button
                                    type="button"
                                    className="font-medium text-indigo-600 dark:text-primary-500"
                                    onClick={(_e) => {
                                        console.log('lath');
                                        _e.preventDefault();
                                        dispatch(getDownloadInvoice(order.id));
                                    }}
                                >
                                    Download Invoice
                                </button>
                                {/* <ButtonPrimary
                                    sizeClass="py-1 px-2 sm:py-3.5 sm:px-6"
                                    fontSize="text-sm font-medium"
                                    className="flex-1 flex-shrink-0"
                                    onClick={() => {
                                        console.log('lath');
                                    }}
                                >
                                    <ArrowDown className="hidden sm:inline-block w-5 h-5 mb-0.5" />
                                    <span className="ml-3">Download Invoice</span>
                                </ButtonPrimary> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    {order.OrderedItems && order.OrderedItems.length > 0 && order.OrderedItems?.map(renderProductItem)}
                </div>
            </div>
        );
    };

    const renderProductLoader = (length: number = 1) => {
        return Array.from({ length: length }).map((index: any) => {
            return (
                <div key={index} className="flex py-4 sm:py-7 last:pb-0 first:pt-0">
                    <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-gray-200 animate-pulse"></div>

                    <div className="ml-4 flex flex-1 flex-col">
                        <div>
                            <div className="flex justify-between ">
                                <div>
                                    <div className="bg-gray-200 rounded-full animate-pulse h-7 w-72"></div>

                                    <div className="flex">
                                        <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2"></div>
                                        <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2 ms-1.5"></div>
                                    </div>

                                    <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        });
    };
    const renderLoader = () => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                <div className="flex  sm:flex-row sm:justify-between sm:items-center p-4 sm:p-8 bg-slate-50 dark:bg-slate-500/5">
                    <div className="flex flex-1 flex-col">
                        <div className="flex justify-between">
                            <div>
                                <div className="bg-gray-200 rounded-full animate-pulse h-9 w-72"></div>
                                <div className="flex">
                                    <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2"></div>
                                    <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2 ms-1.5"></div>
                                </div>
                            </div>
                            <div>
                                <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2"></div>
                                <div className="bg-gray-200 rounded-full animate-pulse h-6 w-28 mt-2"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    {renderProductLoader(2)}
                </div>
            </div>
        );
    };

    return (
        <div>
            <CommonLayout>
                <div className="space-y-10 sm:space-y-12">
                    {/* HEADING */}
                    <h2 className="text-2xl sm:text-3xl font-semibold">Order History</h2>
                    {isLoading && renderLoader()}

                    {!isLoading && OrderData?.map((row: Order, index: number) => renderOrder(row, index))}
                </div>
            </CommonLayout>
        </div>
    );
};

export default AccountOrder;
