// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
// import useJwt from '../auth/jwt/useJwt'

// auth config import
import authConfig from '../../../auth/config';
import axios from '../../../service/axios';

// Axios Imports

// Loader Components
// import showLoader from '@components/loader';

// secure ls
// import { secureGet, secureSet } from '@src/service/secureLs';

// import { ConnectClient } from '../service/SignalRConnection';

const initialUser = () => {
    // const item = window.localStorage.getItem(authConfig.storageUserDataKeyName);
    const item = window.localStorage.getItem(authConfig.storageUserDataKeyName);

    //** Parse stored json or if none return initialValue
    return item && item !== 'undefined' ? JSON.parse(item) : {};
    // return item
};

const getAccessToken = () => {
    const item = window.localStorage.getItem(authConfig.storageTokenKeyName);
    //** Parse stored json or if none return initialValue
    return item ? JSON.parse(item) : null;
};

export const verifyToken = createAsyncThunk('authentication/verifyToken', async () => {
    try {
        const res = await axios.get('/token', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return res.data;
    } catch (err) {
        return {};
    }
});

export const authSlice = createSlice({
    name: 'authentication',
    initialState: {
        userData: initialUser(),
        accessToken: getAccessToken()
    },
    reducers: {
        // changeUserData: (state : any, action) => {
        //         state[authConfig.storageUserDataKeyName] = action.payload.userData;
        //         // localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
        //         (authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
        //     },
        // changeAccessToken: (state, action) => {
        //     (state as any)[authConfig.storageTokenKeyName] = action.payload.accessToken;
        //     localStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        // },
        //     changeOriginToken: (state, action) => {
        //         const originalAccessToken = window.localStorage.getItem(authConfig.storageTokenKeyName);
        //         (state as any)[authConfig.storageMockAdminKeyName] = originalAccessToken;
        //         localStorage.setItem(authConfig.storageMockAdminKeyName, originalAccessToken);
        //     },
        //     changeOriginReplaceToken: (state, action) => {
        //         const originalAccessToken = window.localStorage.getItem(authConfig.storageMockAdminKeyName);
        //         (state as any)[authConfig.storageTokenKeyName] = originalAccessToken;
        //         localStorage.setItem(authConfig.storageTokenKeyName, originalAccessToken);
        //         localStorage.removeItem(authConfig.storageMockAdminKeyName);
        //     },
        handleLogin: (state, action) => {
            state.userData = action.payload.userData;
            state.accessToken = action.payload[authConfig.storageTokenKeyName];
            console.log(action.payload);
            localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
            localStorage.setItem(authConfig.storageTokenKeyName, JSON.stringify(action.payload.accessToken));
        },
        handleLogout: (state) => {
            state.userData = null;
            state.accessToken = null;
            // ** Remove user, accessToken & refreshToken from localStorage
            localStorage.removeItem(authConfig.storageUserDataKeyName);
            localStorage.removeItem(authConfig.storageTokenKeyName);
        }
    },
    extraReducers: (builder) => {
        builder.addCase(verifyToken.fulfilled, (state, action) => {
            (state as any)[authConfig.storageUserDataKeyName] = action.payload.userData;
            localStorage.setItem(authConfig.storageUserDataKeyName, JSON.stringify(action.payload.userData));
        });
    }
});

export const { handleLogin, handleLogout } = authSlice.actions;

export default authSlice.reducer;
