/* eslint-disable no-unused-vars */
import NcInputNumber from 'components/QuantityAdd';
import Prices from 'components/Prices';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ContactInfo from './ContactInfo';
import ShippingAddress from './ShippingAddress';
import ShippingMethod from './Shippingmethod';
import BillingAddress from './BillingAddress';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reduxStore/store';
import DefaultImage from '../../images/placeholder-small.png';
import { isUserLoggedIn } from 'utils/User';
import { deleteFromLocal, deleteToCart, updateToCartQuantity } from '../../reduxStore/store/cart/index';
import { applyCoupon, createCustomerOrder, getCustomerAddress } from '../../reduxStore/store/order/index';
import { secureGet } from 'service/secureLs';
import { CheckoutData } from 'reduxStore/store/order/class';
import service from 'service/constant';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { X } from 'react-feather';
import { FormFeedback } from 'reactstrap';
import Input from 'shared/Input/Input';
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline';

const CheckoutPage = () => {
    const dispatch = useDispatch<AppDispatch>();

    const [tabActive, setTabActive] = useState<
        'ContactInfo' | 'ShippingAddress' | 'PaymentMethod' | 'ShippingMethod' | 'BillingAddress' | 'Confirm'
    >('ShippingAddress');
    const [checkSameAddress, setCheckIsSameAddress] = useState(true);
    const [confirmBillingAddress, setConfirmBillingAddress] = useState<any | undefined>();
    const [confirmSameAddress, setConfirmSameAddress] = useState(true);

    const [shippingSet, setShippingSet] = useState(false);
    const [billingSet, setBillingSet] = useState(false);
    const [discountData, setDiscountData] = useState<any>({});

    const cardData = useSelector((state: RootState) => state?.CartSlice?.cart);

    const {
        register,
        formState: { errors },
        setValue,
        getValues,
        setError
    } = useForm({
        mode: 'onChange',
        defaultValues: {
            discountCode: ''
        }
    });

    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate();
    const handleRemoveFromCart = (item: any) => {
        if (isUserLoggedIn()) {
            dispatch(deleteToCart(item?.id));
        } else {
            dispatch(deleteFromLocal(item));
        }
    };
    const getCustomerAddressData = () => {
        dispatch(getCustomerAddress());
    };
    // eslint-disable-next-line no-unused-vars
    const [updateQty, setUpdateQty] = useState<number | undefined>(/* initial value */);
    const handleScrollToEl = (id: string) => {
        const element = document.getElementById(id);
        setTimeout(() => {
            element?.scrollIntoView({ behavior: 'smooth' });
        }, 80);
    };

    const handleQtyUpdate = (item: any, newQuantity: number) => {
        //  let isStockAvailable = 0;
        //  if (isUserLoggedIn()) {
        //    isStockAvailable = await getStockQuantity(item?.productId);
        //  } else {
        //    isStockAvailable = await getStockQuantity(item?.ProductMaster?.id);
        //  }
        const value = newQuantity;

        if (value > 0) {
            setUpdateQty(item?.quantity * item.ProductMaster?.price);
            if (isUserLoggedIn()) {
                dispatch(
                    updateToCartQuantity({
                        id: item?.id,
                        productId: item?.productId,
                        quantity: value
                    })
                );
            } else {
                dispatch(
                    updateToCartQuantity({
                        product: item,
                        quantity: value
                    })
                );
            }
        } else {
            //  setQuantityError(false);
        }
    };
    const calculateTotalPrice = () => {
        let totalPrice = 0;
        if (discountData?.id) {
            return discountData?.discountedAmount;
        }
        cardData.forEach((item: any) => {
            if (item?.ProductMaster?.isSale == true) {
                totalPrice += item?.quantity * item?.ProductMaster?.salePrice;
            } else {
                totalPrice += item?.quantity * item?.ProductMaster?.price;
            }
        });

        return totalPrice;
        console.log('totalPrice', totalPrice);
    };
    const handleFormSubmit = (checkSameAddress: any, currentAddress: any) => {
        setConfirmSameAddress(checkSameAddress);
        setConfirmBillingAddress(currentAddress);
        setBillingSet(true);
    };

    const handleShippingFormSubmit = () => {
        if (checkSameAddress) {
            setConfirmSameAddress(true);
            setConfirmBillingAddress(checkSameAddress);
        }
        setShippingSet(true);
    };

    const Payment = async () => {
        const groupData: CheckoutData = {
            cartIds: cardData.map((item) => item.id),
            checkIsSameAddress: confirmSameAddress
        };

        if (discountData?.id) groupData.couponCode = discountData?.couponCode;

        let shippingAddress = secureGet('customerShippingAddress');

        // groupData.orderNote = shippingAddress?.orderNote;
        let shippingAddressObj = {
            address1: shippingAddress.address1,
            address2: shippingAddress.address2,
            cityName: shippingAddress.cityName,
            stateName: shippingAddress.stateName,
            stateId: shippingAddress.stateId,
            countryName: shippingAddress.countryName,
            zipCode: shippingAddress.zipCode
        };

        if (confirmSameAddress == true) {
            if (shippingAddress?.value == null) {
                groupData.newShippingAddress = { ...shippingAddressObj };
                groupData.newBillingAddress = { ...shippingAddressObj };
            } else {
                groupData.shippingAddressId = shippingAddress.value;
                groupData.billingAddressId = shippingAddress.value;
            }
        } else {
            if (shippingAddress.value == null) {
                groupData.newShippingAddress = { ...shippingAddressObj };
            } else {
                groupData.shippingAddressId = shippingAddress.value;
            }
            if (!confirmBillingAddress || confirmBillingAddress?.value == null) {
                groupData.newBillingAddress = {
                    address1: confirmBillingAddress.address1,
                    address2: confirmBillingAddress.address2,
                    cityName: confirmBillingAddress.cityName,
                    stateName: confirmBillingAddress.stateName?.label,
                    stateId: confirmBillingAddress.stateName?.value,
                    countryName: confirmBillingAddress.countryName,
                    zipCode: confirmBillingAddress.zipCode
                };
            } else {
                groupData.billingAddressId = confirmBillingAddress.value;
            }
        }
        try {
            let response: any = await dispatch(createCustomerOrder(groupData)).unwrap();

            // Submit the payment form to PayU
            const form = document.createElement('form');
            form.method = 'post';
            form.action = response?.paymentURL;
            Object.entries(response?.data).forEach(([key, value]) => {
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = key;
                // let value1 = any;
                input.value = String(value);
                form.appendChild(input);
            });

            document.body.appendChild(form);
            form.submit();
        } catch (err: any) {
            console.log('Failed to create order.');
            // return toast.error(err?.response?.data?.message || 'Something went wrong please try again!');
        }
    };

    const applyCouponCode = async () => {
        try {
            let discountCode = getValues('discountCode');
            if (!discountCode || discountCode == '' || discountCode == null) {
                return setError('discountCode', { type: 'custom', message: 'Discount Code is required.' });
            }

            let discountedData = {
                couponCode: discountCode,
                cartIds: cardData.map((m1) => m1.id)
            };
            await dispatch(applyCoupon(discountedData))
                .unwrap()
                .then((res) => setDiscountData(res.data));
        } catch (err: any) {
            return toast.error(err?.response?.data?.message || 'Something went wrong!');
        }
    };

    const renderProduct = (item: any, index: number) => {
        const url = service.IMAGE_URL;

        const image = item?.ProductMaster?.ProductImageMappings?.[0]?.ProductImage?.path
            ? `${url}${item?.ProductMaster.ProductImageMappings?.[0]?.ProductImage?.path}`
            : DefaultImage;

        return (
            <div key={index} className="relative flex py-7 first:pt-0 last:pb-0">
                <div className="relative h-36 w-24 sm:w-28 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img
                        src={image}
                        // alt={name}
                        className="h-full w-full object-cover"
                    />
                </div>

                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div className="flex-[1.5] ">
                                <h3 className="text-base font-semibold">{item?.ProductMaster?.name}</h3>
                                {/* <div className="mt-1.5 sm:mt-2.5 flex text-sm text-slate-600 dark:text-slate-300"></div> */}
                                {/* <div className="mt-3 flex justify-between w-full sm:hidden relative">
                                    <select
                                        name="qty"
                                        id="qty"
                                        className="form-select text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                                    >
                                        <option value="1">1</option>
                                        <option value="2">2</option>
                                        <option value="3">3</option>
                                        <option value="4">4</option>
                                        <option value="5">5</option>
                                        <option value="6">6</option>
                                        <option value="7">7</option>
                                    </select>
                                    <Prices
                                        contentClass="py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium h-full"
                                        price={item?.ProductMaster?.Price}
                                    />
                                </div> */}
                            </div>

                            <div className="hidden flex-1 sm:flex justify-end">
                                <Prices
                                    price={item?.ProductMaster?.price}
                                    className="mt-0.5"
                                    isSale={item?.ProductMaster?.isSale}
                                    salePrice={item?.ProductMaster?.salePrice}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                        <div className="hidden sm:block text-center relative">
                            <NcInputNumber
                                className="relative z-10"
                                quantity={item?.quantity}
                                onChange={(newQuantity) => handleQtyUpdate(item, newQuantity)}
                            />
                        </div>

                        <a
                            href="##"
                            onClick={() => handleRemoveFromCart(item)}
                            className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                        >
                            <span>Remove</span>
                        </a>
                    </div>
                </div>
            </div>
        );
    };

    const renderLeft = () => {
        return (
            <div className="space-y-8">
                <div id="ContactInfo" className="scroll-mt-24">
                    <ContactInfo
                        item={cardData}
                        isActive={tabActive === 'ContactInfo'}
                        onOpenActive={() => {
                            setTabActive('ContactInfo');
                            handleScrollToEl('ContactInfo');
                        }}
                        onCloseActive={() => {
                            setTabActive('ShippingAddress');
                            handleScrollToEl('ShippingAddress');
                        }}
                    />
                </div>

                <div id="ShippingAddress" className="scroll-mt-24">
                    <ShippingAddress
                        isActive={tabActive === 'ShippingAddress'}
                        onFormSubmit={handleShippingFormSubmit}
                        onOpenActive={() => {
                            setTabActive('ShippingAddress');
                            handleScrollToEl('ShippingAddress');
                        }}
                        onCloseActive={() => {
                            setTabActive('BillingAddress');
                            handleScrollToEl('BillingAddress');
                        }}
                        tabActive={() => {
                            setTabActive('BillingAddress');
                        }}
                    />
                </div>

                <div id="BillingAddress" className="scroll-mt-24">
                    <BillingAddress
                        isActive={tabActive === 'BillingAddress'}
                        checkSameAddress={checkSameAddress}
                        setCheckIsSameAddress={setCheckIsSameAddress}
                        // Payment={Payment}
                        onFormSubmit={handleFormSubmit}
                        onOpenActive={() => {
                            setTabActive('BillingAddress');
                            handleScrollToEl('BillingAddress');
                        }}
                        onCloseActive={() => {
                            setTabActive('Confirm');
                            // handleScrollToEl('ShippingMethod');
                        }}
                    />
                </div>

                <div id="ShippingMethod" className="scroll-mt-24">
                    <ShippingMethod isActive={tabActive === 'ShippingMethod'} />
                </div>
            </div>
        );
    };

    useEffect(() => {
        getCustomerAddressData();
    }, []);

    return (
        <div className="nc-CheckoutPage">
            <Helmet>
                <title>Checkout || Instaacard</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div className="mb-16">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Checkout</h2>
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        <Link to={'/#'} className="">
                            Home
                        </Link>
                        {/* <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <Link to={'/#'} className="">
                            Clothing Categories
                        </Link> */}
                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">Checkout</span>
                    </div>
                </div>

                <div className="flex flex-col lg:flex-row">
                    <div className="flex-1">{renderLeft()}</div>

                    <div className="flex-shrink-0 border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:lg:mx-14 2xl:mx-16 "></div>

                    <div className="w-full lg:w-[36%] ">
                        <div className="sticky top-28">
                            <h3 className="text-lg font-semibold">Order summary</h3>
                            <div className="mt-8 divide-y divide-slate-200/70 dark:divide-slate-700 ">
                                {cardData && cardData?.map(renderProduct)}
                            </div>

                            <div className="mt-10 pt-6 text-sm text-slate-500 dark:text-slate-400 border-t border-slate-200/70 dark:border-slate-700 ">
                                {!discountData.id ? (
                                    <>
                                        <div className={`flex items-center gap-2`}>
                                            <div>
                                                <Input
                                                    className="form-control"
                                                    id="discountCode"
                                                    placeholder="Discount Code"
                                                    {...register('discountCode')}
                                                />
                                                {errors && errors?.discountCode && (
                                                    <FormFeedback>{errors?.discountCode?.message}</FormFeedback>
                                                )}
                                            </div>

                                            <button
                                                type="button"
                                                id="apply-discount"
                                                onClick={() => applyCouponCode()}
                                                className="btn-outline btn ms-25 font-medium text-primary-6000 hover:text-primary-500"
                                            >
                                                Apply
                                            </button>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="flex mt-auto pt-4 items-end justify-between text-sm">
                                            <div
                                                className={`flex items-center justify-center px-4 py-2 text-sm rounded-full border focus:outline-none cursor-pointer select-none border-primary-500 bg-primary-50 text-primary-900`}
                                            >
                                                <span className="line-clamp-1 ml-2">
                                                    {discountData?.couponCode} &#40;{String(discountData?.discountValue)}
                                                    {discountData?.discountType == '0' ? '% OFF' : 'Rs FLAT'} &#41;
                                                </span>
                                                <XMarkIcon
                                                    // className="w-6 h-6 hover:bg-red"
                                                    className="w-6 h-6"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setDiscountData({});
                                                        setValue('discountCode', '');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                                <div className="mt-4 flex justify-between py-2.5">
                                    <span>Subtotal</span>₹ {calculateTotalPrice().toFixed(2)}
                                </div>
                                {discountData?.id && (
                                    <div className="mt-0 flex justify-between py-1">
                                        <span>Discount</span>₹ {(discountData?.discount).toFixed(2)}
                                    </div>
                                )}

                                <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-2">
                                    <span>Order total</span>
                                    <span> ₹ {calculateTotalPrice().toFixed(2)}</span>
                                </div>
                            </div>
                            <ButtonPrimary
                                disabled={!shippingSet || !billingSet}
                                // href="/account-my-order"
                                className="mt-8 w-full"
                                // type="submit"
                                onClick={() => {
                                    Payment();
                                }}
                            >
                                Confirm order
                            </ButtonPrimary>
                            {/* <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                                <p className="block relative pl-5">
                                    <svg className="w-4 h-4 absolute -left-1 top-0.5" viewBox="0 0 24 24" fill="none">
                                        <path
                                            d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M12 8V13"
                                            stroke="currentColor"
                                            strokeWidth="1.5"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                        <path
                                            d="M11.9945 16H12.0035"
                                            stroke="currentColor"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        />
                                    </svg>
                                    Learn more{` `}
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="##"
                                        className="text-slate-900 dark:text-slate-200 underline font-medium"
                                    >
                                        Taxes
                                    </a>
                                    <span>
                                        {` `}and{` `}
                                    </span>
                                    <a
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        href="##"
                                        className="text-slate-900 dark:text-slate-200 underline font-medium"
                                    >
                                        Shipping
                                    </a>
                                    {` `} information
                                </p>
                            </div> */}
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default CheckoutPage;
