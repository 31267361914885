/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';

const PrivacyPolicy = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl mb-5">
                <div className="max-w-screen-md mx-auto space-y-5">
                    {/* <Badge href="##" color="purple" name="Traveler" /> */}
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Privacy Policy
                    </h1>
                    {/* <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        INSTAAPRINT<sup>TM</sup> value your trust with us. INSTAAPRINT<sup>TM</sup> insist upon maintaining the highest
                        privacy standards for secure transactions and customer information privacy. Please read the following statement to
                        learn about our information gathering and dissemination practices.
                    </span>

                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        By visiting our website you agree to be bound by the terms and conditions of this Privacy Policy. If you do not
                        agree please do not use or access our website. By mere use of the website, you expressly consent to our use and
                        disclosure of your personal information in accordance with this Privacy Policy. This Privacy Policy is incorporated
                        into and subject to the Terms of Use.
                    </span>

                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
                        Note: INSTAAPRINT<sup>TM</sup> privacy policy is subject to change at any time without notice. INSTAAPRINT
                        <sup>TM</sup> request you to review our privacy policy periodically to make sure you are aware of any
                        changes/amendment therein.
                    </span> */}
                    <p>
                        This Privacy Policy ("Privacy Policy") aims to inform you about the information we collect when you make a purchase
                        on our store, www.instaacard.com, operated by<strong> Trumpcard Initiatives Worldwide&nbsp; Pvt. Ltd</strong>. At{' '}
                        <strong>Instaacard,</strong> safeguarding your private information is our top priority, and we are dedicated to
                        transparency. We respect the privacy concerns of our customers and value the trust you place in us when using our
                        website and services.
                    </p>
                    <p>
                        By "personally identifying information," we mean data that directly identifies you, such as your name, address,
                        phone number, payment details, and email addresses. We also gather demographic information, like your postcode, and
                        may collect information about your computer, including your IP address, when you visit our website.
                    </p>
                    <p>
                        &nbsp;<strong>Changes to this Privacy Policy:</strong>
                    </p>
                    <p>
                        We reserve the right to make changes to this policy at any time. It is advisable to visit this page frequently to
                        stay updated. If we decide to use personally identifiable information in a manner different from the initial
                        statement, users will be promptly notified by email, with the option to permit or deny the use of their information
                        in this separate manner.
                    </p>
                    <p>
                        &nbsp;<strong>Privacy Policy for Children:</strong>
                    </p>
                    <p>
                        We do not intentionally collect Personal Data from users under 18. If a user under 18 submits Personal Data, we will
                        attempt to delete it promptly upon discovery. Parents or guardians discovering their child provided information
                        without consent should contact us, and we will delete the information as soon as reasonably possible.
                    </p>
                    <p>
                        &nbsp;<strong>How We Obtain or Collect Information about You:</strong>
                    </p>
                    <p>
                        <strong>Information You Provide:</strong>
                    </p>
                    <p>"Personal Data" includes information like your name, telephone number, location, email address, and username.</p>
                    <p>
                        "Social Logins" from platforms like Facebook, Google, or Apple may provide first name, last name, and email address
                        to facilitate account creation.
                    </p>
                    <p>
                        <strong>Automated Information Collection:</strong>
                    </p>
                    <p>Log Information: Server logs record browser details, IP address, web requests, browser type, and cookies.</p>
                    <p>Cookies: Sent when you log in or purchase products, used to improve site quality.</p>
                    <p>Links: Clickable links may be tracked for product improvement.</p>
                    <p>
                        <strong>How We Use Your Information:</strong>
                    </p>
                    <p>
                        We use the gathered information to provide, improve, and develop our website and services. This includes account
                        creation, secure account management, sending newsletters, personalized recommendations, enhancing safety and
                        security, troubleshooting service issues, customer communication, service operation and improvement, fraud
                        prevention, and legal compliance.
                    </p>
                    <p>
                        <strong>Personal Information That We Share:</strong>
                    </p>
                    <p>
                        We do not sell, rent, or trade personal information. Sharing is limited to third-party service providers,
                        subsidiaries, affiliates, or in cases of merger, protection, breach of terms, compliance with laws, and user
                        consent.
                    </p>
                    <p>
                        <strong>Data Security:</strong>
                    </p>
                    <p>
                        While we implement security measures, no online transmission is 100% secure. Users are encouraged to use strong,
                        unique passwords and limit access to their accounts. Various policies, including encryption, access, and retention
                        policies, are in place to guard against unauthorized access and unnecessary data retention.
                    </p>
                    <p>
                        &nbsp;<strong>Data Retention and Deletion:</strong>
                    </p>
                    <p>
                        We retain personal data for necessary business purposes. Users can request deletion or anonymization, but certain
                        data may be retained for legal obligations, dispute resolution, or legitimate business interests.
                    </p>
                    <p>
                        &nbsp;<strong>Links to Other Websites:</strong>
                    </p>
                    <p>
                        This Privacy Policy applies solely to our site. Users are advised to review the privacy policies of linked
                        third-party sites.
                    </p>
                    <p>
                        &nbsp;<strong>Contact Information:</strong>
                    </p>
                    <p>For questions, complaints, or more information, users can contact us at support@instaacard.com.</p>
                    <p>
                        <strong>Rights and Choices:</strong>
                    </p>
                    <p>
                        Users have rights to access, update, erase, and restrict processing of their personal data. Opt-out options are
                        available for newsletters and commercial emails.
                    </p>
                    <p>
                        &nbsp;This Privacy Policy is designed to ensure clarity and transparency regarding the handling of personal
                        information by <strong>Trumpcard Initiatives Worldwide Pvt. Ltd</strong>.
                    </p>{' '}
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert mb-5">
                <ul>
                    <li>
                        <h3>Collection of Personally Identifiable Information and other Information</h3>
                        <p>
                            When you use our website, we collect and store your personal information which is provided by you from time to
                            time. Our primary goal in doing so is to provide you a safe, efficient, smooth and customized experience. This
                            allows us to provide services and features that most likely meet your needs, and to customize our website to
                            make your experience safer and easier. More importantly, while doing so, we collect personal information from
                            you that we consider necessary for enhancing user experience.
                        </p>
                        <p>
                            In general, you can browse the website without disclosing your personal information. Once you provide us your
                            personal information, you are no more anonymous. Where possible, we indicate which fields are required and which
                            fields are optional. You always have the option to not provide information by choosing not to use a particular
                            service or feature on the Website. We may automatically track certain information about you based upon your
                            behavior on our Website. We use this information to do internal research on our users’ demographics, interests,
                            and behavior to better understand, protect and serve our users. This information is compiled and analyzed on an
                            aggregated basis. This information may include the URL that you just came from (whether this URL is on our
                            Website or not), which URL you next go to (whether this URL is on our Website or not), your computer browser
                            information, and your IP address.
                        </p>
                        <p>
                            We use data collection devices such as “cookies” on certain pages of the Website to help analyze our web page
                            flow, measure promotional effectiveness, and promote trust and safety. “Cookies” are small files placed on your
                            hard drive that assist us in providing our services. We offer certain features that are only available through
                            the use of a “cookie”.
                        </p>
                        <p>
                            We also use cookies to allow you to enter your password less frequently during a session. Cookies can also help
                            us provide information that is targeted to your interests. Most cookies are “session cookies,” meaning that they
                            are automatically deleted from your hard drive at the end of a session. You are always free to decline our
                            cookies if your browser permits, although in that case you may not be able to use certain features on the
                            Website and you may be required to re-enter your password more frequently during a session.
                        </p>
                        <p>
                            Additionally, you may encounter “cookies” or other similar devices on certain pages of the Website that are
                            placed by third parties. We do not control the use of cookies by third parties.
                        </p>
                        <p>
                            If you choose to buy on the Website, we collect information about your buying behavior. If you transact with us,
                            we collect some additional information, such as a billing address, a credit / debit card number and a credit /
                            debit card expiration date and/ or other payment instrument details and tracking information from cheques or
                            money orders. If you choose to post messages on our message boards, chat rooms or other message areas or leave
                            feedback, we will collect that information you provide to us. We retain this information as necessary to resolve
                            disputes, provide customer support and troubleshoot problems as permitted by law.
                        </p>
                        <p>
                            If you send us personal correspondence, such as emails or letters, or if other users or third parties send us
                            correspondence about your activities or postings on the Website, we may collect such information into a file
                            specific to you.
                        </p>
                        <p>
                            We collect personally identifiable information (email address, name, phone number, credit card / debit card /
                            other payment instrument details, etc.) from you when you set up a free account with us. While you can browse
                            some sections of our Website without being a registered member, certain activities (such as placing an order) do
                            require registration. We do use your contact information to send you offers based on your previous orders and
                            your interests.
                        </p>
                    </li>
                    <li>
                        <h3>Use of Demographic / Profile Data / Your Information</h3>
                        <p>
                            We use personal information to provide the services you request. To the extent we use your personal information
                            to market to you, we will provide you the ability to opt-out of such uses. We use your personal information to
                            resolve disputes; troubleshoot problems; help promote a safe service; collect money; measure consumer interest
                            in our products and services, inform you about online and offline offers, products, services, and updates;
                            customize your experience; detect and protect us against error, fraud and other criminal activity; enforce our
                            terms and conditions; and as otherwise described to you at the time of collection.
                        </p>
                        <p>
                            In our efforts to continually improve our product and service offerings, we collect and analyse demographic and
                            profile data about our users’ activity on our Website.
                        </p>
                        <p>
                            We identify and use your IP address to help diagnose problems with our server, and to administer our Website.
                            Your IP address is also used to help identify you and to gather broad demographic information.
                        </p>

                        <p>
                            We will occasionally ask you to complete optional online surveys. These surveys may ask you for contact
                            information and demographic information (like zip code, age, or income level). We use this data to tailor your
                            experience at our Website, providing you with content that we think you might be interested in and to display
                            content according to your preferences.
                        </p>
                        <p>
                            We also collect user photos under our Tulipos feature and we don’t share the info with any third party and the
                            info stays safe with us, but we may use it on our website.
                        </p>
                    </li>
                    <li>
                        <h3>Cookies</h3>
                        <p>
                            A “cookie” is a small piece of information stored by a web server on a web browser so it can be later read back
                            from that browser. Cookies are useful for enabling the browser to remember information specific to a given user.
                            We place both permanent and temporary cookies in your computer’s hard drive. The cookies do not contain any of
                            your personally identifiable information.
                        </p>
                    </li>
                    <li>
                        <h3>Sharing of personal information</h3>
                        <p>
                            We may share personal information with our other corporate entities and affiliates. These entities and
                            affiliates may market to you as a result of such sharing unless you explicitly opt-out.
                        </p>
                        <p>
                            We may disclose personal information to third parties. This disclosure may be required for us to provide you
                            access to our Services, to comply with our legal obligations, to enforce our User Agreement, to facilitate our
                            marketing and advertising activities, or to prevent, detect, mitigate, and investigate fraudulent or illegal
                            activities related to our Services. We do not disclose your personal information to third parties for their
                            marketing and advertising purposes without your explicit consent.
                        </p>
                        <p>
                            We may disclose personal information if required to do so by law or in the good faith belief that such
                            disclosure is reasonably necessary to respond to subpoenas, court orders, or other legal process. We may
                            disclose personal information to law enforcement offices, third party rights owners, or others in the good faith
                            belief that such disclosure is reasonably necessary to: enforce our Terms or Privacy Policy; respond to claims
                            that an advertisement, posting or other content violates the rights of a third party; or protect the rights,
                            property or personal safety of our users or the general public.
                        </p>
                        <p>
                            We and our affiliates will share / sell some or all of your personal information with another business entity
                            should we (or our assets) plan to merge with, or be acquired by that business entity, or re-organization,
                            amalgamation, restructuring of business. Should such a transaction occur that other business entity (or the new
                            combined entity) will be required to follow this privacy policy with respect to your personal information.
                        </p>
                    </li>
                    <li>
                        <h3>Links to Other Sites</h3>
                        <p>
                            Our Website links to other websites that may collect personally identifiable information about you. tulipos.com
                            is not responsible for the privacy practices or the content of those linked websites.
                        </p>
                    </li>
                    <li>
                        <h3>Security Precautions</h3>
                        <p>
                            We provide all users with the opportunity to opt-out of receiving non-essential (promotional, marketing-related)
                            communications from us on behalf of our partners, and from us in general, after setting up an account.
                        </p>
                        <p>
                            If you want to remove your contact information from all instaaprint.com lists and newsletters, please click on
                            unsubscribe in the email you receive
                        </p>
                    </li>
                    <li>
                        <h3>Advertisements on instaaprint.com</h3>
                        <p>
                            We may use third-party advertising companies to serve ads when you visit our Website. These companies may use
                            information (not including your name, address, email address, or telephone number) about your visits to this and
                            other websites in order to provide advertisements about goods and services of interest to you.
                        </p>
                    </li>
                    <li>
                        <h3>Your Consent</h3>
                        <p>
                            By using the Website and/ or by providing your information, you consent to the collection and use of the
                            information you disclose on the Website in accordance with this Privacy Policy, including but not limited to
                            Your consent for sharing your information as per this privacy policy.
                        </p>
                        <p>
                            By using the Website and/ or by providing your information, you consent to the collection and use of the
                            information you disclose on the Website in accordance with this Privacy Policy, including but not limited to
                            Your consent for sharing your information as per this privacy policy.
                        </p>
                    </li>
                    <li>
                        <h3>Grievance Officer</h3>
                        <p>
                            In accordance with Information Technology Act 2000 and rules made there under, the name and contact details of
                            the Grievance Officer are provided below:
                        </p>
                    </li>

                    <li>
                        <h3>Questions?</h3>
                        <p> Please contact us regarding any questions regarding this statement.</p>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Terms and Condition | Instaacard</title>
            </Helmet>
            {renderHeader()}

            {/* <div className="nc-SingleContent container space-y-10">{renderContent()}</div> */}
        </div>
    );
};

export default PrivacyPolicy;
