// Secure ls
import SecureLS from 'secure-ls';

export const secureLs = new SecureLS({
    encodingType: 'aes',
    encryptionSecret: 'insta-card',
    isCompression: false
});
export const secureSet = (key: string, value: any) => {
    try {
        secureLs.set(key, value);
        return true;
    } catch (err) {
        return false;
    }
};

export const secureGet = (key: string) => {
    try {
        let data = secureLs.get(key);
        if (data) return data;
        return null;
    } catch (err) {
        return null;
    }
};

export default secureLs;
