import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import ModalQuickView from './ModalQuickView';
import { Card } from 'reactstrap';
import service from 'service/constant';

export interface ProductCardProps {
    className?: string;
    data?: {
        label: string;
        value: string;
        image: string;
    };
}

const ProductCard: FC<ProductCardProps> = ({ className = '', data }) => {
    const [showModalQuickView, setShowModalQuickView] = React.useState(false);

    const navigate = useNavigate();
    const clickProductDetail = () => {
        navigate(`/products/${data?.value}`);
    };
    return (
        <>
            <Card onClick={clickProductDetail}>
                <div className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`} data-nc-id="ProductCard">
                    {/* <Link to={"/product-detail"} className="absolute inset-0"></Link> */}

                    <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                        <NcImage
                            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                            src={data?.image ? service.IMAGE_URL + data?.image : ''}
                            className="object-cover w-full h-full drop-shadow-xl"
                        />
                    </div>

                    <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                        <div>
                            <h2 className={`nc-ProductCard__title text-base font-semibold transition-colors`}>{data?.label}</h2>
                        </div>
                    </div>
                </div>
            </Card>

            {/* QUICKVIEW */}
            <ModalQuickView show={showModalQuickView} onCloseModalQuickView={() => setShowModalQuickView(false)} />
        </>
    );
};

export default ProductCard;
