import Label from 'components/Label/Label';
import React, { FC, useEffect, useState } from 'react';
import Select from 'react-select';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

// import Select from "shared/Select/Select";
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { AppDispatch, RootState } from 'reduxStore/store';
import { Form, FormFeedback } from 'reactstrap';
import { getAllStates } from '../../reduxStore/store/order/index';
import { secureGet, secureSet } from 'service/secureLs';
import classnames from 'classnames';

interface Props {
    isActive: boolean;
    onCloseActive: () => void;
    onOpenActive: () => void;
    tabActive: any;
    onFormSubmit: () => void;
}

const ShippingAddress: FC<Props> = ({ isActive, onCloseActive, onOpenActive, tabActive, onFormSubmit }) => {
    const RenderShippingAddress: FC = () => {
        let customerAddresses = useSelector((state: RootState) => state?.customerOrderSlice?.customerAddress);

        customerAddresses = [
            ...customerAddresses,
            {
                value: null,
                address1: null,
                address2: null,
                zipCode: null,
                State: null,
                cityName: null,
                countryName: null,
                stateName: null,
                label: String('Add New Address')
            }
        ];

        const statesData = useSelector((state: RootState) => state?.customerOrderSlice?.states);
        const dispatch = useDispatch<AppDispatch>();
        const [formSubmitted, setFormSubmitted] = useState(false);
        const validationScheme = yup.object().shape({
            address1: yup.string().required('Address Line1 is required.'),
            address2: yup.string().required('Address Line2 is required.'),
            countryName: yup.string().required('Country is required.'),
            stateName: yup.object().required('State is required.').typeError('State must be in object.'),
            cityName: yup.string().required('City is required.'),
            zipCode: yup
                .number()
                .typeError('Zip Code must be in number.')
                .required('Zip Code is required.')
                .test('len', 'Must be exactly 6 Digit Zip Code', (val) => val.toString().length === 6),
            shippingAddressId: yup.object().required('Shipping address is required.')
        });
        const {
            register,
            handleSubmit,
            formState: { errors },
            control,
            reset,
            setValue,
            trigger
        } = useForm<any>({
            mode: 'onChange',
            resolver: yupResolver(validationScheme)
        });
        const handleClickOnShipping = () => {
            setValue(
                'shippingAddressId',
                customerAddresses.find((i) => i.value === null)
            );
        };
        const { ref: refShippingAddress1, ...restShippingAddress1 } = register('address1');
        const { ref: refShippingAddress2, ...restShippingAddress2 } = register('address2');
        const { ref: refShippingCountryName, ...restShippingCountryName } = register('countryName');
        // eslint-disable-next-line no-unused-vars
        const { ref: refShippingStateName, ...restShippingStateName } = register('stateName');
        const { ref: refShippingCityName, ...restShippingCityName } = register('cityName');
        const { ref: refShippingZipCode, ...restShippingZipCode } = register('zipCode');
        interface ShippingAddressError {
            message?: string;
            label?: {
                message?: string;
            };
            value?: {
                message?: string;
            };
        }
        const updateAddressFields = (addressId: any) => {
            if (addressId) {
                let selectedAddress = customerAddresses.find((i: { value?: any }) => i?.value == addressId);

                let currentAddress = {
                    value: selectedAddress?.value,
                    label: selectedAddress?.label,
                    address1: selectedAddress?.address1,
                    address2: selectedAddress?.address2,
                    cityName: selectedAddress?.cityName,
                    stateName: selectedAddress?.stateName,
                    stateId: selectedAddress?.State?.id,
                    countryName: selectedAddress?.countryName,
                    zipCode: selectedAddress?.zipCode
                };
                setValue('address1', selectedAddress?.address1);
                setValue('address2', selectedAddress?.address2);
                setValue('cityName', selectedAddress?.cityName);
                setValue('stateName', {
                    label: selectedAddress?.stateName,
                    value: selectedAddress?.State?.id
                });
                setValue('countryName', selectedAddress?.countryName);
                setValue('zipCode', selectedAddress?.zipCode);
                secureSet('customerShippingAddress', currentAddress);
                trigger();
            } else {
                setValue('address1', null);
                setValue('address2', null);
                setValue('cityName', null);
                setValue('stateName', null);
                setValue('countryName', null);
                setValue('zipCode', null);
                secureSet('customerShippingAddress', {});
            }
        };

        const onSubmit: SubmitHandler<any> = (data) => {
            let currentAddress = {
                // orderNote: orderNoteData,
                value: data?.shippingAddressId?.value,
                label: data?.shippingAddressId?.label,
                address1: data?.address1,
                address2: data?.address2,
                cityName: data?.cityName,
                stateId: data?.stateName?.value,
                stateName: data?.stateName?.label,
                countryName: data?.countryName,
                zipCode: Number(data?.zipCode)
            };
            // console.log(currentAddress, "--");
            secureSet('customerShippingAddress', currentAddress);
            setFormSubmitted(true);
            onFormSubmit();
            tabActive();
        };

        const getAllStatesData = () => {
            dispatch(getAllStates());
        };
        const shippingAddress = secureGet('customerShippingAddress');

        useEffect(() => {
            if (shippingAddress && Object?.keys(shippingAddress)?.length) {
                reset({
                    shippingAddressId: {
                        value: shippingAddress?.value,
                        label: shippingAddress?.label
                    },
                    address1: shippingAddress?.address1,
                    address2: shippingAddress?.address2,
                    cityName: shippingAddress?.cityName,
                    stateName: {
                        label: shippingAddress?.stateName,
                        value: shippingAddress?.stateId
                    },
                    countryName: shippingAddress?.countryName,
                    zipCode: shippingAddress?.zipCode
                });
            } else {
                reset({});
            }
            getAllStatesData();
        }, []);

        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
                <div className="p-6 flex flex-col sm:flex-row items-start">
                    <span className="hidden sm:block">
                        <svg
                            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>

                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                            <span className="uppercase">SHIPPING ADDRESS</span>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </h3>

                        {shippingAddress ? (
                            <>
                                <h6 className="font-bold text-17 text-gray-700">
                                    {shippingAddress?.address1}
                                    {','} {shippingAddress?.address2}
                                    {','}
                                    {shippingAddress?.cityName}
                                    {','}
                                    {shippingAddress?.stateName}
                                    {','}
                                </h6>
                                <h6 className="font-bold text-17 text-gray-700">
                                    {shippingAddress?.countryName}
                                    {'-'} {shippingAddress?.zipCode}
                                </h6>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={() => {
                            onOpenActive();
                            setFormSubmitted(false);
                        }}
                    >
                        Change
                    </ButtonSecondary>
                </div>
                {formSubmitted == false && (
                    <div
                        className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                            isActive ? 'block' : 'hidden'
                        }`}
                    >
                        <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">Addresses</Label>
                                    <Controller
                                        control={control}
                                        name="shippingAddressId"
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                // isClearable
                                                value={value}
                                                classNamePrefix="select"
                                                className={classnames('react-select', {
                                                    'is-invalid':
                                                        (errors?.shippingAddressId as ShippingAddressError)?.message ||
                                                        (errors?.shippingAddressId as ShippingAddressError)?.label?.message ||
                                                        ((errors?.shippingAddressId as ShippingAddressError)?.value &&
                                                            (errors?.shippingAddressId as ShippingAddressError)?.value?.message)
                                                })}
                                                placeholder={'Select Address'}
                                                options={customerAddresses}
                                                onChange={(val: any) => {
                                                    // Update the form state
                                                    onChange(val);
                                                    val && val?.value ? updateAddressFields(val?.value) : updateAddressFields(null);
                                                }}
                                            />
                                        )}
                                    />
                                    <FormFeedback>
                                        {(errors?.shippingAddressId as any)?.message ||
                                            (errors?.shippingAddressId as any)?.label?.message ||
                                            ((errors?.shippingAddressId as any)?.value &&
                                                (errors?.shippingAddressId as any)?.value?.message)}
                                    </FormFeedback>
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                                <div className="flex-1">
                                    <Label className="text-sm">Flat, House no, Building, Company, Apartment*</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address1"
                                        // name="address1"
                                        placeholder="Flat, House no., Building, Company, Apartment"
                                        // invalid={errors?.address1 && true}
                                        {...restShippingAddress1}
                                        ref={refShippingAddress1}
                                        onChange={(e) => {
                                            restShippingAddress1.onChange(e);
                                            handleClickOnShipping();
                                        }}
                                    />
                                    {errors?.address1?.message && <FormFeedback>{String(errors?.address1?.message)}</FormFeedback>}
                                </div>
                                <div className="sm:w-1/3">
                                    <Label className="text-sm">Area, Street, Sector, Village </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="address2"
                                        // name="address2"
                                        // invalid={errors?.address2 && true}
                                        placeholder="Area, Street, Sector, Village"
                                        {...restShippingAddress2}
                                        ref={refShippingAddress2}
                                        onChange={(e) => {
                                            restShippingAddress2.onChange(e);
                                            handleClickOnShipping();
                                        }}
                                    />
                                    {errors?.address2 && <FormFeedback>{String(errors?.address2?.message) || ''}</FormFeedback>}
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">City</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="cityName"
                                        // name="cityName"
                                        // invalid={errors?.cityName && true}
                                        placeholder="City"
                                        {...restShippingCityName}
                                        ref={refShippingCityName}
                                        onChange={(e) => {
                                            restShippingCityName.onChange(e);
                                            handleClickOnShipping();
                                        }}
                                    />
                                    {errors?.cityName && <FormFeedback>{String(errors?.cityName?.message) || ''}</FormFeedback>}
                                </div>
                                <div>
                                    <Label className="text-sm">State</Label>
                                    <Controller
                                        // id="stateName"
                                        control={control}
                                        name="stateName"
                                        render={({ field }) => (
                                            <Select
                                                isClearable
                                                classNamePrefix="select"
                                                placeholder={'Select State'}
                                                options={statesData}
                                                // theme={selectThemeColors}
                                                className={classnames('react-select', {
                                                    'is-invalid':
                                                        ((errors?.stateName as any)?.message ||
                                                            ((errors?.stateName as any)?.label &&
                                                                (errors?.stateName as any)?.label?.message) ||
                                                            ((errors?.stateName as any)?.value &&
                                                                (errors?.stateName as any)?.value?.message)) ??
                                                        false
                                                })}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleClickOnShipping();
                                                }}
                                            />
                                        )}
                                    />
                                    {errors?.stateName && (
                                        <FormFeedback>
                                            {errors && errors?.countryName && (
                                                <FormFeedback>{String(errors?.countryName?.message) || ''}</FormFeedback>
                                            )}
                                        </FormFeedback>
                                    )}
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">Country</Label>
                                    <Input
                                        // className="mt-1.5"
                                        type="text"
                                        className="form-control"
                                        id="countryName"
                                        // invalid={errors?.countryName && true}
                                        // name="countryName"
                                        placeholder="Country Name"
                                        {...restShippingCountryName}
                                        ref={refShippingCountryName}
                                        onChange={(e) => {
                                            restShippingCountryName.onChange(e);
                                            handleClickOnShipping();
                                        }}
                                    />
                                    {errors && errors?.countryName && (
                                        <FormFeedback>{String(errors?.countryName?.message) || ''}</FormFeedback>
                                    )}
                                </div>
                                <div>
                                    <Label className="text-sm">Zipcode</Label>
                                    <Input
                                        // className="mt-1.5"
                                        type="tel"
                                        className="form-control"
                                        id="zipCode"
                                        // name="zipCode"
                                        // invalid={errors?.zipCode && true}
                                        placeholder="zip Code"
                                        maxLength={6}
                                        pattern="[0-9]{6,}"
                                        {...restShippingZipCode}
                                        ref={refShippingZipCode}
                                        onChange={(e) => {
                                            restShippingZipCode.onChange(e);
                                            handleClickOnShipping();
                                        }}
                                    />
                                    {errors && errors?.zipCode && <FormFeedback>{String(errors?.zipCode?.message) || ''}</FormFeedback>}
                                </div>
                            </div>

                            {/* ============ */}
                            {/* <div>
              <Label className="text-sm">Address type</Label>
              <div className="mt-1.5 grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-3">
                <Radio
                  label={`<span class="text-sm font-medium">Home <span class="font-light">(All Day Delivery)</span></span>`}
                  id="Address-type-home"
                  name="Address-type"
                  defaultChecked
                />
                <Radio
                  label={`<span class="text-sm font-medium">Office <span class="font-light">(Delivery <span class="font-medium">9 AM - 5 PM</span>)</span> </span>`}
                  id="Address-type-office"
                  name="Address-type"
                />
              </div>
            </div> */}

                            {/* ============ */}
                            <div className="flex flex-col sm:flex-row pt-6">
                                <ButtonPrimary
                                    className="sm:!px-7 shadow-none"
                                    type="submit"
                                    // onClick={() => onCloseActive()}
                                >
                                    Contitnue
                                </ButtonPrimary>
                                {/* <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={onCloseActive}
              >
                Cancel
              </ButtonSecondary> */}
                            </div>
                        </Form>
                    </div>
                )}
            </div>
        );
    };
    return <RenderShippingAddress />;
};

export default ShippingAddress;
