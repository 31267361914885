import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import Explore1 from 'images/instaacard/home/start-exploring/explore-1.png';
import Explore2 from 'images/instaacard/home/start-exploring/explore-2.png';
import Explore3 from 'images/instaacard/home/start-exploring/explore-3.png';
import Explore4 from 'images/instaacard/home/start-exploring/explore-4.png';
import Explore5 from 'images/instaacard/home/start-exploring/explore-5.png';

export interface SectionHowItWorkProps {
    className?: string;
    data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
    {
        id: 1,
        img: Explore1,
        imgDark: Explore1,
        title: 'Your Digital Profile',
        desc: 'Add everything you want to share with your connections by simply adding it to your profile.'
    },
    {
        id: 2,
        img: Explore2,
        imgDark: Explore2,
        title: 'Multiple Profile',
        desc: 'Support multiple profiles for personal and professional use, reducing the need for separate physical cards.'
    },
    {
        id: 3,
        img: Explore3,
        imgDark: Explore3,
        title: 'Digital Connections',
        desc: 'Have all your connection in one place one smart link.'
    },
    {
        id: 4,
        img: Explore4,
        imgDark: Explore4,
        title: 'Personalized intelligent hyperlink',
        desc: 'Virtually share the link to any one and make connection instantly'
    },
    {
        id: 5,
        img: Explore5,
        imgDark: Explore5,
        title: 'Informative profile',
        desc: 'A visually appealing and informative profile upon being accessed.'
    }
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = '', data = DEMO_DATA }) => {
    return (
        <div className={`nc-SectionHowItWork ${className}`} data-nc-id="SectionHowItWork">
            <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-16">
                Five distinctive attributes of INSTAACARD
            </h2>
            <div className="relative grid sm:grid-cols-1 lg:grid-cols-5 gap-10 sm:gap-16 xl:gap-20">
                {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
                    <div key={item.id} className="relative flex flex-col items-center max-w-xs mx-auto">
                        {item.id % 2 === 0 ? (
                            <>
                                <NcImage containerClassName="mb-4 sm:mb-10 max-w-[140px] mx-auto" className="rounded-3xl" src={item.img} />
                                <div className="text-center mt-auto space-y-2">
                                    <h3 className="text-icBlue-100 font-bold">{item.title}</h3>
                                    <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">{item.desc}</span>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="text-center mt-auto space-y-2">
                                    <span className="text-icBlue-100 font-bold">{item.title}</span>
                                    <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">{item.desc}</span>
                                </div>
                                <NcImage containerClassName="mb-4 sm:mb-10 max-w-[140px] mx-auto" className="rounded-3xl" src={item.img} />
                            </>
                        )}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionHowItWork;
