import React from 'react';
import NavigationItem from './NavigationItem';
import { NAVIGATION_DEMO_2 } from 'data/navigation';

function Navigation() {
    const handleItemClick = (id: string) => {
        // console.log("Clicked on item with id:", id);
    };
    return (
        <ul className="nc-Navigation flex items-center">
            {NAVIGATION_DEMO_2.map((item) => (
                <NavigationItem key={item.id} menuItem={item} itemClick={handleItemClick} />
            ))}
        </ul>
    );
}

export default Navigation;
