import Swal from 'sweetalert2';

export const confirmDelete = async (title: string, text?: string) => {
    return Swal.fire({
        title: title || 'Delete',
        text: text || 'Once deleted, data cannot be recovered.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Confirm',
        cancelButtonText: 'Cancel',
        customClass: {
            confirmButton:
                'nc-Button text-sm sm:text-base font-medium py-3 px-4 sm:py-3.5 sm:px-6 relative h-auto inline-flex items-center justify-center rounded-full transition-colors ttnc-ButtonPrimary disabled:bg-opacity-90 bg-slate-900 dark:bg-slate-100 hover:bg-slate-800 text-slate-50 dark:text-slate-800 shadow-xl',
            cancelButton: 'btn btn-outline-primary ms-1'
        },
        buttonsStyling: false
    });
};

export const showAlert = async (icon?: string, title?: string, text?: string, customClass?: string) => {
    return Swal.fire({
        // icon: icon || 'info',
        title: title || 'Notification',
        text: text || '',
        customClass: {
            confirmButton: customClass || 'btn btn-outline-primary'
        },
        buttonsStyling: false
    });
};
