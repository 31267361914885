import ButtonPrimary from 'shared/Button/ButtonPrimary';
import React from 'react';
import { Helmet } from 'react-helmet-async';
import NcImage from 'shared/NcImage/NcImage';
import NotFound from 'images/404.png';

const Page404: React.FC = () => {
    return (
        <div className="nc-Page404">
            <Helmet>
                <title>Order Failure || Instaacard</title>
            </Helmet>
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                {/* HEADER */}
                <header className="text-center max-w-2xl mx-auto space-y-2 items-center">
                    <NcImage src={NotFound} containerClassName="h-96 flex justify-center" className="h-96" />
                    {/* <Check /> */}
                    <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-2">
                        FAILED TO PLACE YOUR ORDER 😔
                    </h2>
                    <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                        We're are so sorry for this. Maybe we can try again later.
                    </span>
                    <div className="pt-8">
                        <ButtonPrimary href="/">Continue Shopping</ButtonPrimary>
                    </div>
                </header>
            </div>
        </div>
    );
};

export default Page404;
