/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';

const CancellationPage = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl mb-5">
                <div className="max-w-screen-md mx-auto space-y-5">
                    {/* <Badge href="##" color="purple" name="Traveler" /> */}
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        CANCELLATION POLICY
                    </h1>
                    <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">Content Coming Soon</span>
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert mb-5">
                <ul>
                    <li>You can call us on 079-40024244 or mail us at info@instaaprint.com within 10 days from the date of delivery.</li>
                    <li>Please mention your order number in subject line to help us quickly process your returns /exchange.</li>
                    <li>Please ensure that returned merchandise is unused and undamaged and in a saleable condition.</li>
                    <li>Please maintain original packing.</li>
                    <li>Please ensure that returned item is packed securely and safely to prevent any loss or damage during transit.</li>
                    <li>The items for reverse pick up will be picked up within 5 working days from the date of request.</li>
                    <li>After receipt of item, your request for exchange /returns would be processed.</li>
                    <li>
                        In the case of returns bank normally takes 8-10 business days to process a refund one it has been initiated from our
                        end.
                    </li>
                    <li>In the case of exchange, your new order will be dispatched to you within 3-5 working business days.</li>
                </ul>
                <p className="mt-1">
                    <strong>Note: </strong>Some of pin codes are not feasible for reverse pick up, in such cases the customer will need to
                    send it back to us. We do not offer reverse facility outside India.
                </p>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Terms and Condition | Instaacard</title>
            </Helmet>
            {renderHeader()}

            {/* <div className="nc-SingleContent container space-y-10">{renderContent()}</div> */}
        </div>
    );
};

export default CancellationPage;
