import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'service/axios';
import { isUserLoggedIn } from 'utils/User';
import { toast } from 'react-toastify';
import { secureGet, secureSet } from 'service/secureLs';
import { RootState } from 'reduxStore/store';

// get all cart data
export const getCart = createAsyncThunk('cartSlice/getCart', async () => {
    try {
        let response = await axios.get('/cart', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        return Promise.reject(err);
    }
});
// multipart/form-data
export const addToCart = createAsyncThunk('cartSlice/addToCart', async (data: {}, { dispatch, rejectWithValue }) => {
    if (isUserLoggedIn()) {
        try {
            // showLoader(true);
            let response = await axios.post('/cart', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Add to Cart successful.');
            // showLoader(false);
            dispatch(getCart());
            return null;
        } catch (err: any) {
            // showLoader(false);
            // toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    } else {
        toast.success('Add to Cart successful.');
        return Promise.resolve(data);
    }
});

//bulk create
export const bulkCreate = createAsyncThunk('cartSlice/bulkCreate', async (data: {}) => {
    try {
        let response = await axios.post('/bulk-cart', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data);
    } catch (err) {
        console.error('Error', err);
        return Promise.reject(err);
    }
});

//upload image
export const uploadImage = createAsyncThunk('cartSlice/uploadImage', async (data: {}) => {
    try {
        let response = await axios.post('/upload-image', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        return Promise.resolve(response);
    } catch (err) {
        console.error('Error', err);
        return Promise.reject(err);
    }
});

export const updateToCartQuantity = createAsyncThunk('cartSlice/updateToCartQuantity', async (data: any, { getState, dispatch }) => {
    const state = getState() as RootState;
    const cart = state.CartSlice.cart;
    if (isUserLoggedIn()) {
        try {
            // showLoader(true);

            let response = await axios.patch('/cart/' + data.id, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            toast.success(response?.data?.message || 'Quantity updated successful.');
            // showLoader(false);
            dispatch(getCart());
            return Promise.resolve(response.data);
        } catch (err) {
            // showLoader(false);
            console.error('Error', err);
            // toast.error(err?.response?.data?.message || "Something went wrong!");
            return Promise.reject(err);
        }
    } else {
        const cartIndex = cart.findIndex((item: any) => item === data.product);
        const updatedCart = [...cart]; // Create a new array to avoid mutating state directly
        updatedCart[cartIndex] = {
            ...updatedCart[cartIndex],
            quantity: data.quantity // Update the quantity property
        };
        // console.log(updatedCart);

        toast.success('Quantity updated successful.');
        return Promise.resolve(updatedCart);
    }
});

export const deleteToCart = createAsyncThunk('cartSlice/deleteToCart', async (data: {}, { dispatch }) => {
    try {
        let response = await axios.delete('/cart/' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        toast.success(response?.data?.message || 'Delete from Cart successfully.');
        dispatch(getCart());
        return Promise.resolve(response.data);
    } catch (err) {
        console.error('Error', err);
        return Promise.reject(err);
    }
});

export const deleteFromLocal = createAsyncThunk('cartSlice/deleteFromLocal', async (data: any, { dispatch, getState }) => {
    const state = getState() as RootState; // Type cast to RootState

    const updatedCart = state.CartSlice.cart.filter((item: any) => item !== data);

    // secureSet("cartList", updatedCart);
    return Promise.resolve(updatedCart);
});
const initialCart = () => {
    try {
        var item = secureGet('cartList');
        if (Array.isArray(item)) return item;
        return [];
    } catch (err) {
        console.error('Error in initialCart', err);
        return [];
    }
};

// get quantity
// export const getQuantity = createAsyncThunk(
//   "cartSlice/getQuantity",
//   async (id) => {
//     try {
//       showLoader(true);

//       let response = await axios.get("/stock-check/" + id, {
//         headers: {
//           "Content-Type": "application/json",
//         },
//       });

//       showLoader(false);
//       return Promise.resolve(response.data);
//     } catch (err) {
//       showLoader(false);
//       if (err?.response?.status != 401) {
//         toast.error(err.response?.data?.message || "Something went wrong!");
//       }

//       return Promise.reject(err);
//     }
//   }
// );
export const cartSlice = createSlice({
    name: 'cartSlice',
    initialState: {
        cart: initialCart(),
        stockQuantity: []
    },
    reducers: {
        clearCartList: (state) => {
            state.cart = [];
            secureSet('cartList', state.cart);
        },
        removeCartList: (state) => {
            state.cart = [];
            localStorage.removeItem('cartList');
        }
    },

    extraReducers: (builder) => {
        builder.addCase(addToCart.fulfilled, (state, action) => {
            if (action.payload) {
                state.cart.push(action.payload);
                secureSet('cartList', state.cart);
            }
            return;
        });
        builder.addCase(getCart.fulfilled, (state, action) => {
            state.cart = action.payload;
            return;
        });
        builder.addCase(deleteFromLocal.fulfilled, (state, action) => {
            state.cart = action.payload;
            secureSet('cartList', state.cart);
            return;
        });
        builder.addCase(updateToCartQuantity.fulfilled, (state, action) => {
            if (!isUserLoggedIn()) {
                state.cart = action.payload;
                secureSet('cartList', state.cart);
            }
            return;
        });
    }
});
export const { clearCartList, removeCartList } = cartSlice.actions;
export default cartSlice.reducer;
