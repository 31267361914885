import rightImg from 'images/hero-right1.png';
import React, { FC } from 'react';
// import SectionFounder from './SectionFounder';
// import SectionStatistic from './SectionStatistic';
import { Helmet } from 'react-helmet-async';
import BgGlassmorphism from 'components/BgGlassmorphism/BgGlassmorphism';
// import BackgroundSection from 'components/BackgroundSection/BackgroundSection';
// import SectionHero from './SectionHero';
// import SectionClientSay from 'components/SectionClientSay/SectionClientSay';
// import SectionPromo3 from 'components/SectionPromo3';
import { Disclosure } from '@headlessui/react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/outline';
// import SectionPromo1 from 'containers/PageHome/components/SectionPromo/SectionPromo1';

export interface PageAboutProps {
    className?: string;
}
const FEATURE_DATA = [
    {
        name: 'Contactless Information Exchange',
        content:
            "NFC business cards allow individuals to exchange contact information, portfolios, or other customizable data by simply tapping their NFC-enabled business cards with another person's NFC-compatible device, such as a smartphone."
    },
    {
        name: 'Digital Content Sharing',
        content:
            'In addition to basic contact details, NFC business cards can be programmed to share digital content like links to personal websites, LinkedIn profiles, portfolios, or other online resources. This provides a dynamic and interactive way to share information beyond traditional text.'
    },
    {
        name: 'Ease of Use',
        content:
            'NFC-based business cards are user-friendly and require no manual data entry. The recipient only needs to tap the card with their smartphone to receive the information, making the networking process quick and efficient.'
    },
    {
        name: 'Versatility in Material',
        content:
            'NFC technology can be embedded in various materials, including PVC, carbon fiber, metal, or other materials, offering users the option to choose a card that aligns with their personal style and preferences.'
    },
    {
        name: 'Customization',
        content:
            'Users can customize the information stored on their NFC business cards, tailoring the content to specific networking needs. This flexibility allows for a more personalized and targeted approach in sharing information.'
    },
    {
        name: 'Real-time Updates',
        content:
            'Some NFC business card solutions offer the ability to update information in real-time. This ensures that the shared details, such as phone numbers or email addresses, remain current even if there are changes over time.'
    },
    {
        name: 'Environmentally Friendly',
        content:
            'NFC business cards contribute to reducing the environmental impact associated with traditional paper business cards. The move towards digital alternatives aligns with sustainable and eco-friendly practices.'
    },
    {
        name: 'Compatibility with Mobile Devices',
        content:
            'NFC technology is widely supported by modern smartphones, both Android and newer iPhone models, ensuring broad compatibility and accessibility for users across different devices.'
    },
    {
        name: 'Security Measures',
        content:
            'NFC business cards incorporate security features, including encryption and authentication protocols, to ensure that the information exchanged between devices remains secure and private.'
    },
    {
        name: 'Innovative Networking Approach',
        content:
            'NFC-based business cards represent an innovative and tech-savvy approach to networking, showcasing a commitment to staying current with technological advancements in the business world.'
    }
];

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
    return (
        <div className={`nc-PageAbout overflow-hidden relative ${className}`} data-nc-id="PageAbout">
            <Helmet>
                <title>About || Instaacard</title>
            </Helmet>

            {/* ======== BG GLASS ======== */}
            <BgGlassmorphism />

            <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
                {/* <SectionHero
                    rightImg={rightImg}
                    heading=""
                    btnText=""
                    subHeading="Welcome to INSTAACARD, where innovation meets sophistication in the realm of modern networking. We specialize in crafting cutting-edge digital NFC business cards with a touch of elegance, offering a seamless blend of technology and premium materials."
                /> */}

                <div className={`nc-SectionHero relative ${className}`} data-nc-id="SectionHero">
                    <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
                        <div className="w-screen max-w-full xl:max-w-lg space-y-5 lg:space-y-7">
                            <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                                👋 About Us.
                            </h2>
                            <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                                Welcome to INSTAACARD, where innovation meets sophistication in modern networking. Specializing in crafting
                                cutting-edge digital NFC business cards, we blend technology with elegance. We recognize the importance of
                                first impressions, revolutionizing connections in the digital age. Farewell to paper cards; our PVC, carbon
                                fiber, and metal cards, equipped with NFC technology, redefine networking. With a simple tap, transfer your
                                contact details and portfolio seamlessly to your contacts' smartphones.
                            </span>
                            {/* {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>} */}
                        </div>
                        <div className="flex-grow">
                            <img className="w-3/5" src={rightImg} alt="" />
                        </div>
                    </div>
                    <div className="mt-10">
                        <h2 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-3xl xl:text-3xl dark:text-neutral-100">
                            What is NFC business cards?
                        </h2>
                        <span className="mt-2 block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                            NFC-based business cards are a modern and innovative approach to traditional paper business cards. These cards
                            incorporate Near Field Communication (NFC) technology to enable the seamless exchange of contact information and
                            other relevant details between individuals. Here are some key features and advantages of NFC-based business
                            cards.
                        </span>
                    </div>
                </div>

                {/* <SectionFounder /> */}
                {/* <BackgroundSection /> */}
                {/* <SectionClientSay /> */}

                {/* <SectionPromo1 /> */}

                <div>
                    <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-8">
                        NFC Feature!
                    </h2>
                    <div className="w-full rounded-2xl  grid sm:grid-cols-1 lg:grid-cols-2 gap-2">
                        {FEATURE_DATA.map((item, index) => {
                            return (
                                <div className="mb-2">
                                    <Disclosure key={index}>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 font-medium text-left bg-slate-100/80 hover:bg-slate-200/60 dark:bg-slate-800 dark:hover:bg-slate-700 rounded-lg focus:outline-none focus-visible:ring focus-visible:ring-slate-500 focus-visible:ring-opacity-75 ">
                                                    <span>{item.name}</span>
                                                    {!open ? (
                                                        <PlusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                                    ) : (
                                                        <MinusIcon className="w-4 h-4 text-slate-600 dark:text-slate-400" />
                                                    )}
                                                </Disclosure.Button>
                                                <Disclosure.Panel
                                                    as="div"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item.content
                                                    }}
                                                ></Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                            );
                        })}
                    </div>
                </div>

                {/* <SectionStatistic /> */}

                {/* <SectionPromo3 /> */}
            </div>
        </div>
    );
};

export default PageAbout;
