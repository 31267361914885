import SectionHowItWork from './components/SectionHowItWork';
import BackgroundSection from './components/BackgroundSection';
import SectionHero2 from './components/SectionHero2';
import SectionSliderProductCard from 'components/SectionSliderProductCard';
// import DiscoverMoreSlider from './components/DiscoverMoreSlider/index';
import StartExploring from './components/StartExploring';
import SectionPromo1 from './components/SectionPromo/SectionPromo1';
import SectionPromo2 from './components/SectionPromo/SectionPromo2';
import SectionPromo3 from './components/SectionPromo/SectionPromo3';
import SectionEcoSystem from './components/SectionEcoSystem';
import FeatureCards from './components/FeatureCards';

function PageHome() {
    return (
        <div className="nc-PageHome relative overflow-hidden">
            {/* SECTION HERO */}
            <SectionHero2 />

            {/* <div className="mt-24 lg:mt-32">
                <DiscoverMoreSlider />
            </div> */}

            <div className="mt-8 lg:mt-12">
                <FeatureCards />
            </div>

            <div className="mt-24 lg:mt-32">
                <SectionEcoSystem />
            </div>

            <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">
                {/* SECTION */}
                {/* <SectionSliderProductCard data={products} /> */}

                {/* SECTION */}
                <SectionPromo1 />

                <div className="py-12 lg:py-22 border-t border-b border-slate-200 dark:border-slate-700">
                    <SectionHowItWork />
                </div>

                {/* SECTION */}
                <div className="relative py-24 lg:py-32">
                    <BackgroundSection />
                    <StartExploring />
                </div>

                {/* SECTION */}
                {/* <SectionGridFeatureItems /> */}

                {/*  */}
                <SectionPromo2 />

                {/* SECTION 3 */}
                {/* <SectionSliderLargeProduct cardStyle="style2" /> */}

                {/*  */}
                {/* <SectionSliderCategories /> */}

                {/* SECTION */}
                <SectionPromo3 />

                <SectionSliderProductCard heading="Best Sellers" subHeading="NFC Cards" />

                {/* <SectionClientSay /> */}
            </div>
        </div>
    );
}

export default PageHome;
