import React from 'react';

const Loader: React.FC = () => {
    return (
        <div className="w-2/4 mx-auto">
            <div className="mx-auto justify-center h-96 max-w-sm bg-gray-300 rounded-lg animate-pulse dark:bg-gray-700"></div>
            <div className="mt-2">
                <div className="mx-auto bg-gray-200 rounded-full dark:bg-gray-700 h-7 w-full animate-pulse"></div>
                <div className="mx-auto bg-gray-200 rounded-full dark:bg-gray-700 h-7 w-full animate-pulse mt-2 "></div>
            </div>
        </div>
    );
};

export default Loader;
