import CardNewCategory from './components/CardNewCategory';
import Heading from 'components/Heading/Heading';
import NavItem2 from 'components/NavItem2';
import React, { FC, useEffect, useState } from 'react';
import Nav from 'shared/Nav/Nav';
import explore1Svg from 'images/collections/explore1.svg';
import explore2Svg from 'images/collections/explore2.svg';
import explore3Svg from 'images/collections/explore3.svg';
import explore4Svg from 'images/collections/explore4.svg';
import explore5Svg from 'images/collections/explore5.svg';
import explore6Svg from 'images/collections/explore6.svg';
import explore7Svg from 'images/collections/explore7.svg';
import explore8Svg from 'images/collections/explore8.svg';
import explore9Svg from 'images/collections/explore9.svg';
import explore1Png from 'images/collections/explore1.png';
import explore2Png from 'images/collections/explore2.png';
import explore3Png from 'images/collections/explore3.png';
import explore4Png from 'images/collections/explore4.png';
import explore5Png from 'images/collections/explore5.png';
import explore6Png from 'images/collections/explore6.png';
import explore7Png from 'images/collections/explore7.png';
import explore8Png from 'images/collections/explore8.png';
import explore9Png from 'images/collections/explore9.png';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reduxStore/store';
import { getProductCategory } from 'reduxStore/store/products/index';
import { ProductItem } from 'reduxStore/store/products/class';
import PulseAnimation from './components/ProductCategories';
import axios from '../../../../service/axios';

interface ExploreType {
    // id: string;
    name: string;
    desc: string;
    image: string;
    svgBg: string;
    color?: string;
}

export interface SectionGridMoreExploreProps {
    className?: string;
    gridClassName?: string;
    boxCard?: 'box1' | 'box4' | 'box6';
    data?: ExploreType[];
}

export const DEMO_MORE_EXPLORE_DATA = [
    {
        id: 1,
        name: 'Backpack',
        desc: 'Manufacturer',
        image: explore1Png,
        svgBg: explore1Svg,
        color: 'bg-indigo-50'
    },
    {
        id: 2,
        name: 'Shoes',
        desc: 'Manufacturer',
        image: explore2Png,
        svgBg: explore2Svg,
        color: 'bg-slate-100/80'
    },
    {
        id: 3,
        name: 'Recycled Blanket',
        desc: 'Manufacturer',
        image: explore3Png,
        svgBg: explore3Svg,
        color: 'bg-violet-50'
    },
    {
        id: 4,
        name: 'Cycling Shorts',
        desc: 'Manufacturer',
        image: explore9Png,
        svgBg: explore9Svg,
        color: 'bg-orange-50'
    },
    {
        id: 5,
        name: 'Cycling Jersey',
        desc: 'Manufacturer',
        image: explore5Png,
        svgBg: explore5Svg,
        color: 'bg-blue-50'
    },
    {
        id: 6,
        name: 'Car Coat',
        desc: 'Manufacturer',
        image: explore6Png,
        svgBg: explore6Svg,
        color: 'bg-orange-50'
    },
    {
        id: 7,
        name: 'Sunglasses',
        desc: 'Manufacturer',
        image: explore7Png,
        svgBg: explore7Svg,
        color: 'bg-stone-100'
    },
    {
        id: 8,
        name: 'kid hats',
        desc: 'Manufacturer',
        image: explore8Png,
        svgBg: explore8Svg,
        color: 'bg-blue-50'
    },
    {
        id: 9,
        name: 'Wool Jacket',
        desc: 'Manufacturer',
        image: explore4Png,
        svgBg: explore4Svg,
        color: 'bg-slate-100/80'
    }
];

const SectionGridMoreExplore: FC<SectionGridMoreExploreProps> = ({
    className = '',
    boxCard = 'box4',
    gridClassName = 'grid-cols-1 md:grid-cols-2 xl:grid-cols-3'
    // data = DEMO_MORE_EXPLORE_DATA.filter((_, i) => i < 3),
}) => {
    const productCategory: ProductItem[] = useSelector((state: RootState) => state?.productSlice?.productCategory);
    const [subCategoryData, setSubCategoryData] = useState([]);
    // const [tabActive, setTabActive] = React.useState("Man");
    const dispatch = useDispatch<AppDispatch>();

    const getProductCategoryOption = async () => {
        // setLoading(true);
        await dispatch(getProductCategory());
        // setLoading(false);
    };

    //? To activate loader set true here.
    const [loading, setLoading] = useState(false);
    const [tabActive, setTabActive] = React.useState('');

    useEffect(() => {
        getProductCategoryOption();
    }, []);

    const renderCard1 = (item: any) => {
        // {
        //     "value": "41c55c22-1859-4a86-9f4f-b91d69c53a43",
        //     "label": "White PVC NFC Cards",
        //     "image": "uploads/product-subcategory/1710313437422-WHITE PVC.jpg",
        //     "productCategoryId": "be51b0d7-5ea5-45fa-90ff-e6e5205a03bd"
        // }
        switch (boxCard) {
            case 'box4':
                return <CardNewCategory key={item.value} name={item.label} id={item.value} image={item.image} />;
        }
    };
    const handleTabRender = async (value: string) => {
        try {
            // setTabActive(value);
            setLoading(true);
            let response = await axios.get('/product-sub-category/category/' + value, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            setSubCategoryData(response.data);
            // await dispatch(getProduct(query))
            //     .unwrap()
            //     .catch((err) => {});
            setLoading(false);
        } catch (err: any) {
            console.log(err.message);
        }
    };
    useEffect(() => {
        if (productCategory.length > 0) {
            setTabActive(productCategory[0]?.label);
            // setLoading(true);
            handleTabRender(productCategory[0]?.value);
            // setLoading(false);
        }
    }, [productCategory]);
    const renderHeading = () => {
        return (
            <div>
                <Heading
                    className="mb-12 lg:mb-14 text-neutral-900 dark:text-neutral-50"
                    fontClass="text-3xl md:text-4xl 2xl:text-5xl font-semibold"
                    isCenter
                    desc=""
                    id="explore"
                >
                    Start exploring.
                </Heading>
                <Nav
                    className="p-1 bg-white dark:bg-neutral-800 rounded-full shadow-lg overflow-x-auto hiddenScrollbar"
                    containerClassName="mb-12 lg:mb-14 relative flex justify-center w-full text-sm md:text-base"
                >
                    {productCategory.length > 0 &&
                        productCategory?.map((item, index) => (
                            <NavItem2
                                key={index}
                                isActive={tabActive === item?.label}
                                onClick={() => {
                                    setTabActive(item?.label);
                                    handleTabRender(item.value);
                                }}
                            >
                                <div className="flex items-center justify-center space-x-1.5 sm:space-x-2.5 text-xs sm:text-sm ">
                                    <span className="inline-block"></span>
                                    <span>{item.label}</span>
                                </div>
                            </NavItem2>
                        ))}
                </Nav>
            </div>
        );
    };

    return (
        <div className={`nc-SectionGridMoreExplore relative ${className}`} data-nc-id="SectionGridMoreExplore">
            {renderHeading()}
            {loading && (
                <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>
                    {Array.from({ length: 6 }).map((_, index) => (
                        <div key={index}>
                            <PulseAnimation />
                        </div>
                    ))}
                </div>
            )}
            {!loading && productCategory && productCategory.length > 0 && (
                <div className={`grid gap-4 md:gap-7 ${gridClassName}`}>{subCategoryData.map((item) => renderCard1(item))}</div>
            )}
        </div>
    );
};

export default SectionGridMoreExplore;
