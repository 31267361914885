import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import { Address, Order } from './class';

export const getCustomerAddress = createAsyncThunk('getCustomerOrderSlice/getCustomerAddress', async () => {
    try {
        //   const query = queryString.stringify(data);

        //   showLoader(true);
        let response = await axios.get('/customer-address?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        //   showLoader(false);
        return Promise.resolve(response?.data);
    } catch (err) {
        //   showLoader(false);
        // toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAllStates = createAsyncThunk('getCustomerOrderSlice/getAllStates', async () => {
    // showLoader(true);
    try {
        let response = await axios.get('/state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        //   showLoader(false);

        return Promise.resolve(response.data);
    } catch (err) {
        //   showLoader(false);
        console.error('Error', err);
        //   toast.error(err?.response?.data?.message || "Something went wrong!");
        return Promise.reject(err);
    }
});
// create new customer
export const createCustomerOrder = createAsyncThunk(
    'getCustomerOrderSlice/createCustomerOrder',
    async (data: {}, { dispatch, rejectWithValue }) => {
        try {
            //   showLoader(true);

            let response = await axios.post('/customer-order', data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            // toast.success(response?.data?.message || "Order created successfully.");
            // dispatch(getCustomerAddress());
            //   showLoader(false);
            return Promise.resolve(response.data);
        } catch (err: any) {
            //   showLoader(false);

            toast.error(err?.response?.data?.message || 'Something went wrong!');
            return rejectWithValue(err);
        }
    }
);

export const getCustomerOrder = createAsyncThunk('getCustomerOrderSlice/getCustomerOrder', async () => {
    try {
        //   showLoader(true);

        let response = await axios.get('/customer-order', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        //   showLoader(false);
        return Promise.resolve(response.data);
    } catch (err) {
        //   showLoader(false);
        //   toast.error(err.response?.data?.message || "Something went wrong!");
        return Promise.reject(err);
    }
});

export const applyCoupon = createAsyncThunk('getCustomerOrderSlice/applyCoupon', async (data: any = {}, { rejectWithValue }) => {
    try {
        // showLoader(true);

        let response = await axios.post('/validate-coupon', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Discount applied successfully.');
        // showLoader(false);
        return Promise.resolve(response.data);
    } catch (err: any) {
        // showLoader(false);
        return rejectWithValue(err);
    }
});
export const getDownloadInvoice = createAsyncThunk('getCustomerOrderSlice/getDownloadInvoice', async (id: string) => {
    try {
        //   showLoader(true);
        let response = await axios.get('/order-invoice/' + id, {
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: 'application/pdf' });

        // Create a URL for the Blob
        const url = window.URL.createObjectURL(blob);

        // Create a temporary anchor element to trigger the download
        const a = document.createElement('a');
        a.href = url;
        a.download = 'invoice.pdf'; // Set the desired filename

        // Trigger a click event on the anchor element to start the download
        a.click();

        // Cleanup: Revoke the Blob URL and remove the temporary anchor element
        window.URL.revokeObjectURL(url);
        a.remove();
        //   showLoader(false);
        // Return a success message or any data you need
        return Promise.resolve({ message: 'Invoice downloaded successfully' });
    } catch (err) {
        //   showLoader(false);
        //   toast.error(err?.response?.data?.message || "Something went wrong!");
        return Promise.reject(err);
    }
});

export const customerOrderSlice = createSlice({
    name: 'getCustomerOrderSlice',
    initialState: {
        customerOrder: [] as Order[],
        customerAddress: [] as Address[],
        states: []
    },

    reducers: {
        removeCustomerAddress: (state) => {
            state.customerAddress = [];
            localStorage.removeItem('customerAddress');
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getCustomerOrder.fulfilled, (state, action) => {
            state.customerOrder = action.payload;
        });
        builder.addCase(getCustomerAddress.fulfilled, (state, action) => {
            state.customerAddress = action.payload;
            return;
        });
        builder.addCase(getAllStates.fulfilled, (state, action) => {
            state.states = action.payload;
        });
    }
});

export default customerOrderSlice.reducer;
export const { removeCustomerAddress } = customerOrderSlice.actions;
