import React, { FC } from 'react';
import classnames from 'classnames';
export interface PricesProps {
    className?: string;
    price?: number;
    contentClass?: string;
    isSale?: boolean;
    salePrice?: number;
}

const Prices: FC<PricesProps> = ({
    className = '',
    price = 0,
    isSale = false,
    salePrice = 0,
    contentClass = 'py-1 px-2 md:py-1.5 md:px-2.5 text-sm font-medium'
}) => {
    return (
        <div className={`${className}`}>
            <div className={`flex items-center border-2 border-green-500 rounded-lg ${contentClass}`}>
                {isSale ? <span className="text-green-500 !leading-none mx-1">₹{salePrice.toFixed(2)}</span> : null}
                <span
                    className={classnames('text-green-500 !leading-none', {
                        'line-through text-gray-600': isSale
                    })}
                >
                    ₹{price.toFixed(2)}
                </span>
            </div>
        </div>
    );
};

export default Prices;
