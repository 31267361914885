interface ServiceConfig {
    API_URL: string;
    IMAGE_URL: string;
}

var service: ServiceConfig = {
    API_URL: 'http://localhost:5005',
    IMAGE_URL: 'https://dev-api.instaacard.com/'
};

// Uncomment and modify the conditional logic based on your environment if needed.
if (process.env.NODE_ENV === 'production') {
    service.API_URL = 'https://dev-api.instaacard.com';
} else {
    service.API_URL = 'https://dev-api.instaacard.com';
}

export default service;
