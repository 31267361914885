import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import HIW1img from 'images/instaacard/home/how-it-works/step-1.png';
import HIW2img from 'images/instaacard/home/how-it-works/step-2.png';
import HIW3img from 'images/instaacard/home/how-it-works/step-3.png';
// import HIW4img from 'images/HIW4img.png';
import VectorImg from 'images/VectorHIW.svg';
import Badge from 'shared/Badge/Badge';

export interface SectionHowItWorkProps {
    className?: string;
    data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
    {
        id: 1,
        img: HIW1img,
        imgDark: HIW1img,
        title: 'Login',
        desc: 'Access your profile by logging in through your smart link.'
    },
    {
        id: 2,
        img: HIW2img,
        imgDark: HIW2img,
        title: 'Setup',
        desc: 'Complete your profile with all necessary information for seamless sharing.'
    },
    {
        id: 3,
        img: HIW3img,
        imgDark: HIW3img,
        title: 'Ready for sharing',
        desc: 'Once saved, your profile is primed for sharing via NFC or QR code scanning.'
    }
    // {
    //     id: 4,
    //     img: HIW4img,
    //     imgDark: HIW4img,
    //     title: 'Enjoy the product',
    //     desc: 'Have fun and enjoy your 5-star quality products'
    // }
];

const SectionHowItWork: FC<SectionHowItWorkProps> = ({ className = '', data = DEMO_DATA }) => {
    return (
        <div className={`nc-SectionHowItWork ${className}`} data-nc-id="SectionHowItWork">
            <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-16">
                Easily create your INSTAACARD profile in three simple steps.
            </h2>
            <div className="relative grid sm:grid-cols-2 lg:grid-cols-3 gap-10 sm:gap-16 xl:gap-20">
                <img className="hidden md:block absolute py-32" src={VectorImg} alt="vector" />
                {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
                    <div key={item.id} className="relative flex flex-col items-center max-w-xs mx-auto">
                        <NcImage containerClassName="mb-4 sm:mb-10 max-w-[175px] mx-auto" className="rounded-3xl" src={item.img} />
                        <div className="text-center mt-auto space-y-5">
                            <Badge
                                name={`Step ${index + 1}`}
                                color={!index ? 'red' : index === 1 ? 'indigo' : index === 2 ? 'yellow' : 'purple'}
                            />
                            <h3 className="text-base font-semibold">{item.title}</h3>
                            <span className="block text-slate-600 dark:text-slate-400 text-sm leading-6">{item.desc}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SectionHowItWork;
