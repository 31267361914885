import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import LikeButton from './LikeButton';
import Prices from './Prices';
import service from 'service/constant';

export interface ProductCardProps {
    className?: string;
    //   data?: Product;
    isLiked?: boolean;
    name: string;
    id: string;
    image: string;
    price: number;
    isSale?: boolean;
    salePrice?: number;
}

const ProductCard: FC<ProductCardProps> = ({
    className = '',
    //   data = PRODUCTS[0],
    name,
    id,
    price,
    image,
    isLiked,
    isSale = false,
    salePrice = 0
}) => {
    const navigate = useNavigate();
    const clickProductDetail = () => {
        navigate(`/product-details/${id}`);
    };
    const url = service.IMAGE_URL;
    const sliderImage = `${url}${image}`;

    return (
        <>
            <div className={`nc-ProductCard relative flex flex-col bg-transparent ${className}`} data-nc-id="ProductCard">
                <div className="relative flex-shrink-0 bg-slate-50 dark:bg-slate-300 rounded-3xl overflow-hidden z-1 group">
                    <NcImage
                        style={{ cursor: 'pointer' }}
                        onClick={clickProductDetail}
                        containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0"
                        src={sliderImage}
                        className="object-fill w-full h-full drop-shadow-xl"
                    />

                    <LikeButton liked={isLiked} className="absolute top-3 right-3 z-10" />
                </div>

                <div className="space-y-4 px-2.5 pt-5 pb-2.5">
                    <div>
                        <h2 className={`nc-ProductCard__title text-base font-semibold transition-colors`} onClick={clickProductDetail}>
                            {name}
                        </h2>
                    </div>

                    <div className="flex justify-between items-end " onClick={clickProductDetail}>
                        <Prices price={price} isSale={isSale} salePrice={salePrice} />
                    </div>
                </div>
            </div>

            {/* QUICKVIEW */}
            {/* <ModalQuickView
        show={showModalQuickView}
        onCloseModalQuickView={() => setShowModalQuickView(false)}
      /> */}
        </>
    );
};

export default ProductCard;
