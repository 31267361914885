import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { useForm, SubmitHandler } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { Form, FormFeedback } from 'reactstrap';
import Input from 'shared/Input/Input';
import showLoader from 'components/LoaderComponent';
import axios from 'axios';
import service from 'service/constant';
import NcImage from 'shared/NcImage/NcImage';
import ResetSuccessImage from 'images/404.png';
import ResetFailureImage from 'images/404.png';

export interface PageLoginProps {
    className?: string;
}

type FormValues = {
    password: string;
    confirmPassword: string;
};

const ResetState = {
    Pending: 1,
    Success: 2,
    Failure: 3
} as const;
type ResetStateType = (typeof ResetState)[keyof typeof ResetState];

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};
const PageResetPassword: FC<PageLoginProps> = ({ className = '' }) => {
    const query = useQuery();
    const token = query.get('token'); // Replace 'paramName' with the name of the query parameter you want to get

    // const dispatch = useDispatch();
    const navigate = useNavigate();
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);
    const [resetState, setResetState] = useState<ResetStateType>(ResetState.Pending);
    const [seconds, setSeconds] = useState(5);

    const ResetPasswordAPI = async (data: any) => {
        return await axios.post(service.API_URL + '/api/v1/client/reset-password', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        showLoader(true);
        setButtonStatus(true);
        const customerData = {
            password: data?.password,
            token: token
        };
        ResetPasswordAPI(customerData)
            .then((res) => {
                // toast.success(res?.data?.message || 'Your password has been successfully updated!');
                const url = new URL(window.location.toString());
                url.searchParams.delete('token');
                window.history.pushState({}, '', url.toString());

                reset();
                setResetState(ResetState.Success);
                // navigate('/login');
                showLoader(false);
            })
            .catch((err) => {
                setResetState(ResetState.Failure);
                showLoader(false);
                setButtonStatus(false);
                // toast.error(err?.response?.data?.message || 'Something went wrong!');
            });
    };
    const validationScheme = yup.object().shape({
        password: yup
            .string()
            .min(8, 'Password must be at least 8 characters.')
            .required('Password is required.')
            .matches(
                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[!@#$&*~]).{8,}$/,
                'Must contain at least one number, uppercase, lowercase and special character.'
            ),
        confirmPassword: yup
            .string()
            .required('Confirm Password is required.')
            .oneOf([yup.ref('password')], 'Password and Confirm Password must match.')
            .min(8, 'Password must be at least 8 characters.')
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm<FormValues>({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    useEffect(() => {
        if (resetState === ResetState.Pending && (!token || token.length === 0)) {
            navigate('/');
        } else if (resetState === ResetState.Success) {
            if (seconds > 0) {
                const timer = setInterval(() => {
                    setSeconds((prev) => prev - 1);
                }, 1000);

                return () => clearInterval(timer);
            } else {
                navigate('/login');
            }
        }
    }, [seconds, resetState]);

    return (
        <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
            <Helmet>
                <title>Reset Password || Instaacard</title>
            </Helmet>

            {resetState === ResetState.Pending && (
                <div className="container mb-24 lg:mb-32">
                    <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                        Reset Password
                    </h2>
                    <div className="max-w-md mx-auto space-y-6">
                        {/* FORM */}
                        <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">Password</span>
                                <Input
                                    type="password"
                                    placeholder="Password"
                                    className="mt-1"
                                    style={{ borderColor: errors.password ? '#ea5455' : '' }}
                                    {...register('password')}
                                />
                                {errors && errors.password && (
                                    <FormFeedback style={{ color: '#ea5455' }}>{errors.password.message}</FormFeedback>
                                )}
                            </label>

                            <label className="block">
                                <span className="text-neutral-800 dark:text-neutral-200">Confirm Password</span>
                                <Input
                                    type="password"
                                    placeholder="Confirm Password"
                                    className="mt-1"
                                    style={{ borderColor: errors.confirmPassword ? '#ea5455' : '' }}
                                    {...register('confirmPassword')}
                                />
                                {errors && errors.confirmPassword && (
                                    <FormFeedback style={{ color: '#ea5455' }}>{errors.confirmPassword.message}</FormFeedback>
                                )}
                            </label>

                            <ButtonPrimary type="submit" disabled={buttonStatus}>
                                Continue
                            </ButtonPrimary>
                        </Form>
                    </div>
                </div>
            )}

            {resetState === ResetState.Success && (
                <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                    {/* HEADER */}
                    <header className="text-center max-w-2xl mx-auto space-y-2">
                        <NcImage src={ResetSuccessImage} />
                        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                            Your password has been reset <b>Successfully!</b>.
                            <br /> Redirecting to login page in {seconds} seconds...
                        </span>
                        <div className="pt-8">
                            <ButtonPrimary href="/login">Proceed to Login</ButtonPrimary>
                        </div>
                    </header>
                </div>
            )}

            {resetState === ResetState.Failure && (
                <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                    {/* HEADER */}
                    <header className="text-center max-w-2xl mx-auto space-y-2">
                        <NcImage src={ResetFailureImage} />
                        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                            Oops, the link you are trying to access is <b>expired</b>. <br />
                            Kindly generate new link to reset your password.
                        </span>
                        <div className="pt-8">
                            <ButtonPrimary href="/forgot-password">Click here to resend...</ButtonPrimary>
                        </div>
                    </header>
                </div>
            )}
        </div>
    );
};

export default PageResetPassword;
