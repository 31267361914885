import React from 'react';
import { Link } from 'react-router-dom';
import logoImg from 'images/logo.svg';
import logoLightImg from 'images/logo-light.svg';

export interface LogoProps {
    img?: string;
    imgLight?: string;
    className?: string;
    style?: object;
}

const Logo: React.FC<LogoProps> = ({ img = logoImg, imgLight = logoLightImg, className = 'flex-shrink-0', style = {} }) => {
    return (
        <Link to="/" className={`ttnc-logo inline-block text-slate-600 ${className}`} style={style}>
            {/* THIS USE FOR MY CLIENT */}
            {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
            {img ? (
                <img
                    className={`block max-h-8 sm:max-h-10 ${imgLight ? 'dark:hidden' : ''}`}
                    // src={img} //todo uncomment
                    src="https://instaacard-website.vercel.app/assets/images/icon/logo/InstaacardLogo.png"
                    alt="Logo"
                    width="175px"
                />
            ) : (
                'Logo Here'
            )}
            {imgLight && <img className="hidden max-h-8 sm:max-h-10 dark:block" src={imgLight} alt="Logo-Light" />}
        </Link>
    );
};

export default Logo;
