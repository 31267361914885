import { Dialog, Transition } from '@headlessui/react';
// import ProductQuickView from 'components/ProductQuickView';
// import ProductQuickView2 from 'components/ProductQuickView2';
import React, { FC, Fragment, useEffect, useState } from 'react';
// import { useLocation } from 'react-router-dom';
import { Address } from 'reduxStore/store/order/class';
import ButtonClose from 'shared/ButtonClose/ButtonClose';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { createCustomerAddress, updateCustomerAddress } from 'reduxStore/store/address';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reduxStore/store';
import { FormFeedback } from 'reactstrap';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import Select from 'react-select';
import Checkbox from 'shared/Checkbox/Checkbox';

export interface ModalQuickViewProps {
    show: boolean;
    handleModal: (data: Address | null) => void;
    data: Address | null;
}

const AddressModal: FC<ModalQuickViewProps> = ({ show, handleModal, data }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [buttonStatus, setButtonStatus] = useState(false);
    const [checkIsCompany, setCheckIsCompany] = useState(false);
    const [checkIsDefault, setCheckIsDefault] = useState(false);

    const statesData = useSelector((state: RootState) => state?.addressSlice?.states);

    // validation Object
    let validationObj = {
        address1: yup.string().required('Address Line1 is required.'),
        address2: yup.string().required('Address Line2 is required.'),
        countryName: yup.string().required('Country is required.'),
        stateId: yup.object().required('State is required.').typeError('State must be an object.'),
        stateName: yup.string(),
        cityName: yup.string().required('City is required.'),
        isDefault: yup.boolean(),
        isCompany: yup.boolean(),
        jobTitle: yup.string(),
        companyName: yup.string().when('isCompany', {
            is: true,
            then: (schema) => schema.required('Company name is required'),
            otherwise: (schema) => schema
        }),
        zipCode: yup.number().typeError('Zip Code must be in number.').required('Zip Code is required.')
    };
    // .test('len', 'Must be exactly 6 Digit Zip Code', (value: string) => value.toString().length === 6)

    const validationScheme = yup.object().shape(validationObj);

    // Hook Form
    const {
        register,
        reset,
        handleSubmit,
        control,
        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });

    const onSubmit = async (formData: any) => {
        const groupData = {
            id: data?.id || null,
            address1: formData?.address1,
            address2: formData?.address2,
            countryName: formData?.countryName,
            stateId: formData?.stateId.value,
            stateName: formData?.stateId?.label,
            cityName: formData?.cityName,
            zipCode: formData?.zipCode,
            isDefault: checkIsDefault,
            isCompany: checkIsCompany,
            companyName: checkIsCompany == false ? null : formData?.companyName,
            jobTitle: checkIsCompany == false ? null : formData?.jobTitle
        };

        setButtonStatus(true);
        if (data && data.id) {
            dispatch(updateCustomerAddress(groupData));
            reset({});
            setCheckIsDefault(false);
            setCheckIsCompany(false);
            handleModal(null);
        } else {
            dispatch(createCustomerAddress(groupData));
            reset({});
            setCheckIsDefault(false);
            setCheckIsCompany(false);
            handleModal(null);
        }

        setButtonStatus(false);
    };

    const resetData = () => {
        if (data?.id) {
            reset({
                address1: data?.address1 || '',
                address2: data?.address2 || '',
                countryName: data?.countryName || '',
                stateId: { value: data?.State?.id, label: data?.State?.name },
                cityName: data?.cityName || '',
                zipCode: data?.zipCode || 0,
                isDefault: data.isDefault,
                isCompany: data.isCompany || false,
                companyName: data?.companyName || '',
                jobTitle: data?.jobTitle || ''
            });
            setCheckIsDefault(data.isDefault ? true : false);
            setCheckIsCompany(data.isCompany ? true : false);
        } else {
            setCheckIsDefault(false);
            setCheckIsCompany(false);
            reset();
        }
    };

    useEffect(() => {
        resetData();
    }, [data, show]);

    return (
        <Transition appear show={show} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 z-50" onClose={() => handleModal(null)}>
                <div className="flex items-stretch md:items-center justify-center h-full text-center md:px-4">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="fixed inset-0 bg-black/40 dark:bg-black/70" />
                    </Transition.Child>

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block align-middle" aria-hidden="true">
                        &#8203;
                    </span>
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <div className="relative inline-flex xl:py-8 w-full max-w-5xl max-h-full">
                            <div
                                className="flex-1 flex overflow-hidden max-h-full p-8 w-full text-left align-middle transition-all transform lg:rounded-2xl bg-white 
              dark:bg-neutral-900 dark:border dark:border-slate-700 dark:text-slate-100 shadow-xl"
                            >
                                <span className="absolute right-3 top-3 z-50">
                                    <ButtonClose onClick={() => handleModal(null)} />
                                </span>
                                <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                                    <div className="grid grid-cols-4 gap-4">
                                        <label className="block col-span-2">
                                            <Label>Address Line 1</Label>
                                            <Input
                                                type="text"
                                                placeholder="Flat, House no., Building, Company, Apartment"
                                                className="mt-1"
                                                onInvalid={(e) => {
                                                    errors.address1 && true;
                                                }}
                                                {...register('address1')}
                                            />
                                            {errors && errors.address1 && <FormFeedback>{errors.address1.message}</FormFeedback>}
                                        </label>

                                        <label className="block col-span-2">
                                            <Label>Address Line 2</Label>
                                            <Input
                                                type="text"
                                                placeholder="Area, Street, Sector, Village"
                                                className="mt-1"
                                                onInvalid={(e) => {
                                                    errors.address2 && true;
                                                }}
                                                {...register('address2')}
                                            />
                                            {errors && errors.address2 && <FormFeedback>{errors.address2.message}</FormFeedback>}
                                        </label>

                                        <label className="block">
                                            <Label>Country</Label>
                                            <Input
                                                type="text"
                                                placeholder="Area, Street, Sector, Village"
                                                className="mt-1"
                                                onInvalid={(e) => {
                                                    errors.countryName && true;
                                                }}
                                                {...register('countryName')}
                                            />
                                            {errors && errors.countryName && <FormFeedback>{errors.countryName.message}</FormFeedback>}
                                        </label>

                                        <label className="block">
                                            <Label>State</Label>
                                            <Controller
                                                control={control}
                                                name="stateId"
                                                render={({ field }) => (
                                                    <Select
                                                        // isClearable
                                                        classNamePrefix="select"
                                                        // input
                                                        // className={`mt-1 nc-Select rounded-2xl `}
                                                        className="text-sm rounded-md py-1 border-slate-200 dark:border-slate-700 relative z-10 dark:bg-slate-800 "
                                                        placeholder={'Select Address'}
                                                        options={statesData}
                                                        // onChange={(val: any) => {
                                                        //     // Update the form state
                                                        //     // onChange(val)
                                                        // }}
                                                        {...field}
                                                    />
                                                )}
                                            />
                                            {errors && errors?.stateId && <FormFeedback>{errors?.stateId?.message}</FormFeedback>}
                                        </label>
                                        <label className="block">
                                            <Label>City</Label>
                                            <Input
                                                type="text"
                                                className="form-control"
                                                id="cityName"
                                                placeholder="City"
                                                {...register('cityName')}
                                            />
                                            {errors && errors?.cityName && <FormFeedback>{errors?.cityName?.message}</FormFeedback>}
                                        </label>

                                        <label className="block">
                                            <Label>Zip Code</Label>
                                            <Input
                                                type="tel"
                                                className="form-control"
                                                // maxlength="6"
                                                pattern="[0-9]{6,}"
                                                {...register('zipCode')}
                                            />
                                            {errors && errors?.zipCode && <FormFeedback>{errors?.zipCode?.message}</FormFeedback>}
                                        </label>

                                        {checkIsCompany === true && (
                                            <>
                                                <label className="block">
                                                    <Label>Job Title</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control"
                                                        id="jobTitle"
                                                        placeholder="Job Title"
                                                        {...register('jobTitle')}
                                                    />
                                                    {errors && errors?.jobTitle && <FormFeedback>{errors?.jobTitle?.message}</FormFeedback>}
                                                </label>
                                                <label className="block">
                                                    <Label>Company Name</Label>
                                                    <Input
                                                        type="text"
                                                        className="form-control invalid"
                                                        id="companyName"
                                                        placeholder="Company Name"
                                                        {...register('companyName')}
                                                    />
                                                    {errors && errors?.companyName && (
                                                        <FormFeedback>{errors?.companyName?.message}</FormFeedback>
                                                    )}
                                                </label>
                                            </>
                                        )}
                                    </div>
                                    <div className="grid grid-cols-4 gap-4 mt-5">
                                        <label className="block">
                                            <Controller
                                                control={control}
                                                name="isCompany"
                                                render={({ field: { value, onChange } }) => (
                                                    <Checkbox
                                                        defaultChecked={value}
                                                        name="isCompany"
                                                        label="Is Company"
                                                        onChange={(checked) => {
                                                            onChange(checked);
                                                            setCheckIsCompany(checked);
                                                        }}
                                                    />
                                                )}
                                            />
                                        </label>

                                        {!data?.id || data?.isDefault == false ? (
                                            <label className="block">
                                                <Controller
                                                    control={control}
                                                    name="isDefault"
                                                    render={({ field: { value, onChange } }) => (
                                                        <Checkbox
                                                            defaultChecked={value}
                                                            name="isDefault"
                                                            label="Is Default"
                                                            onChange={(checked) => {
                                                                onChange(checked);
                                                                setCheckIsDefault(checked);
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </label>
                                        ) : null}
                                    </div>

                                    <div className="mt-5">
                                        <ButtonPrimary disabled={buttonStatus} type="submit">
                                            Submit
                                        </ButtonPrimary>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition>
    );
};

export default AddressModal;
