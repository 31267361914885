// ** React Imports
import React, { FC, ReactNode } from 'react';
import { Navigate } from 'react-router-dom';
import { Suspense } from 'react';
import { Page } from './types';

// auth config import
import { useSelector } from 'react-redux';
import { RootState } from 'reduxStore/store';

export interface PrivateRouteProps {
    children?: ReactNode;
    route: Page;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ children, route }) => {
    // ** Hooks & Vars
    const user = useSelector((state: RootState) => state.auth.accessToken);
    if (route) {
        if (!user) {
            return <Navigate to="/login" />;
        }
    }
    return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
