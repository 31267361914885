import ButtonPrimary from 'shared/Button/ButtonPrimary';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import NcImage from 'shared/NcImage/NcImage';
import Promo3 from 'images/promo3.png';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'service/axios';
import { Order } from 'reduxStore/store/order/class';
import Loader from './Loader';

const Page404: React.FC = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    // eslint-disable-next-line no-unused-vars
    const [orderData, setOrderData] = useState<Order | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        axios
            .get('/customer-order/' + id)
            .then((res) => {
                setOrderData(res?.data);
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                return navigate('/404');
            });
        return () => {
            setOrderData(null);
            setIsLoading(true);
        };
    }, []);

    return (
        <div className="nc-Page404">
            <Helmet>
                <title>Order Success || Instaacard</title>
            </Helmet>
            <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
                {/* HEADER */}
                {isLoading && <Loader />}
                {!isLoading && (
                    <header className="text-center max-w-2xl mx-auto space-y-2 items-center">
                        <NcImage src={Promo3} containerClassName="h-96 flex justify-center" className="h-96" />
                        {/* <Check /> */}
                        <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-2">
                            THANK YOU
                        </h2>
                        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                            Your payment is successfully processed and Your Order is on the way.
                        </span>{' '}
                        <span className="block text-sm text-neutral-800 sm:text-base dark:text-neutral-200 tracking-wider font-medium">
                            Transaction ID: {id}
                        </span>
                        <div className="pt-8">
                            <ButtonPrimary href="/">Continue Shopping</ButtonPrimary>
                        </div>
                    </header>
                )}
            </div>
        </div>
    );
};

export default Page404;
