/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';

const shippingPolicy = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl mb-5">
                <div className="max-w-screen-md mx-auto space-y-5">
                    {/* <Badge href="##" color="purple" name="Traveler" /> */}
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Shipping Policy
                    </h1>
                    <p>
                        This Privacy Policy ("Privacy Policy") aims to inform you about the information we collect when you make a purchase
                        on our store, www.instaacard.com, operated by<strong> Trumpcard Initiatives Worldwide&nbsp; Pvt. Ltd</strong>. At{' '}
                        <strong>Instaacard,</strong> safeguarding your private information is our top priority, and we are dedicated to
                        transparency. We respect the privacy concerns of our customers and value the trust you place in us when using our
                        website and services.
                    </p>
                    <p>
                        By "personally identifying information," we mean data that directly identifies you, such as your name, address,
                        phone number, payment details, and email addresses. We also gather demographic information, like your postcode, and
                        may collect information about your computer, including your IP address, when you visit our website.
                    </p>
                    <p>
                        &nbsp;<strong>Changes to this Privacy Policy:</strong>
                    </p>
                    <p>
                        We reserve the right to make changes to this policy at any time. It is advisable to visit this page frequently to
                        stay updated. If we decide to use personally identifiable information in a manner different from the initial
                        statement, users will be promptly notified by email, with the option to permit or deny the use of their information
                        in this separate manner.
                    </p>
                    <p>
                        &nbsp;<strong>Privacy Policy for Children:</strong>
                    </p>
                    <p>
                        We do not intentionally collect Personal Data from users under 18. If a user under 18 submits Personal Data, we will
                        attempt to delete it promptly upon discovery. Parents or guardians discovering their child provided information
                        without consent should contact us, and we will delete the information as soon as reasonably possible.
                    </p>
                    <p>
                        &nbsp;<strong>How We Obtain or Collect Information about You:</strong>
                    </p>
                    <p>
                        <strong>Information You Provide:</strong>
                    </p>
                    <p>"Personal Data" includes information like your name, telephone number, location, email address, and username.</p>
                    <p>
                        "Social Logins" from platforms like Facebook, Google, or Apple may provide first name, last name, and email address
                        to facilitate account creation.
                    </p>
                    <p>
                        <strong>Automated Information Collection:</strong>
                    </p>
                    <p>Log Information: Server logs record browser details, IP address, web requests, browser type, and cookies.</p>
                    <p>Cookies: Sent when you log in or purchase products, used to improve site quality.</p>
                    <p>Links: Clickable links may be tracked for product improvement.</p>
                    <p>
                        <strong>How We Use Your Information:</strong>
                    </p>
                    <p>
                        We use the gathered information to provide, improve, and develop our website and services. This includes account
                        creation, secure account management, sending newsletters, personalized recommendations, enhancing safety and
                        security, troubleshooting service issues, customer communication, service operation and improvement, fraud
                        prevention, and legal compliance.
                    </p>
                    <p>
                        <strong>Personal Information That We Share:</strong>
                    </p>
                    <p>
                        We do not sell, rent, or trade personal information. Sharing is limited to third-party service providers,
                        subsidiaries, affiliates, or in cases of merger, protection, breach of terms, compliance with laws, and user
                        consent.
                    </p>
                    <p>
                        <strong>Data Security:</strong>
                    </p>
                    <p>
                        While we implement security measures, no online transmission is 100% secure. Users are encouraged to use strong,
                        unique passwords and limit access to their accounts. Various policies, including encryption, access, and retention
                        policies, are in place to guard against unauthorized access and unnecessary data retention.
                    </p>
                    <p>
                        &nbsp;<strong>Data Retention and Deletion:</strong>
                    </p>
                    <p>
                        We retain personal data for necessary business purposes. Users can request deletion or anonymization, but certain
                        data may be retained for legal obligations, dispute resolution, or legitimate business interests.
                    </p>
                    <p>
                        &nbsp;<strong>Links to Other Websites:</strong>
                    </p>
                    <p>
                        This Privacy Policy applies solely to our site. Users are advised to review the privacy policies of linked
                        third-party sites.
                    </p>
                    <p>
                        &nbsp;<strong>Contact Information:</strong>
                    </p>
                    <p>For questions, complaints, or more information, users can contact us at support@instaacard.com.</p>
                    <p>
                        <strong>Rights and Choices:</strong>
                    </p>
                    <p>
                        Users have rights to access, update, erase, and restrict processing of their personal data. Opt-out options are
                        available for newsletters and commercial emails.
                    </p>
                    <p>
                        &nbsp;This Privacy Policy is designed to ensure clarity and transparency regarding the handling of personal
                        information by <strong>Trumpcard Initiatives Worldwide Pvt. Ltd</strong>.
                    </p>{' '}
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert mb-5">
                <h3>
                    <strong>Shipping Policy</strong>
                </h3>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Shipping Policy | Instaacard</title>
            </Helmet>
            {renderHeader()}

            {/* <div className="nc-SingleContent container space-y-10">{renderContent()}</div> */}
        </div>
    );
};

export default shippingPolicy;
