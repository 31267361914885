// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import queryString from 'query-string';
import { Product } from '../products/class';
// ** API Imports
import axios from '../../../service/axios';

export const getSliderProduct = createAsyncThunk('productSliderSlice/getSliderProduct', async () => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/product?productCategoryId=be51b0d7-5ea5-45fa-90ff-e6e5205a03bd', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});
export const getBestSellerProduct = createAsyncThunk('productSliderSlice/getBestSellerProduct', async () => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/product?productCategoryId=59fc06b1-bee3-4881-bc45-e526023e4940', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});

export const getProductById = createAsyncThunk('productSliderSlice/getProductById', async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get(`/product/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});

export const getProductCategory = createAsyncThunk('productSliderSlice/getProductCategory', async () => {
    try {
        let response = await axios.get('/product-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});

export const productSliderSlice = createSlice({
    name: 'SliderProductData',
    initialState: {
        productSlider: [] as Product[],
        bestProductSlider: [] as Product[],
        productCount: 0 as number,
        nextPage: 1 as number,
        productCategory: [],
        productById: {} as Product
    },

    reducers: {},

    extraReducers: (builder) => {
        builder.addCase(getSliderProduct.fulfilled, (state, action) => {
            state.productSlider = action.payload.productMaster;
        });
        builder.addCase(getBestSellerProduct.fulfilled, (state, action) => {
            state.bestProductSlider = action.payload.productMaster;
        });
        builder.addCase(getProductCategory.fulfilled, (state, action) => {
            state.productCategory = action.payload;
        });
        builder.addCase(getProductById.fulfilled, (state, action) => {
            state.productById = action.payload;
        });
    }
});

export default productSliderSlice.reducer;
