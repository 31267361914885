import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { confirmDelete, showAlert } from 'components/Swals';
import { toast } from 'react-toastify';
// import queryString from 'query-string';
import axios from 'service/axios';

// get loggedIn customer
export const getCustomerAddressAll = createAsyncThunk('customerAddressSlice/getCustomerAddressAll', async () => {
    try {
        // const query = queryString.stringify(data);
        let response = await axios.get('/customer-address', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response?.data);
    } catch (err) {
        // toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAllStates = createAsyncThunk('customerAddressSlice/state', async () => {
    try {
        let response = await axios.get('/state', {
            headers: {
                'Content-Type': 'application/json'
            }
        });

        return Promise.resolve(response.data);
    } catch (err) {
        console.error('Error', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// create new customer
export const createCustomerAddress = createAsyncThunk('customerAddressSlice/createCustomerAddress', async (data: any, { dispatch }) => {
    try {
        let response = await axios.post('/customer-address', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Address Added successfully.');
        dispatch(getCustomerAddressAll());

        return Promise.resolve(response.data);
    } catch (err) {
        console.error('Error', err);
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// update customer address
export const updateCustomerAddress = createAsyncThunk(
    'customerAddressSlice/updateCustomerAddress',
    async (data: any = {}, { dispatch }) => {
        try {
            let response = await axios.put('/customer-address/' + data?.id, data, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            toast.success(response?.data?.message || 'Address updated successfully.');
            dispatch(getCustomerAddressAll());

            return Promise.resolve(response.data);
        } catch (err) {
            console.error('Error', err);
            // toast.error(err?.response?.data?.message || 'Something went wrong!');
            return Promise.reject(err);
        }
    }
);

// delete customer
// export const deleteCustomerAddress = createAsyncThunk('customerAddressSlice/deleteCustomerAddress', async (id, { dispatch }) => {
//     try {
//         let response = await axios.delete('/customer-address/' + id, {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         toast.success(response?.data?.message || 'Address deleted successfully.');
//         dispatch(getCustomerAddressAll());

//         return Promise.resolve(response.data);
//     } catch (err) {
//         console.error('Error', err);
//         // toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });
export const deleteCustomerAddress = createAsyncThunk(
    'customerAddressSlice/deleteCustomerAddress',
    async (data: { id: string }, { dispatch }) => {
        try {
            const result = await confirmDelete('Delete Address');
            if (result.isConfirmed) {
                let response = await axios.delete('/customer-address/' + data.id, {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                toast.success(response?.data?.message || 'Address deleted successfully.');
                dispatch(getCustomerAddressAll());

                await showAlert('success', 'Deleted!', 'Your record has been deleted.', 'btn btn-success');
                // toast.success(response?.data?.message || 'User deleted successfully.');
                // showLoader(false);
                return Promise.resolve(response.data);
            }
        } catch (err: any) {
            console.log('err', err);
            showAlert('error', 'Not Deleted!', `${err?.response?.data?.message || 'Something went wrong!'}`, 'btn btn-danger');
            return Promise.reject(err);
        }
    }
);

export const addressSlice = createSlice({
    name: 'addressSlice',
    initialState: {
        customerAddress: [],
        states: []
    },
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getCustomerAddressAll.fulfilled, (state, action) => {
            state.customerAddress = action.payload;
            return;
        });
        builder.addCase(getAllStates.fulfilled, (state, action) => {
            state.states = action.payload;
        });
    }
});

export default addressSlice.reducer;
