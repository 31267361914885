/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';

const ReturnPolicy = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl mb-5">
                <div className="max-w-screen-md mx-auto space-y-5">
                    {/* <Badge href="##" color="purple" name="Traveler" /> */}
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        RETURN AND REFUND POLICY
                    </h1>
                    <p>This Refund Policy outlines our procedures for managing refunds.</p>
                    <p>
                        <strong>Returns:</strong>
                    </p>
                    <p>
                        We do not accept returns. To qualify for a refund, you must provide a receipt or proof of purchase in the form of an
                        order number, and your order must not have been delivered according to carrier tracking information.
                    </p>
                    <p>
                        <strong>Refunds (if applicable):</strong>
                    </p>
                    <p>
                        If your<strong> Instaacard</strong> Smart Business card is scratched or damaged, you may be eligible for a
                        replacement. We offer replacements for damaged <strong>Instaacard</strong> Smart Business card with photo proof
                        within 48 hours of delivery.
                    </p>
                    <p>
                        If you encounter difficulties activating your <strong>Instaacard</strong> Smart Business card or connecting it to
                        compatible phones and wish to request a refund or replacement, please contact our team at support@instaacard.com.
                        Usually, most issues can be resolved with helpful tips from our team.
                    </p>
                    <p>
                        If you have successfully activated your <strong>Instaacard</strong> Smart Business card but are still experiencing
                        issues with compatibility after contacting support@instaacard.com, you may be eligible for a replacement{' '}
                        <strong>Instaacard</strong> Smart Business card. If issues persist even after activating a replacement and you
                        request a refund, you may be eligible for a refund.
                    </p>
                    <p>
                        If you request a refund and our team cannot confirm the potential defect, you may be eligible for a partial refund
                        on your order.
                    </p>
                    <p>
                        Once approved for a refund, we will send you an email notifying you of the processed refund. The credit will be
                        automatically applied to your credit card or original payment method within 2-4 days.
                    </p>
                    <p>
                        <strong>Warranty:</strong>
                    </p>
                    <p>
                        If your <strong>Instaacard</strong> Smart Business card is damaged or loses its adhesive within the first 10 days of
                        the order being marked as delivered, you qualify for a free replacement. Contact customer service at
                        support@instaacard.com for eligibility verification.
                    </p>
                    <p>
                        <strong>Late or Missing Refunds (if applicable):</strong>
                    </p>
                    <p>
                        If you haven't received a refund, check your bank account. Contact your credit card company and bank. If issues
                        persist, contact us at support@instaacard.com.
                    </p>
                    <p>
                        <strong>Sale Items:</strong>
                    </p>
                    <p>Only regular-priced items are eligible for refunds; sale items cannot be refunded.</p>
                    <p>
                        <strong>Exchanges:</strong>
                    </p>
                    <p>We do not accept exchanges.</p>
                    <p>
                        <strong>Shipping:</strong>
                    </p>
                    <p>Since we do not accept returns, no shipping information is required.</p>
                    <p>
                        <strong>Contact Us:</strong>
                    </p>
                    <p>
                        For more information or to address privacy concerns, questions, or complaints, contact us via email at
                        support@instaacard.com.
                    </p>{' '}
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert mb-5">
                <ul>
                    <li>
                        You can return or exchange within 10 days after the date of delivery, But under the condition that Instaaprint
                        customer service is intimated within 10 days of delivery of the goods to your address.
                    </li>
                    <li>
                        No additional amount will be required for the generation of exchange. The refund amount shall be transferred to your
                        bank account within 8-10 business days.
                    </li>
                    <li>
                        Shipping charges are not refunded. Customers are expected to pay for the shipping costs if the pin code is out of
                        the pickup location.
                    </li>
                    <li>Only damaged or defective items are eligible for return or exchange.</li>
                    <li>Items which are ordered through a scheme or combo offers are not eligible for exchange or return.</li>

                    <li>
                        Exchange is initiated for non-refundable items only in case of damaged goods or when the product is different from
                        the description.
                    </li>
                    <li>
                        Only items which are unused and undamaged with all their tags and labels intact, in original packaging are qualified
                        for return.
                    </li>
                    <li>CLAIMING REFUNDS</li>
                </ul>

                <h3>Refunds will be initiated only in the following cases:</h3>
                <ul>
                    <li>Certifiable quality issue</li>
                    <li>Packages lost on the way</li>
                    <li>If a wrong item has been delivered to you.</li>
                    <li>If we find any mispricing in its online catalog and the value charged to you is higher than the genuine cost</li>
                    <li>On the off chance that the client isn’t happy with the purchase</li>
                </ul>

                <h3>No refunds will be initiated in the following cases:</h3>
                <ul>
                    <li>In the event of minor design or shading variation</li>
                    <li>Incorrect or obsolete conveyance address</li>
                    <li>Inaccurate location format including any type of a PO Box address</li>
                    <li>After 3 failed delivery attempts by the courier</li>
                    <li>Items returned in a used or flawed condition</li>
                </ul>

                <h3>FOR CREDIT CARD / DEBIT CARD / NET BANKING OR PAYMENT WALLET TRANSACTIONS:</h3>
                <ul>
                    <li>
                        The refund amount shall be transferred to your bank account within 8-10 business days post receiving and quality
                        checking of the returned product.
                    </li>
                    <li>
                        Shipping charges are not applicable for a refund unless Instaaprint faultily made a mistake in delivering the wrong
                        item.
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>RETURN AND EXCHANGE POLICY | Instaacard</title>
            </Helmet>
            {renderHeader()}

            {/* <div className="nc-SingleContent container space-y-10">{renderContent()}</div> */}
        </div>
    );
};

export default ReturnPolicy;
