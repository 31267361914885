import { useEffect, useState } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Page } from './types';
import ScrollToTop from './ScrollToTop';
import Footer from 'shared/Footer/Footer';
import PageHome from 'containers/PageHome/PageHome';
import Page404 from 'containers/Page404/Page404';
import AccountPage from 'containers/AccountPage/AccountPage';
import PageContact from 'containers/PageContact/PageContact';
import PageAbout from 'containers/PageAbout/PageAbout';
import PageSignUp from 'containers/PageSignUp/PageSignUp';
import PageLogin from 'containers/PageLogin/PageLogin';
import PaymentSuccess from 'containers/PaymentPages/PaymentSuccess';
import PaymentFailure from 'containers/PaymentPages/PaymentFailure';
import SiteHeader from 'containers/SiteHeader';
import CollectionPage from 'containers/CollectionPage';
import ProductDetailPage from 'containers/ProductDetailPage/ProductDetailPage';
import SingleProductDetailPage from '../containers/ProductDetailPage/SingleProductDetails';
import AccountOrder from 'containers/AccountPage/AccountOrder';
import CartPage from 'containers/ProductDetailPage/CartPage';
import CheckoutPage from 'containers/PageCheckout/CheckoutPage';
import FilterProductCollection from 'containers/FilterProductCollection';
import SingleOrder from 'containers/AccountPage/SingleOrder';
import { useDispatch } from 'react-redux';
import { verifyToken } from '../reduxStore/store/auth/index';
import { AppDispatch } from '../reduxStore/store';
import { isUserLoggedIn } from '../utils/User';
import { Order } from 'reduxStore/store/order/class';
import AccountAddress from 'containers/AccountPage/AccountAddress';
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer } from 'react-toastify';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import { AxiosInterceptor } from 'service/axios';
import { getCart } from 'reduxStore/store/cart';
import TermsPage from 'containers/PolicyPages/TermsCondition';
import CancellationPage from 'containers/PolicyPages/Cancellation';
import PrivacyPolicy from 'containers/PolicyPages/PrivacyPolicy';
import ReturnPolicy from 'containers/PolicyPages/ReturnPolicy';
import TermsUse from 'containers/PolicyPages/TermsUse';
import WhatsAppIcon from 'images/socials/Whatsapp.svg';
import PageForgetpass from 'containers/PageForgetpass/PageForgetpass';
import PageResetPassword from 'containers/PageResetPassword/PageResetPassword';

export const pages: Page[] = [
    { path: '/', component: PageHome },
    // { path: '/home2', component: PageHome2 },
    // { path: '/home3', component: PageHome3 },
    //
    // { path: '/home-header-2', component: PageHome },
    { path: '/product-details/:id', component: SingleProductDetailPage },
    { path: '/product-detail', component: ProductDetailPage },
    // { path: '/product-detail-2', component: ProductDetailPage2 },
    //
    // { path: '/page-collection-2', component: PageCollection2 },
    { path: '/subcategory/pvc-card', component: CollectionPage },
    { path: '/subcategory/metal-card', component: CollectionPage },
    { path: '/subcategory/carbon-fiber-card', component: CollectionPage },
    { path: '/products/:id', component: FilterProductCollection },
    // { path: '/page-collection', component: PageCollection },
    // { path: '/page-search', component: PageSearch },
    //
    { path: '/account', component: AccountPage, auth: true },
    // { path: '/account-save-lists', component: AccountSaveList },
    // { path: '/account-change-password', component: AccountPass },
    { path: '/address', component: AccountAddress, auth: true },
    // { path: '/account-billing', component: AccountBilling },
    { path: '/account-my-order', component: AccountOrder, auth: true },
    //
    { path: '/cart', component: CartPage },
    { path: '/checkout', component: CheckoutPage, auth: true },
    //
    // { path: '/blog', component: BlogPage },
    // { path: '/blog-single', component: BlogSingle },
    //
    { path: '/contact', component: PageContact },
    { path: '/about', component: PageAbout },
    { path: '/signup', component: PageSignUp, auth: false },
    { path: '/login', component: PageLogin, auth: false },
    // { path: '/subscription', component: PageSubscription },
    { path: '/forgot-password', component: PageForgetpass },
    { path: '/account/reset-password', component: PageResetPassword },
    { path: '/page/new-order-success/:id', component: PaymentSuccess, auth: true },
    { path: '/page/order-failure', component: PaymentFailure, auth: true },
    { path: '/terms-and-condition', component: TermsPage },
    { path: '/reverse-policy', component: TermsPage },
    { path: '/cancellation', component: CancellationPage },
    { path: '/privacy-policy', component: PrivacyPolicy },
    { path: '/return-policy', component: ReturnPolicy },
    { path: '/shipping-policy', component: TermsUse }
    // {
    //   path: "/order/:id",
    //   component: SingleOrder,
    // },
];

const MyRoutes = () => {
    const dispatch = useDispatch<AppDispatch>();
    // eslint-disable-next-line no-unused-vars
    const [selectedOrder, setSelectedOrder] = useState<Order | null>(null);

    const getCartData = async () => {
        await dispatch(getCart());
    };

    useEffect(() => {
        if (isUserLoggedIn()) {
            getCartData();
            dispatch(verifyToken());
        }
    }, []);

    return (
        <>
            <ScrollToTop />
            <SiteHeader />
            {/* <ToastContainer
                position="top-right"
                autoClose={2000}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            /> */}
            <AxiosInterceptor />
            <Routes>
                {pages.map(({ component: Component, path, auth }, index) => {
                    if (auth === true) {
                        return (
                            <Route
                                key={index}
                                path={path}
                                element={
                                    <PrivateRoute
                                        route={{
                                            path: path,
                                            component: Component,
                                            auth: auth
                                        }}
                                    >
                                        <Component />
                                    </PrivateRoute>
                                }
                            />
                        );
                    } else if (auth === false) {
                        return (
                            <Route
                                key={index}
                                path={path}
                                element={
                                    <PublicRoute
                                        route={{
                                            path: path,
                                            component: Component,
                                            auth: auth
                                        }}
                                    >
                                        <Component />
                                    </PublicRoute>
                                }
                            />
                        );
                    }

                    return <Route key={index} element={<Component />} path={path} />;
                })}

                <Route element={<Page404 />} path="*" />
                <Route element={<SingleOrder selectedOrder={selectedOrder} />} path="/order/:id" />
            </Routes>
            <div className="fixed bottom-4 right-6 z-20">
                <button
                    className=""
                    onClick={(e: any) => {
                        e.preventDefault();
                        window.open('https://wa.me/+918320800556', '_blank');
                    }}
                >
                    <img
                        src={WhatsAppIcon}
                        className="h-12 lg:h-16 md:h-12 sm:h-12 float-right font-bold tracking-wide rounded-full hover:shadow-2xl hover:shadow-cyan-500"
                    />
                </button>
            </div>
            <Footer />
        </>
    );
};

export default MyRoutes;
