import { NoSymbolIcon, CheckIcon } from '@heroicons/react/24/outline';
import NcInputNumber from 'components/QuantityAdd';
import Prices from 'components/Prices';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Eye } from 'react-feather';
import { deleteFromLocal, deleteToCart, getCart, updateToCartQuantity } from '../../reduxStore/store/cart/index';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reduxStore/store';
import { useEffect, useState } from 'react';
import { CartProduct } from 'reduxStore/store/cart/class';
import DefaultImage from '../../images/placeholder-small.png';
import { isUserLoggedIn } from 'utils/User';
import service from 'service/constant';

const CartPage = () => {
    const loginCartData = useSelector((state: RootState) => state?.CartSlice?.cart);
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const [updateQty, setUpdateQty] = useState<number | undefined>(/* initial value */);

    // eslint-disable-next-line no-unused-vars
    const getCartData = async () => {
        await dispatch(getCart());
    };

    useEffect(() => {
        if (isUserLoggedIn()) {
            // getCartData();
        }
    }, [updateQty]);

    const handleQtyUpdate = (item: any, newQuantity: number) => {
        //  let isStockAvailable = 0;
        //  if (isUserLoggedIn()) {
        //    isStockAvailable = await getStockQuantity(item?.productId);
        //  } else {
        //    isStockAvailable = await getStockQuantity(item?.ProductMaster?.id);
        //  }
        const value = newQuantity;

        if (value > 0) {
            setUpdateQty(item?.quantity * item.ProductMaster?.price);
            if (isUserLoggedIn()) {
                dispatch(
                    updateToCartQuantity({
                        id: item?.id,
                        productId: item?.productId,
                        quantity: value
                    })
                );
            } else {
                // console.log("notlogin");
                dispatch(
                    updateToCartQuantity({
                        product: item,
                        quantity: value
                    })
                );
            }
        } else {
            //  setQuantityError(false);
        }
    };

    const calculateTotalPrice = () => {
        let totalPrice = 0;

        console.log(loginCartData);
        loginCartData.forEach((item: any) => {
            // console.log(item);
            let prodPrice = item.ProductMaster.isSale ? item?.ProductMaster?.salePrice : item?.ProductMaster?.price;
            totalPrice += item?.quantity * prodPrice;
        });

        return totalPrice;
    };

    const handleRemoveFromCart = (item: any) => {
        if (isUserLoggedIn()) {
            dispatch(deleteToCart(item?.id));
        } else {
            dispatch(deleteFromLocal(item));
        }
    };

    const handleLoginAndNavigate = () => {
        if (isUserLoggedIn()) {
            navigate('/checkout');
        } else {
            navigate('/login');
        }
    };

    // eslint-disable-next-line no-unused-vars
    const renderStatusSoldOut = () => {
        return (
            <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
                <NoSymbolIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">Sold Out</span>
            </div>
        );
    };

    // eslint-disable-next-line no-unused-vars
    const renderStatusInStock = () => {
        return (
            <div className="rounded-full flex items-center justify-center px-2.5 py-1.5 text-xs text-slate-700 dark:text-slate-300 border border-slate-200 dark:border-slate-700">
                <CheckIcon className="w-3.5 h-3.5" />
                <span className="ml-1 leading-none">In Stock</span>
            </div>
        );
    };
    const renderProduct = (item: CartProduct, index: number) => {
        const url = service.IMAGE_URL;
        const image = item?.ProductMaster?.ProductImageMappings?.[0]?.ProductImage?.path
            ? `${url}${item?.ProductMaster.ProductImageMappings?.[0]?.ProductImage?.path}`
            : DefaultImage;

        const openImageInNewTab = (data: any) => {
            window.open(data, '_blank');
        };
        return (
            <div key={index} className="relative flex py-8 sm:py-10 xl:py-12 first:pt-0 last:pb-0">
                <div className="relative h-36 w-24 sm:w-40 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
                    <img src={image} className="h-full w-full object-cover" />
                </div>
                <div className="ml-3 sm:ml-6 flex flex-1 flex-col">
                    <div>
                        <div className="flex justify-between ">
                            <div className="flex-[1.5] ">
                                <h3 className="text-base font-semibold">{item?.ProductMaster?.name}</h3>
                            </div>

                            <div className="hidden sm:block text-center relative">
                                <NcInputNumber
                                    className="relative z-10"
                                    quantity={item?.quantity}
                                    // onChange={(newQuantity) =>
                                    onChange={(newQuantity) => handleQtyUpdate(item, newQuantity)}
                                />
                            </div>

                            <div className="hidden flex-1 sm:flex justify-end">
                                <Prices
                                    price={item?.ProductMaster?.price}
                                    className="mt-0.5"
                                    isSale={item?.ProductMaster?.isSale}
                                    salePrice={item?.ProductMaster?.salePrice}
                                />
                            </div>
                        </div>
                    </div>

                    <div>
                        {item?.itemData?.name && (
                            <h1>
                                <span className="text-base font-semibold">Name</span> : {item?.itemData?.name}
                            </h1>
                        )}

                        {/* {item?.itemData?.email && (
                            <h1>
                                <span className="text-base font-semibold">Email</span> : {item?.itemData?.email}
                            </h1>
                        )} */}

                        {item?.itemData?.mobile && (
                            <h1>
                                <span className="text-base font-semibold">Mobile</span> : {item?.itemData?.mobile}
                            </h1>
                        )}

                        {item?.itemData?.company && (
                            <h1>
                                <span className="text-base font-semibold">Company</span> : {item?.itemData?.company}
                            </h1>
                        )}

                        {item?.itemData?.designation && (
                            <h1>
                                <span className="text-base font-semibold">Designation</span> : {item?.itemData?.designation}
                            </h1>
                        )}

                        {item?.itemData?.variant && (
                            <h1>
                                <span className="text-base font-semibold">Variant</span> : {item?.itemData?.variant}
                            </h1>
                        )}

                        {item?.itemData?.designFile && (
                            <>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        openImageInNewTab(url + item?.itemData?.designFile);
                                    }}
                                >
                                    <div className="flex">
                                        View Design File &nbsp;
                                        <Eye />
                                    </div>
                                </button>
                            </>
                        )}

                        {item?.itemData?.companyLogo ? (
                            <>
                                <button
                                    className="btn"
                                    onClick={() => {
                                        openImageInNewTab(url + item?.itemData?.companyLogo);
                                    }}
                                >
                                    <div className="flex">
                                        View Logo &nbsp;
                                        <Eye />
                                    </div>
                                </button>
                            </>
                        ) : (
                            <></>
                        )}
                    </div>
                    <div className="flex mt-2 pt-4 items-end justify-end text-sm">
                        <span
                            className="relative z-10 flex items-center mt-3 font-medium text-primary-6000 hover:text-primary-500 text-sm "
                            onClick={() => handleRemoveFromCart(item)}
                            style={{ cursor: 'pointer' }}
                        >
                            Remove
                        </span>
                    </div>
                </div>
            </div>
        );
    };
    return (
        <div className="nc-CartPage">
            <Helmet>
                <title>Shopping Cart || Instaacard</title>
            </Helmet>

            <main className="container py-16 lg:pb-28 lg:pt-20 ">
                <div className="mb-12 sm:mb-16">
                    <h2 className="block text-2xl sm:text-3xl lg:text-4xl font-semibold ">Shopping Cart</h2>
                    <div className="block mt-3 sm:mt-5 text-xs sm:text-sm font-medium text-slate-700 dark:text-slate-400">
                        <Link to={'/#'} className="">
                            Homepage
                        </Link>

                        <span className="text-xs mx-1 sm:mx-1.5">/</span>
                        <span className="underline">Shopping Cart</span>
                    </div>
                </div>

                <hr className="border-slate-200 dark:border-slate-700 my-10 xl:my-12" />
                {loginCartData && loginCartData.length > 0 ? (
                    <div className="flex flex-col lg:flex-row">
                        <div className="w-full lg:w-[60%] xl:w-[55%] divide-y divide-slate-200 dark:divide-slate-700 ">
                            {loginCartData && loginCartData?.map(renderProduct)}
                        </div>
                        <div className="border-t lg:border-t-0 lg:border-l border-slate-200 dark:border-slate-700 my-10 lg:my-0 lg:mx-10 xl:mx-16 2xl:mx-20 flex-shrink-0"></div>
                        <div className="flex-1">
                            <div className="sticky top-28">
                                <h3 className="text-lg font-semibold ">Order Summary</h3>
                                <div className="mt-7 text-sm text-slate-500 dark:text-slate-400 divide-y divide-slate-200/70 dark:divide-slate-700/80">
                                    <div className="flex justify-between pb-4">
                                        <span>Subtotal</span>
                                        <span className="font-semibold text-slate-900 dark:text-slate-200">
                                            ₹{calculateTotalPrice().toFixed(2)}
                                        </span>
                                    </div>

                                    <div className="flex justify-between font-semibold text-slate-900 dark:text-slate-200 text-base pt-4">
                                        <span>Order total</span>
                                        <span> ₹{calculateTotalPrice().toFixed(2)}</span>
                                    </div>
                                </div>
                                <ButtonPrimary onClick={handleLoginAndNavigate} className="mt-8 w-full">
                                    Checkout
                                </ButtonPrimary>
                                {/* <div className="mt-5 text-sm text-slate-500 dark:text-slate-400 flex items-center justify-center">
                                    <p className="block relative pl-5">
                                        <svg className="w-4 h-4 absolute -left-1 top-0.5" viewBox="0 0 24 24" fill="none">
                                            <path
                                                d="M12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22Z"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M12 8V13"
                                                stroke="currentColor"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M11.9945 16H12.0035"
                                                stroke="currentColor"
                                                strokeWidth="2"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </svg>
                                        Learn more{` `}
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="##"
                                            className="text-slate-900 dark:text-slate-200 underline font-medium"
                                        >
                                            Taxes
                                        </a>
                                        <span>
                                            {` `}and{` `}
                                        </span>
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href="##"
                                            className="text-slate-900 dark:text-slate-200 underline font-medium"
                                        >
                                            Shipping
                                        </a>
                                        {` `} infomation
                                    </p>
                                </div> */}
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="col-sm-12 empty-cart-cls text-center">
                        <h3>
                            <strong>Your Cart is Empty</strong>
                        </h3>
                        <h4>Explore more shortlist some items.</h4>
                    </div>
                )}
            </main>
        </div>
    );
};

export default CartPage;
