import Logo from 'shared/Logo/Logo';
import SocialsList1 from 'shared/SocialsList1/SocialsList1';
import { CustomLink } from 'data/types';
import React from 'react';
import { Clock, Mail, MapPin, Phone } from 'react-feather';

export interface WidgetFooterMenu {
    id: string;
    title: string;
    menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
    {
        id: '1',
        title: 'QUICK LINKS',
        menus: [
            { href: '/product-details/88c4b9f3-234b-4929-b26b-d1e16327495b', label: 'Design Your Card' },
            { href: '/about', label: 'Company' },
            { href: '/subcategory/metal-card', label: 'Shop' },
            { href: '/subcategory/pvc-card', label: 'Featured' },
            { href: '/contact', label: 'Contact Us' },
            { href: '/account', label: 'My Account' }
        ]
    },
    {
        id: '2',
        title: 'POLICIES',
        menus: [
            { href: '/terms-and-condition', label: 'Terms And Conditions' },
            // { href: '/cancellation', label: 'Cancellation Policy' },
            { href: '/privacy-policy', label: 'Privacy Policy' },
            // { href: '/reverse-policy', label: 'Reverse Pick Up Policy' },
            { href: '/return-policy', label: 'Return And Exchange Policy' },
            { href: '/shipping-policy', label: 'Shipping Policy' }
        ]
    }
];

const Footer: React.FC = () => {
    const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
        return (
            <div key={index} className="text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">{menu.title}</h2>
                <ul className="mt-5 space-y-4">
                    {menu.menus.map((item, index) => (
                        <li key={index}>
                            <a
                                key={index}
                                className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                                href={item.href}
                                // target="_blank"
                                // rel="noopener noreferrer"
                            >
                                {item.label}
                            </a>
                        </li>
                    ))}
                </ul>
            </div>
        );
    };

    const renderWidgetSupport = () => {
        return (
            <div className="text-sm">
                <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">Support</h2>
                <ul className="mt-5 space-y-4">
                    <li>
                        <div className="flex items-center space-x-4 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                            <div>
                                <MapPin size={20} />
                            </div>
                            <span className="ms-25">Ahmedabad, Gujarat, India</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center space-x-4 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                            <div>
                                <Clock size={20} />
                            </div>
                            <span className="ms-25">
                                Timing : 10.30 AM to 06.30 PM <br /> (Monday to Saturday)
                            </span>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center space-x-4 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                            <div>
                                <Phone size={20} />
                            </div>
                            <span className="ms-25">Call Us: +91 70697 91000</span>
                        </div>
                    </li>
                    <li>
                        <div className="flex items-center space-x-4 text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white">
                            <div>
                                <Mail size={20} />
                            </div>
                            <span className="ms-25">
                                Email Us:{' '}
                                <a href="mailto:support@instaacard.com" style={{ textTransform: 'none' }}>
                                    support@instaacard.com
                                </a>
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        );
    };

    return (
        <div className="nc-Footer relative py-20 lg:pt-28 lg:pb-24 border-t border-neutral-200 dark:border-neutral-700 bg-slate-100">
            <div className="container grid grid-cols-2 gap-y-10 gap-x-5 sm:gap-x-8 md:grid-cols-4 lg:grid-cols-4 lg:gap-x-10 ">
                <div className="grid grid-cols-4 gap-5 col-span-2 md:col-span-4 lg:md:col-span-1 lg:flex lg:flex-col">
                    <div className="col-span-2 md:col-span-1">
                        <Logo />
                    </div>
                    <div className="col-span-2 flex items-center md:col-span-3">
                        <SocialsList1 className="flex items-center space-x-2 lg:space-x-0 lg:flex-col lg:space-y-3 lg:items-start" />
                    </div>
                </div>
                {widgetMenus.map(renderWidgetMenuItem)}
                {renderWidgetSupport()}
            </div>
        </div>
    );
};

export default Footer;
