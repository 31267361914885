/* eslint-disable no-unused-vars */
import React, { FC, useState } from 'react';
import facebookSvg from 'images/Facebook.svg';
import twitterSvg from 'images/Twitter.svg';
import googleSvg from 'images/Google.svg';
import { Helmet } from 'react-helmet-async';
import Input from 'shared/Input/Input';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Link, useNavigate } from 'react-router-dom';
import axios from '../../service/axios';
import { handleLogin } from '../../reduxStore/store/auth';
import { useDispatch } from 'react-redux';
import { useForm, Resolver, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

export interface PageSignUpProps {
    className?: string;
}

type FormValues = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    confirmPassword: string;
    mobile: number;
};

const loginSocials = [
    {
        name: 'Continue with Facebook',
        href: '#',
        icon: facebookSvg
    },
    {
        name: 'Continue with Twitter',
        href: '#',
        icon: twitterSvg
    },
    {
        name: 'Continue with Google',
        href: '#',
        icon: googleSvg
    }
];

const PageSignUp: FC<PageSignUpProps> = ({ className = '' }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [buttonStatus, setButtonStatus] = useState<boolean>(false);

    const { register, handleSubmit } = useForm<FormValues>();
    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        try {
            setButtonStatus(true);
            const formData = {
                firstName: data.firstName,
                lastName: data.lastName,
                email: data.email,
                confirmPassword: data.confirmPassword,
                mobile: Number(data.mobile),
                password: data.password
            };
            const response = await axios.post('/customer', formData);
            if (response?.data) {
                // dispatch(handleLogin(response.data));
                toast.success('Account created successfully. Please login to place order.');

                navigate('/login');
            }
            return setButtonStatus(false);
        } catch (error: any) {
            setButtonStatus(false);
            return toast.error(error?.response?.data?.message || 'Something went wrong!');
        }
    };
    return (
        <div className={`nc-PageSignUp  ${className}`} data-nc-id="PageSignUp">
            <Helmet>
                <title>Sign up || Instaacard</title>
            </Helmet>
            <div className="container mb-24 lg:mb-32">
                <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Signup
                </h2>
                <div className="max-w-md mx-auto space-y-6 ">
                    {/* <div className="grid gap-3">
            {loginSocials.map((item, index) => (
              <a
                key={index}
                href={item.href}
                className=" flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"
              >
                <img
                  className="flex-shrink-0"
                  src={item.icon}
                  alt={item.name}
                />
                <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                  {item.name}
                </h3>
              </a>
            ))}
          </div> */}
                    {/* OR */}
                    {/* <div className="relative text-center">
            <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
              OR
            </span>
            <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
          </div> */}
                    {/* FORM */}
                    <form
                        className="grid grid-cols-1 gap-3"
                        action="/login"
                        // method="post"
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">First Name</span>
                            <Input type="text" className="mt-1" {...register('firstName')} />
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Last Name</span>
                            <Input type="text" className="mt-1" {...register('lastName')} />
                        </label>
                        <label className="block">
                            <span className="text-neutral-800 dark:text-neutral-200">Email address</span>
                            <Input type="email" placeholder="example@example.com" className="mt-1" {...register('email')} />
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Password</span>
                            <Input type="password" className="mt-1" {...register('password')} />
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                                Confirm Password
                            </span>
                            <Input type="password" className="mt-1" {...register('confirmPassword')} />
                        </label>
                        <label className="block">
                            <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">Mobile</span>
                            <Input type="number" className="mt-1" {...register('mobile')} />
                        </label>
                        <ButtonPrimary type="submit" disabled={buttonStatus}>
                            Continue
                        </ButtonPrimary>
                    </form>

                    {/* ==== */}
                    <span className="block text-center text-neutral-700 dark:text-neutral-300">
                        Already have an account? {` `}
                        <Link className="text-green-600" to="/login">
                            Sign in
                        </Link>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default PageSignUp;
