import React, { FC } from 'react';
import NcImage from 'shared/NcImage/NcImage';
import rightImg from 'images/home/Kids-banner.png';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Logo from 'shared/Logo/Logo';
import backgroundLineSvg from 'images/Moon.svg';

export interface SectionPromo2Props {
    className?: string;
}

const SectionPromo2: FC<SectionPromo2Props> = ({ className = 'lg:pt-10' }) => {
    return (
        <div className={`nc-SectionPromo2 ${className}`}>
            <div className="relative flex flex-col lg:flex-row lg:justify-end bg-yellow-50 dark:bg-slate-800 rounded-2xl sm:rounded-[40px] p-4 pb-0 sm:p-5 sm:pb-0 lg:p-24">
                <div className="absolute inset-0">
                    <img className="absolute w-full h-full object-contain dark:opacity-5" src={backgroundLineSvg} alt="backgroundLineSvg" />
                </div>

                <div className="lg:w-[45%] max-w-lg relative">
                    <Logo className="w-96" />
                    <h2 className="font-semibold text-3xl sm:text-4xl xl:text-3xl 2xl:text-3xl mt-6 sm:mt-10 !leading-[1.13] tracking-tight">
                        Networking made effortless <br />
                        with INSTAACARD.
                    </h2>
                    <span className="block mt-6 text-slate-500 dark:text-slate-400">
                        Tailor your Instaacard, the NFC-based smart business card, to reflect your unique identity. Personalize it with your
                        branding, contact details, and key information. Instantly connect with others by simply tapping phones or scanning
                        QR codes, revolutionizing how you network and make lasting impressions.{' '}
                    </span>
                    <div className="flex space-x-2 sm:space-x-5 mt-6 sm:mt-12">
                        <ButtonPrimary href="/subcategory/pvc-card" className="dark:bg-slate-200 dark:text-slate-900">
                            Discover more
                        </ButtonPrimary>
                    </div>
                </div>

                <NcImage
                    containerClassName="relative block lg:absolute lg:left-0 lg:bottom-0 mt-10 lg:mt-0 max-w-xl lg:max-w-[calc(55%-40px)]"
                    src={rightImg}
                />
            </div>
        </div>
    );
};

export default SectionPromo2;
