import React, { FC } from 'react';
import { MinusIcon, PlusIcon } from '@heroicons/react/24/solid';

export interface NcInputNumberProps {
    className?: string;
    min?: number;
    max?: number;
    label?: string;
    desc?: string;
    quantity: number;
    onChange?: (newQuantity: number) => void;
}

const NcInputNumber: FC<NcInputNumberProps> = ({ className = 'w-full', min = 1, max = 99, onChange, label, desc, quantity }) => {
    const handleClickDecrement = () => {
        if (min >= quantity) return;
        onChange && onChange(quantity - 1);
    };

    const handleClickIncrement = () => {
        if (max && max <= quantity) return;
        onChange && onChange(quantity + 1);
    };

    const renderLabel = () => {
        return (
            <div className="flex flex-col">
                <span className="font-medium text-neutral-800 dark:text-neutral-200">{label}</span>
                {desc && <span className="text-xs text-neutral-500 dark:text-neutral-400 font-normal">{desc}</span>}
            </div>
        );
    };

    return (
        <div className={`nc-NcInputNumber flex items-center justify-between space-x-5 ${className}`}>
            {label && renderLabel()}

            <div className={`nc-NcInputNumber__content flex items-center justify-between w-[104px] sm:w-28`}>
                <button
                    className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                    type="button"
                    onClick={handleClickDecrement}
                    disabled={min >= quantity}
                >
                    <MinusIcon className="w-4 h-4" />
                </button>
                <span className="select-none block flex-1 text-center leading-none">{quantity}</span>
                <button
                    className="w-8 h-8 rounded-full flex items-center justify-center border border-neutral-400 dark:border-neutral-500 bg-white dark:bg-neutral-900 focus:outline-none hover:border-neutral-700 dark:hover:border-neutral-400 disabled:hover:border-neutral-400 dark:disabled:hover:border-neutral-500 disabled:opacity-50 disabled:cursor-default"
                    type="button"
                    onClick={handleClickIncrement}
                    disabled={max ? max <= quantity : false}
                >
                    <PlusIcon className="w-4 h-4" />
                </button>
            </div>
        </div>
    );
};

export default NcInputNumber;
