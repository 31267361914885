// ** Reducers Imports
import auth from './store/auth';
import crudApis from './crud';

const rootReducer = {
    auth,
    ...crudApis
};

export default rootReducer;
