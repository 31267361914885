/* eslint-disable no-unused-vars */

// Order Status enum
export const OrderStatus = {
    Pending: '0',
    Confirmed: '1',
    Processing: '2',
    'In Transit': '3',
    'Out For Delivery': '4',
    Rejected: '5',
    Cancelled: '6',
    Delivered: '7'
};
