/* eslint-disable no-unused-vars */
import React, { FC } from 'react';

// import Explore5 from 'images/instaacard/home/start-exploring/explore-5.png';

import { Smartphone, UserCheck, Wifi } from 'react-feather';

export interface FeatureProps {
    className?: string;
    data?: (typeof DEMO_DATA)[0][];
}

const DEMO_DATA = [
    {
        id: 1,
        img: <Wifi size={40} />,
        title: 'Contactless Sharing',
        desc: 'Just a tap shares your Information'
    },
    {
        id: 2,
        img: <UserCheck size={40} />,
        title: 'No Recurring cost',
        desc: ' Enjoy Lifetime updates at no additional cost'
    },
    {
        id: 3,
        img: <Smartphone size={40} />,
        title: 'No App Required ',
        desc: 'Automatically Works With NFC Enabled Smartphones'
    },
    {
        id: 4,
        img: <UserCheck size={40} />,
        title: 'ECO- FRIENDLY',
        desc: 'Used from sustainable materials with NFC for better networking'
    }
];

const FeatureCards: FC<FeatureProps> = ({ className = '', data = DEMO_DATA }) => {
    return (
        <div className={`nc-SectionHowItWork ${className}`} data-nc-id="SectionHowItWork">
            <h2 className="font-semibold text-3xl sm:text-3xl xl:text-4xl mt-6 sm:mt-10 !leading-[1.2] tracking-tight text-center pb-8">
                Discover Instaacard NFC
            </h2>
            <div className="relative grid sm:grid-cols-1 lg:grid-cols-4 border-t gap-1 border-b border-slate-200">
                {/* <div className="relative grid sm:grid-cols-1 lg:grid-cols-4 gap-10 sm:gap-16 xl:gap-20 border-t border-b border-slate-200 py-10 "> */}
                {data.map((item: (typeof DEMO_DATA)[number], index: number) => (
                    <div
                        key={item.id}
                        className="relative flex flex-col items-center mx-auto w-full bg-icBlue-100 hover:bg-icYellow-100 py-10 text-white hover:text-icBlue-100"
                    >
                        <div className={`nc-NcImage mb-2 sm:mb-5 max-w-[140px] mx-auto rounded-3xl`}>
                            {/* <div className={`nc-NcImage mb-2 sm:mb-5 max-w-[140px] mx-auto rounded-3xl hover:text-icYellow-100`}> */}
                            {item.img}
                        </div>
                        <div className="text-center space-y-2 align-baseline">
                            <h3 className="text-base font-semibold">{item.title}</h3>
                            <span className="block dark:text-slate-400 text-sm leading-6">{item.desc}</span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default FeatureCards;
