import Label from 'components/Label/Label';
import React, { FC, useState } from 'react';
import Select from 'react-select';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Input from 'shared/Input/Input';
import Checkbox from 'shared/Checkbox/Checkbox';
// import Select from "shared/Select/Select";
import { useDispatch, useSelector } from 'react-redux';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import { AppDispatch, RootState } from 'reduxStore/store';
import { Form } from 'reactstrap';
import { getAllStates } from '../../reduxStore/store/order/index';
import classnames from 'classnames';

interface Props {
    isActive: boolean;
    checkSameAddress: any;
    setCheckIsSameAddress: any;
    onCloseActive: () => void;
    onOpenActive: () => void;
    // Payment: any;
    onFormSubmit: any;
}

const ShippingAddress: FC<Props> = ({ isActive, checkSameAddress, setCheckIsSameAddress, onCloseActive, onFormSubmit, onOpenActive }) => {
    const RenderShippingAddress: FC = () => {
        let customerAddresses = useSelector((state: RootState) => state?.customerOrderSlice?.customerAddress);

        // // eslint-disable-next-line no-unused-vars
        // const shippingAddress = secureGet('customerShippingAddress');

        customerAddresses = [
            ...customerAddresses,
            {
                value: null,
                address1: null,
                address2: null,
                zipCode: null,
                State: null,
                cityName: null,
                countryName: null,
                stateName: null,
                label: String('Add New Address')
            }
        ];

        const statesData = useSelector((state: RootState) => state?.customerOrderSlice?.states);
        const dispatch = useDispatch<AppDispatch>();
        // eslint-disable-next-line no-unused-vars
        const [formSubmitted, setFormSubmitted] = useState(false);

        const handleCheckIsSameAddress = () => {
            if (checkSameAddress === true) {
                setCheckIsSameAddress(false);
            } else {
                setCheckIsSameAddress(true);
            }
            reset({
                customerAddressId: null,
                address1: null,
                address2: null,
                countryName: null,
                stateName: null,
                cityName: null,
                zipCodeL: null
            });
        };
        const {
            register,
            handleSubmit,
            // eslint-disable-next-line no-unused-vars
            formState: { errors },
            control,
            reset,
            setValue,
            trigger
        } = useForm<any>({
            mode: 'onChange'
            // resolver: yupResolver(validationScheme),
        });

        const { ref: refAddress1, ...restAddress1 } = register('address1');
        const { ref: refAddress2, ...restAddress2 } = register('address2');
        const { ref: refCountryName, ...restCountryName } = register('countryName');
        // eslint-disable-next-line no-unused-vars
        const { ref: refStateName, ...restStateName } = register('stateName');
        const { ref: refCityName, ...restCityName } = register('cityName');
        const { ref: refZipCode, ...restZipCode } = register('zipCode');

        const updateBillingAddressFields = (addressId: any) => {
            if (addressId) {
                let selectedAddress = customerAddresses.find((i) => i.value == addressId);

                setValue('address1', selectedAddress?.address1);
                setValue('address2', selectedAddress?.address2);
                setValue('cityName', selectedAddress?.cityName);
                setValue('stateName', {
                    label: selectedAddress?.State?.name,
                    value: selectedAddress?.State?.id
                });
                setValue('countryName', selectedAddress?.countryName);
                setValue('zipCode', selectedAddress?.zipCode);
                trigger();
            } else {
                setValue('address1', null);
                setValue('address2', null);
                setValue('cityName', null);
                setValue('stateName', null);
                setValue('countryName', null);
                setValue('zipCode', null);
            }
        };

        const handleClickOnBilling = () => {
            setValue(
                'customerAddressId',
                customerAddresses.find((i) => i.value === null)
            );
        };

        const onSubmit: SubmitHandler<any> = (data) => {
            if (data?.customerAddressId) {
                let currentAddress = {
                    value: data?.customerAddressId?.value,
                    label: data?.customerAddressId?.label,
                    address1: data?.address1,
                    address2: data?.address2,
                    cityName: data?.address2,
                    stateName: data?.stateName,
                    countryName: data?.countryName,
                    zipCode: data?.zipCode
                };

                onFormSubmit(checkSameAddress, currentAddress);
                onCloseActive();
            } else {
                checkSameAddress == true && onFormSubmit(checkSameAddress);
                onCloseActive();
            }
        };

        // eslint-disable-next-line no-unused-vars
        const getAllStatesData = () => {
            dispatch(getAllStates());
        };

        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-xl ">
                <div className="p-6 flex flex-col sm:flex-row items-start">
                    <span className="hidden sm:block">
                        <svg
                            className="w-6 h-6 text-slate-700 dark:text-slate-400 mt-0.5"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M12.1401 15.0701V13.11C12.1401 10.59 14.1801 8.54004 16.7101 8.54004H18.6701"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M5.62012 8.55005H7.58014C10.1001 8.55005 12.1501 10.59 12.1501 13.12V13.7701V17.25"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M7.14008 6.75L5.34009 8.55L7.14008 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M16.8601 6.75L18.6601 8.55L16.8601 10.35"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                            <path
                                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                                stroke="currentColor"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                            />
                        </svg>
                    </span>

                    <div className="sm:ml-8">
                        <h3 className=" text-slate-700 dark:text-slate-300 flex ">
                            <span className="uppercase">Billing Address</span>
                            <svg
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth="2.5"
                                stroke="currentColor"
                                className="w-5 h-5 ml-3 text-slate-900 dark:text-slate-100"
                            >
                                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                            </svg>
                        </h3>
                        {checkSameAddress && <h6 className="font-bold text-17 text-gray-700">Same as Shipping Address.</h6>}
                    </div>
                    <ButtonSecondary
                        sizeClass="py-2 px-4 "
                        fontSize="text-sm font-medium"
                        className="bg-slate-50 dark:bg-slate-800 mt-5 sm:mt-0 sm:ml-auto !rounded-lg"
                        onClick={() => {
                            onOpenActive();
                            setFormSubmitted(false);
                        }}
                    >
                        Change
                    </ButtonSecondary>
                </div>

                <div
                    className={`border-t border-slate-200 dark:border-slate-700 px-6 py-7 space-y-4 sm:space-y-6 ${
                        isActive ? 'block' : 'hidden'
                    }`}
                >
                    <div>
                        <div
                            className="flex"
                            onChange={() => {
                                handleCheckIsSameAddress();
                            }}
                        >
                            <Checkbox className="form-check-control" name="same as Shipping Address" defaultChecked={checkSameAddress} />{' '}
                            &nbsp;
                            <label> Same as Shipping Address</label>
                        </div>
                    </div>
                    {checkSameAddress === false ? (
                        <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">Addresses</Label>
                                    <Controller
                                        control={control}
                                        name="customerAddressId"
                                        render={({ field: { value, onChange } }) => (
                                            <Select
                                                // isClearable
                                                value={value}
                                                classNamePrefix="select"
                                                placeholder={'Select Address'}
                                                options={customerAddresses}
                                                onChange={(val: any) => {
                                                    // Update the form state
                                                    onChange(val);
                                                    val && val?.value
                                                        ? updateBillingAddressFields(val?.value)
                                                        : updateBillingAddressFields(null);
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="sm:flex space-y-4 sm:space-y-0 sm:space-x-3">
                                <div className="flex-1">
                                    <Label className="text-sm">Flat, House no, Building, Company, Apartment*</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        // id="shippingAddress1"
                                        // name="shippingAddress1"
                                        placeholder="Flat, House no., Building, Company, Apartment"
                                        // invalid={errors?.shippingAddress1 && true}
                                        {...restAddress1}
                                        ref={refAddress1}
                                        onChange={(e) => {
                                            handleClickOnBilling();
                                        }}
                                    />
                                </div>
                                <div className="sm:w-1/3">
                                    <Label className="text-sm">Area, Street, Sector, Village </Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="shippingAddress2"
                                        // name="shippingAddress2"
                                        // invalid={errors?.shippingAddress2 && true}
                                        placeholder="Area, Street, Sector, Village"
                                        {...restAddress2}
                                        ref={refAddress2}
                                        onChange={(e) => {
                                            handleClickOnBilling();
                                        }}
                                    />
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">City</Label>
                                    <Input
                                        type="text"
                                        className="form-control"
                                        id="cityName"
                                        // name="shippingCityName"
                                        // invalid={errors?.shippingCityName && true}
                                        placeholder="City"
                                        {...restCityName}
                                        ref={refCityName}
                                        onChange={(e) => {
                                            handleClickOnBilling();
                                        }}
                                    />
                                </div>
                                <div>
                                    <Label className="text-sm">State</Label>
                                    <Controller
                                        // id="shippingStateName"
                                        control={control}
                                        name="stateName"
                                        render={({ field }) => (
                                            <Select
                                                isClearable
                                                classNamePrefix="select"
                                                placeholder={'Select State'}
                                                options={statesData}
                                                // theme={selectThemeColors}
                                                className={classnames('react-select', {
                                                    // "is-invalid":
                                                    //   errors?.shippingStateName?.message ||
                                                    //   errors?.shippingStateName?.label?.message ||
                                                    //   errors?.shippingStateName?.value?.message,
                                                })}
                                                {...field}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    handleClickOnBilling();
                                                }}
                                            />
                                        )}
                                    />
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-3">
                                <div>
                                    <Label className="text-sm">Country</Label>
                                    <Input
                                        // className="mt-1.5"
                                        type="text"
                                        className="form-control"
                                        id="shippingCountryName"
                                        // invalid={errors?.shippingCountryName && true}
                                        // name="shippingCountryName"
                                        placeholder="Country Name"
                                        {...restCountryName}
                                        ref={refCountryName}
                                        onChange={(e) => {
                                            handleClickOnBilling();
                                        }}
                                    />
                                </div>
                                <div>
                                    <Label className="text-sm">Zipcode</Label>
                                    <Input
                                        // className="mt-1.5"
                                        type="tel"
                                        className="form-control"
                                        id="shippingZipCode"
                                        // name="shippingZipCode"
                                        // invalid={errors?.shippingZipCode && true}
                                        placeholder="zip Code"
                                        maxLength={6}
                                        pattern="[0-9]{6,}"
                                        {...restZipCode}
                                        ref={refZipCode}
                                        onChange={(e) => {
                                            handleClickOnBilling();
                                        }}
                                    />
                                </div>
                            </div>

                            {/* ============ */}
                            <div className="flex flex-col sm:flex-row pt-6">
                                <ButtonPrimary
                                    className="sm:!px-7 shadow-none"
                                    type="submit"
                                    // onClick={() => onCloseActive()}
                                >
                                    Continue
                                </ButtonPrimary>
                                {/* <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={onCloseActive}
                >
                Cancel
              </ButtonSecondary> */}
                            </div>
                        </Form>
                    ) : (
                        <Form className="grid grid-cols-1 gap-6" action="#" method="post" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex flex-col sm:flex-row pt-6">
                                <ButtonPrimary
                                    className="sm:!px-7 shadow-none"
                                    type="submit"
                                    // onClick={() => onCloseActive()}
                                >
                                    Continue
                                </ButtonPrimary>
                                {/* <ButtonSecondary
                className="mt-3 sm:mt-0 sm:ml-3"
                onClick={onCloseActive}
                >
                Cancel
              </ButtonSecondary> */}
                            </div>
                        </Form>
                    )}
                </div>
            </div>
        );
    };
    return <RenderShippingAddress />;
};

export default ShippingAddress;
