/* eslint-disable no-unused-vars */
import React from 'react';
import { Link } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import Comment from 'shared/Comment/Comment';
import NcImage from 'shared/NcImage/NcImage';
import Textarea from 'shared/Textarea/Textarea';
import { Helmet } from 'react-helmet-async';
import { _getImgRd, _getPersonNameRd, _getTitleRd } from 'contains/fakeData';
import Tag from 'shared/Tag/Tag';

const TermsPage = () => {
    const renderHeader = () => {
        return (
            <header className="container rounded-xl mb-5">
                <div className="max-w-screen-md mx-auto space-y-5">
                    {/* <Badge href="##" color="purple" name="Traveler" /> */}
                    <h1
                        className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
                        title="Quiet ingenuity: 120,000 lunches and counting"
                    >
                        Terms and Condition
                    </h1>
                    <p>
                        These Terms and any accompanying documents outline the terms and conditions governing your use of our website and
                        constitute the entire agreement between <strong>Instaacard</strong> and you, the user of our services and related
                        products. The terms apply to all aspects of users' interaction with our site, including browsing, accessing, using,
                        framing, and linking to our platform ("Site"). Anyone engaging in such activities is referred to as a "User" under
                        these Terms and may be addressed as "Customer," "User," or "You."
                    </p>
                    <p>
                        By accessing, browsing, using, framing, or linking to our site, you acknowledge that you have read, understood, and
                        agree to be legally bound by the provisions of these Terms. Your use of this website indicates your acceptance of
                        all the terms and conditions herein. If you do not agree to these terms or are dissatisfied in any way, please
                        refrain from using this website.
                    </p>
                    <p>
                        &nbsp;<strong>Updates to These Terms:</strong>
                    </p>
                    <p>
                        These Terms are subject to change, and we may periodically make important revisions. Any changes will be posted on
                        the Site, and all activities on the site are subject to the updated Terms.
                    </p>
                    <p>
                        &nbsp;<strong>Content Ownership and Copyright:</strong>
                    </p>
                    <p>
                        All rights to the Site, including content, information, and other materials, are proprietary to{' '}
                        <strong>Instaacard</strong> and protected by copyright laws. Trademarks and logos cited on the site belong to their
                        respective owners.
                    </p>
                    <p>
                        &nbsp;<strong>Restrictions on Use of the Site and its Content:</strong>
                    </p>
                    <p>
                        Subject to compliance with these Terms, we grant you a non-exclusive, non-transferable license to access and use the
                        materials on the site for personal, non-commercial use. Any other use, including redistribution, copying,
                        modification, reproduction, and display, is prohibited without our prior express written consent.
                    </p>
                    <p>
                        &nbsp;<strong>Intellectual Property Rights and Trademarks:</strong>
                    </p>
                    <p>
                        <strong>Instaacard</strong> reserves all Intellectual Property Rights in its services, system, and materials. The
                        service name, trademarks, and logos used on the site are protected by intellectual property laws.
                    </p>
                    <p>
                        &nbsp;<strong>Product Descriptions, Availability, Pricing, and Tax:</strong>
                    </p>
                    <p>
                        Product descriptions aim for accuracy, and we make efforts to ensure availability. Prices may vary, and applicable
                        taxes will be added to orders.
                    </p>
                    <p>
                        &nbsp;<strong>Payment and Delivery of Order:</strong>
                    </p>
                    <p>
                        Payment must be received in advance, and we will confirm and ship orders to the specified delivery address. Delivery
                        is subject to agreed-upon terms.
                    </p>
                    <p>
                        &nbsp;<strong>Cancellation:</strong>
                    </p>
                    <p>Orders can be canceled before the product leaves our facility and is handed over to the shipping company.</p>
                    <p>
                        &nbsp;<strong>Your Account, Password, and Security:</strong>
                    </p>
                    <p>
                        Registration may be required, and you are responsible for maintaining the confidentiality of your account. We
                        reserve the right to refuse service and terminate accounts at our discretion.
                    </p>
                    <p>
                        &nbsp;<strong>Termination of Your Usage:</strong>
                    </p>
                    <p>
                        Either party may terminate these Terms. We may terminate or suspend access to our site for disruptive behavior or
                        violation of these Terms.
                    </p>
                    <p>
                        &nbsp;<strong>User-Generated Comments, Testimonials, Feedback, and Other Submissions:</strong>
                    </p>
                    <p>
                        User-generated content is considered non-confidential, and by submitting, you grant us a license to use such
                        content.
                    </p>
                    <p>
                        &nbsp;<strong>Copyright Infringement Notification:</strong>
                    </p>
                    <p>We respect intellectual property rights and have procedures for addressing copyright infringement.</p>
                    <p>
                        <strong>Third-Party Links:</strong>
                    </p>
                    <p>We are not responsible for the content of linked sites, and their use is at your own risk.</p>
                    <p>
                        <strong>Disclaimers:</strong>
                    </p>
                    <p>
                        The site is provided "as-is," and we disclaim all representations and warranties regarding its operation and
                        content.
                    </p>
                    <p>
                        &nbsp;<strong>Indemnity:</strong>
                    </p>
                    <p>
                        You agree to indemnify and hold us harmless from any claims, losses, or demands arising from your breach of these
                        Terms.
                    </p>
                    <p>
                        &nbsp;<strong>Limitation of Liability:</strong>
                    </p>
                    <p>To the extent permitted by law, we shall not be liable for any damage arising from your use of the site.</p>
                    <p>
                        &nbsp;<strong>General:</strong>
                    </p>
                    <p>
                        These Terms are governed by the laws of India. If any provision is deemed unlawful, the remainder of the Terms will
                        remain in effect.
                    </p>
                    <p>
                        &nbsp;<strong>Entire Agreement:</strong>
                    </p>
                    <p>These Terms constitute the entire agreement between you and us, superseding all prior agreements.</p>
                    <p>
                        &nbsp;<strong>Contacting Us:</strong>
                    </p>
                    <p>For complaints or further information, contact us at:</p>
                    <p>Email: help@instaacard.com</p>
                    <p>
                        <strong>Trumpcard Initiatives Worldwide Pvt. Ltd.</strong>
                    </p>
                    <p>304, Sursarthi Complex,</p>
                    <p>Near Ambawadi Circle, Ambawadi,</p>
                    <p>Ahmedabad, Gujarat,</p>
                    <p>380006 INDIA</p>{' '}
                </div>
            </header>
        );
    };

    const renderContent = () => {
        return (
            <div id="single-entry-content" className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert mb-5">
                <ul>
                    <li>You can call us on 079-40024244 or mail us at info@instaaprint.com within 10 days from the date of delivery.</li>
                    <li>Please mention your order number in subject line to help us quickly process your returns /exchange.</li>
                    <li>Please ensure that returned merchandise is unused and undamaged and in a saleable condition.</li>
                    <li>Please maintain original packing.</li>
                    <li>Please ensure that returned item is packed securely and safely to prevent any loss or damage during transit.</li>
                    <li>The items for reverse pick up will be picked up within 5 working days from the date of request.</li>
                    <li>After receipt of item, your request for exchange /returns would be processed.</li>
                    <li>
                        In the case of returns bank normally takes 8-10 business days to process a refund one it has been initiated from our
                        end.
                    </li>
                    <li>In the case of exchange, your new order will be dispatched to you within 3-5 working business days.</li>
                </ul>
                <p className="mt-1">
                    <strong>Note: </strong>Some of pin codes are not feasible for reverse pick up, in such cases the customer will need to
                    send it back to us. We do not offer reverse facility outside India.
                </p>
            </div>
        );
    };

    return (
        <div className="nc-PageSingle pt-8 lg:pt-16 ">
            <Helmet>
                <title>Terms and Condition | Instaacard</title>
            </Helmet>
            {renderHeader()}

            {/* <div className="nc-SingleContent container space-y-10">{renderContent()}</div> */}
        </div>
    );
};

export default TermsPage;
