/* eslint-disable no-unused-vars */
import React, { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import AddressCard from 'containers/AccountPage/AccountAddress/components/AddressCard';
import LoadingAddress from 'containers/AccountPage/AccountAddress/components/LoadingAddress';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'reduxStore/store';
import { getCustomerAddressAll } from '../../../reduxStore/store/address';
import CommonLayout from '../CommonLayout';
import { Address } from 'reduxStore/store/order/class';
import AddressModal from './components/AddressModal';
import { getAllStates } from 'reduxStore/store/address';

export interface PageProps {
    className?: string;
}
const AccountAddress: FC<PageProps> = ({ className = '' }) => {
    const dispatch = useDispatch<AppDispatch>();
    const [isLoading, setIsLoading] = useState(true);

    const customerAddress = useSelector((state: RootState) => state?.addressSlice.customerAddress);

    const [showModal, setShowModal] = React.useState(false);
    // eslint-disable-next-line no-unused-vars
    const [editRow, setEditRow] = React.useState<Address | null>(null);

    const handleModal = (row: Address | null) => {
        if (row?.id) {
            setEditRow(row);
        } else {
            setEditRow(null);
        }
        setShowModal(!showModal);
    };

    useEffect(() => {
        dispatch(getCustomerAddressAll())
            .unwrap()
            .then((res) => {
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
                // console.log(err.message);
            });
        dispatch(getAllStates());
    }, []);

    return (
        <CommonLayout>
            <div className="space-y-10 sm:space-y-12">
                {/* HEADING */}
                <h2 className="text-2xl sm:text-3xl font-semibold">Address</h2>
                <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 gap-x-6 gap-y-6 mt-8 lg:mt-10">
                    {!isLoading &&
                        customerAddress.map((item, index) => (
                            <AddressCard data={item} key={index} index={index} handleModal={handleModal} />
                        ))}
                    {isLoading && <LoadingAddress />}
                </div>
            </div>
            <AddressModal data={editRow} show={showModal} handleModal={handleModal} />
        </CommonLayout>
    );
};

export default AccountAddress;
