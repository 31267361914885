import Label from 'components/Label/Label';
import React, { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
// import Select from 'shared/Select/Select';
// import Textarea from 'shared/Textarea/Textarea';
import CommonLayout from './CommonLayout';
import { Helmet } from 'react-helmet-async';
// import { avatarImgs } from 'contains/fakeData';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useForm } from 'react-hook-form';
import { FormFeedback } from 'reactstrap';
import { AppDispatch, RootState } from 'reduxStore/store';
import axios from 'service/axios';
import { toast } from 'react-toastify';
import { verifyToken } from 'reduxStore/store/auth';

export interface AccountPageProps {
    className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = '' }) => {
    const dispatch = useDispatch<AppDispatch>();

    const authData = useSelector((state: RootState) => state?.auth);
    const [buttonStatus, setButtonStatus] = useState<boolean>(false);

    // validation Object
    let validationObj = {
        firstName: yup.string().required('First Name is required.'),
        lastName: yup.string().required('Last Name is required.'),
        email: yup.string().required('Email is required.').email('Must be a valid email.'),
        mobile: yup
            .number()
            .required('Mobile is required.')
            .typeError('Mobile must be a number.')
            .test('len', 'Number must be at least 10 digits', (val) => val.toString().length >= 10)
            .nullable()
    };
    const validationScheme = yup.object().shape(validationObj);

    // Hook Form
    const {
        register,
        reset,
        handleSubmit,

        formState: { errors }
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(validationScheme)
    });
    const createProfile = async (data: any) => {
        return await axios.put('/customer', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    };

    const onSubmit = async (formData: any) => {
        setButtonStatus(true);

        createProfile(formData)
            .then((res) => {
                setButtonStatus(false);

                toast.success('Account updated successfully.');
                dispatch(verifyToken());
            })
            .catch((err) => {
                setButtonStatus(false);

                toast.error(err?.response?.data?.message || 'Something went wrong!');
            });
    };

    useEffect(() => {
        reset({
            firstName: authData?.userData?.customer?.firstName || '',
            lastName: authData?.userData?.customer?.lastName || '',
            email: authData?.userData?.email || '',
            mobile: authData?.userData?.customer?.mobile || ''
        });
    }, [authData]);

    return (
        <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
            <Helmet>
                <title>Account || Instaacard ecommerce React Template</title>
            </Helmet>
            <CommonLayout>
                <div className="space-y-10 sm:space-y-12">
                    {/* HEADING */}
                    <h2 className="text-2xl sm:text-3xl font-semibold">Account infomation</h2>
                    <div className="flex flex-col md:flex-row">
                        <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
                            <div className="flex-grow mt-10 md:mt-0  max-w-3xl space-y-6">
                                <div>
                                    <Label>First Name</Label>
                                    <Input className="mt-1.5" {...register('firstName')} />
                                    {errors && errors.firstName && <FormFeedback>{errors.firstName.message}</FormFeedback>}
                                </div>
                                <div>
                                    <Label>Last Name</Label>
                                    <Input className="mt-1.5" {...register('lastName')} />
                                    {errors && errors.lastName && <FormFeedback>{errors.lastName.message}</FormFeedback>}
                                </div>

                                <div>
                                    <Label>Email</Label>
                                    <div className="mt-1.5 flex">
                                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                            <i className="text-2xl las la-envelope"></i>
                                        </span>
                                        <Input
                                            className="!rounded-l-none"
                                            placeholder="example@email.com"
                                            defaultValue={authData?.userData?.email}
                                        />
                                    </div>
                                </div>

                                {/* ---- */}
                                <div>
                                    <Label>Phone number</Label>
                                    <div className="mt-1.5 flex">
                                        <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                                            <i className="text-2xl las la-phone-volume"></i>
                                        </span>
                                        <Input className="!rounded-l-none" defaultValue={authData?.userData?.customer?.mobile} />
                                    </div>
                                </div>
                                {/* ---- */}

                                <div className="pt-2">
                                    <ButtonPrimary disabled={buttonStatus} type="submit">
                                        Update account
                                    </ButtonPrimary>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </CommonLayout>
        </div>
    );
};

export default AccountPage;
