import userSlice from './store/user/index';
import productSlice from './store/products/index';
import productSliderSlice from './store/productSliderSlice';
import CartSlice from './store/cart/index';
import NavProductSlice from './store/NavProductSlice';
import customerOrderSlice from './store/order/index';
import addressSlice from './store/address';

const crudApis = {
    userSlice,
    productSlice,
    productSliderSlice,
    NavProductSlice,
    CartSlice,
    customerOrderSlice,
    addressSlice
};

export default crudApis;
