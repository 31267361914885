import React, { FC } from 'react';
import { Address } from '../../../../reduxStore/store/order/class';
import { Trash2, Edit } from 'react-feather';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'reduxStore/store';
import { deleteCustomerAddress } from 'reduxStore/store/address';
// import AddressModal from './AddressModal';

export interface ProductCardProps {
    className?: string;
    data: Address;
    index: number;
    handleModal: (data: Address | null) => void;
}

const AddressCard: FC<ProductCardProps> = ({ className = '', data, index, handleModal }) => {
    const dispatch = useDispatch<AppDispatch>();

    return (
        <div className="border border-slate-200 dark:border-slate-700 rounded-xl overflow-hidden z-0">
            <div className="flex-col sm:flex-row items-start p-1 py-4">
                <div>
                    <div className="my-auto mx-2 border-b h-20 flex justify-between">
                        <h3 className="flex-1 mb-3 text-slate-700 dark:text-slate-300 text-xl font-semibold pt-2">
                            <span className="uppercase tracking-tight ">Address - {index + 1}</span>
                        </h3>
                        <div className="flex-2 items-center justify-end text-slate-700 dark:text-slate-100">
                            <button
                                className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    handleModal(data);
                                }}
                            >
                                <Edit color="orange" />
                            </button>
                        </div>
                        <div className="flex-2 items-center justify-end text-slate-700 dark:text-slate-100">
                            <button
                                className="hidden lg:flex w-10 h-10 sm:w-12 sm:h-12 rounded-full text-slate-700 dark:text-slate-300 hover:bg-slate-100 dark:hover:bg-slate-800 focus:outline-none items-center justify-center"
                                onClick={(e) => {
                                    e.preventDefault();
                                    if (data.id) {
                                        dispatch(deleteCustomerAddress({ id: data?.id }));
                                    }
                                }}
                            >
                                <Trash2 color="red" />
                            </button>
                        </div>
                    </div>
                    <div className="mx-2 mt-2">
                        <div>
                            <h6>
                                {data?.address1}
                                {','} {data?.address2}
                                {','}
                                {data?.cityName}
                                {','}
                                {data?.stateName}
                                {','}
                            </h6>
                            <h6>
                                {data?.countryName}
                                {'-'} {data?.zipCode}
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressCard;
