// ** Redux Imports
import { createSlice, createAsyncThunk, Slice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-unused-vars
import queryString from 'query-string';
import { Product } from './class';
// ** API Imports
import axios from '../../../service/axios';

export const getProduct = createAsyncThunk('getProductAllData/getProduct', async (data: {}) => {
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get('/product?' + data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});
export const getProductById = createAsyncThunk('getProductAllData/getProductById', async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get(`/product/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});
export const getSelectedProduct = createAsyncThunk('getProductAllData/getSelectedProduct', async (id: string) => {
    // console.log("id", id);
    // const query = queryString.stringify(data);
    try {
        let response = await axios.get(`/product/${id}`, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});
export const getProductCategory = createAsyncThunk('getProductAllData/getProductCategory', async () => {
    try {
        let response = await axios.get('/product-category?option=true', {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response?.data;
    } catch (err) {
        return {};
    }
});

export const productSlice: Slice = createSlice({
    name: 'getProductAllData',
    initialState: {
        product: [] as Product[],
        productCount: 0 as number,
        nextPage: 1 as number,
        selectedData: null,
        productCategory: [],
        productById: {} as Product
    },
    reducers: {
        removeCategory: (state) => {
            state.productCategory = [];
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getProduct.fulfilled, (state, action) => {
            state.product = action.payload.productMaster;
        });
        builder.addCase(getProductCategory.fulfilled, (state, action) => {
            state.productCategory = action.payload;
        });
        builder.addCase(getProductById.fulfilled, (state, action) => {
            state.productById = action.payload;
        });
        builder.addCase(getSelectedProduct.fulfilled, (state, action) => {
            state.selectedData = action.payload;
            return;
        });
    }
});

export default productSlice.reducer;
export const { removeCategory } = productSlice.actions;
