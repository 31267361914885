import React, { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import { Order } from 'reduxStore/store/order/class';
import { useLocation } from 'react-router-dom';

export interface PageContactProps {
    className?: string;
    selectedOrder: Order | null;
}

// eslint-disable-next-line no-unused-vars
const info = [
    {
        title: '🗺 ADDRESS',
        desc: 'Photo booth tattooed prism, portland taiyaki hoodie neutra typewriter'
    },
    {
        title: '💌 EMAIL',
        desc: 'nc.example@example.com'
    },
    {
        title: '☎ PHONE',
        desc: '000-123-456-7890'
    }
];

const PageContact: FC<PageContactProps> = ({ className = '', selectedOrder }) => {
    // eslint-disable-next-line no-unused-vars
    const { state } = useLocation();
    const renderProductItem = () => {
        return (
            //   <tr className="border-b border-slate-200 dark:border-slate-700 py-2 px-4">
            //     <td>
            //       <div className="h-24 w-16 sm:w-20 flex-shrink-0 overflow-hidden rounded-xl bg-slate-100">
            //         <img
            //           // src={image1}
            //           // alt={name}
            //           className="h-full w-full object-cover object-center"
            //         />
            //       </div>
            //     </td>

            //     <td className="ml-4 flex flex-1 flex-col">
            //       <div>
            //         <div className="flex justify-between ">
            //           <div>
            //             <h3 className="text-base font-medium line-clamp-1">metal</h3>
            //             <div>
            //               <span>Order No :</span>
            //               <span
            //                 className="text-sm text-slate-500 dark:text-slate-400 font-semibold"
            //                 style={{ cursor: "pointer" }}
            //                 // onClick={clickorderDetail}
            //               >
            //                 {/* {item.orderNumber} */}
            //               </span>
            //             </div>
            //             <p className="text-gray-500 dark:text-slate-400 flex items-center">
            //               <span className="hidden sm:inline-block">Qty</span>
            //               <span className="inline-block sm:hidden">x</span>
            //               <span className="ml-2">1</span>
            //               <br />
            //             </p>
            //           </div>
            //           <Prices className="mt-0.5 ml-2" />
            //         </div>
            //       </div>
            //     </td>
            //   </tr>

            <tr>
                <th className="py-2 px-4">
                    <img
                        //   src={image1}
                        //  alt={name}
                        className="h-full w-full object-cover object-center"
                    />
                </th>
                <th className="py-2 px-4">Name</th>
                <th className="py-2 px-4">Quantity</th>
                <th className="py-2 px-4">Price</th>
            </tr>
        );
    };

    const renderOrder = () => {
        return (
            <div className="border border-slate-200 dark:border-slate-700 rounded-lg overflow-hidden z-0">
                <div className="border-t border-slate-200 dark:border-slate-700 p-2 sm:p-8 divide-y divide-y-slate-200 dark:divide-slate-700">
                    <table className="w-full">
                        <thead>
                            <tr className="bg-slate-100 dark:bg-slate-500/20">
                                <th className="py-2 px-4">Image</th>
                                <th className="py-2 px-4">Name</th>
                                <th className="py-2 px-4">Quantity</th>
                                <th className="py-2 px-4">Price</th>
                            </tr>
                        </thead>
                        <tbody>{renderProductItem()}</tbody>
                    </table>
                </div>
            </div>
        );
    };

    return (
        <div className={`nc-PageContact overflow-hidden ${className}`} data-nc-id="PageContact">
            <Helmet>
                <title>Contact || Shop - eCommerce React Template</title>
            </Helmet>
            <div className="">
                <h2 className="my-20   flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    <span className="mr-16"> Order Details</span>
                    <ButtonPrimary
                        className="mr-10"
                        //   onClick={notifyAddTocart}
                    >
                        <span>Invoice</span>
                    </ButtonPrimary>
                    <ButtonPrimary
                        className=""
                        //   onClick={notifyAddTocart}
                    >
                        <span>Back</span>
                    </ButtonPrimary>
                </h2>
                <div className="container max-w-7xl mx-auto">
                    <div className="flex-shrink-0 grid grid-cols-1 md:grid-cols-2 gap-12 ">
                        <div className="max-w-sm space-y-8">
                            <div>
                                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">Billing Address</h3>
                                <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                    D-306, The First Vastrapur AHMEDABAD Gujarat INDIA -38000
                                </span>
                            </div>
                        </div>
                        <div>
                            <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">Shipping Address</h3>
                            <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                                D-306, The First Vastrapur AHMEDABAD Gujarat INDIA -38000
                            </span>
                        </div>
                    </div>
                    <div>{renderOrder()}</div>;
                </div>
            </div>

            {/* OTHER SECTIONS */}
            <div className="container">
                {/* <BackgroundSection />
          <SectionPromo1 /> */}
            </div>
        </div>
    );
};

export default PageContact;
